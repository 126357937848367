import { emptyApi as api } from 'src/store/emptyApi';
import { RootState } from '@helpers/store';
import { updatePreferences } from 'src/store/preferences/preferencesActions';
import {
  ChangePasswordRequest,
  ProfileClaimDiscordRequest,
  ProfileClaimPhoneRequest,
  ProfileInfo,
  UpdatePreferencesRequest,
  UpdateReferralCodeRequest,
} from '@config/types/profile';
import { AhrefsChecksResponse } from '@config/types/shared';

// Entity
const path = '/profile';
const type = 'Profile';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    viewProfile: build.query<ProfileInfo, void>({
      providesTags: [{ type }],
      query: () => ({ url: path }),
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded, getState }) {
        const { data } = await cacheDataLoaded;
        const state = getState() as RootState;

        // set preferences on login
        if (data?.preferences && !Object.keys(state.preferences).length) {
          dispatch(updatePreferences(data.preferences));
        }
      },
    }),
    updateProfilePreferences: build.mutation<void, UpdatePreferencesRequest>({
      query: (body) => ({ url: `${path}/preferences`, method: 'PATCH', body }),
    }),
    changeProfilePassword: build.mutation<void, ChangePasswordRequest>({
      query: (body) => ({
        url: `${path}/password`,
        method: 'PATCH',
        body,
      }),
    }),
    updateProfileReferralCode: build.mutation<void, UpdateReferralCodeRequest>({
      query: (body) => ({ url: `${path}/referral-code`, method: 'PATCH', body }),
    }),
    claimProfileAssociationPhone: build.mutation<void, ProfileClaimPhoneRequest>({
      query: (body) => ({
        url: `/v2${path}/a/phones`,
        method: 'POST',
        body,
      }),
    }),
    abandonProfileAssociationPhone: build.mutation<void, number>({
      query: (id) => ({
        url: `${path}/a/phones/${id}`,
        method: 'DELETE',
      }),
    }),
    claimProfileAssociationDiscord: build.mutation<void, ProfileClaimDiscordRequest>({
      query: (body) => ({
        url: `${path}/a/telegrams`,
        method: 'POST',
        body,
      }),
    }),
    abandonProfileAssociationDiscord: build.mutation<void, number>({
      query: (id) => ({
        url: `${path}/a/telegrams/${id}`,
        method: 'DELETE',
      }),
    }),
    getProfileAhrefsChecks: build.query<AhrefsChecksResponse, void>({
      query: () => ({ url: `${path}/ahrefs-checks` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };

export const {
  useViewProfileQuery,
  useLazyViewProfileQuery,
  useChangeProfilePasswordMutation,
  useUpdateProfilePreferencesMutation,
  useUpdateProfileReferralCodeMutation,
  useClaimProfileAssociationPhoneMutation,
  useAbandonProfileAssociationPhoneMutation,
  useClaimProfileAssociationDiscordMutation,
  useAbandonProfileAssociationDiscordMutation,
  useLazyGetProfileAhrefsChecksQuery,
} = injectedRtkApi;
