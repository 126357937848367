export type ImplementsView<T = any> = { [key in views]: T };

export enum views {
  ahrefs = 'ahrefs',
  anchor = 'anchor',
  attainmentPayment = 'attainmentPayment',
  attainmentPaymentLinkbuilderOverview = 'attainmentPaymentLinkbuilderOverview',
  attainmentPaymentLinkbuilderSingle = 'attainmentPaymentLinkbuilderSingle',
  attainmentPaymentBatch = 'attainmentPaymentBatch',
  attainmentPaymentBatchLinkbuilderSingle = 'attainmentPaymentBatchLinkbuilderSingle',
  bonusPayment = 'bonusPayment',
  donatedLanding = 'donatedLanding',
  donatedLandingLinkbuilder = 'donatedLandingLinkbuilder',
  donatedSite = 'donatedSite',
  donatedSiteLinkbuilder = 'donatedSiteLinkbuilder',
  event = 'event',
  eventTask = 'eventTask',
  feedback = 'feedback',
  feedbackPendingAdmin = 'feedbackPendingAdmin',
  feedbackPendingTaskmaster = 'feedbackPendingTaskmaster',
  feedbackPendingLinkbuilder = 'feedbackPendingLinkbuilder',
  feedbackPendingAdminPopover = 'feedbackPendingAdminPopover',
  feedbackPendingTaskmasterPopover = 'feedbackPendingTaskmasterPopover',
  feedbackPendingLinkbuilderPopover = 'feedbackPendingLinkbuilderPopover',
  file = 'file',
  financialPenalty = 'financialPenalty',
  guideQuestion = 'guideQuestion',
  guideQuestionAdmin = 'guideQuestionAdmin',
  issue = 'issue',
  issueTeam = 'issueTeam',
  issueUser = 'issueUser',
  issueAuthor = 'issueAuthor',
  issueAssignee = 'issueAssignee',
  landing = 'landing',
  landingPending = 'landingPending',
  landingPendingPopover = 'landingPendingPopover',
  linkAttribute = 'linkAttribute',
  linkPlan = 'linkPlan',
  linkPlanTaskmasterSearch = 'linkPlanTaskmasterSearch',
  linkPlanTaskmaster = 'linkPlanTaskmaster',
  linkPlanPendingAdmin = 'linkPlanPendingAdmin',
  linkPlanPendingTaskmaster = 'linkPlanPendingTaskmaster',
  linkPlanPendingAdminPopover = 'linkPlanPendingAdminPopover',
  linkPlanPendingTaskmasterPopover = 'linkPlanPendingTaskmasterPopover',
  paymentOption = 'paymentOption',
  paymentDetail = 'paymentDetail',
  priceSetting = 'priceSetting',
  referringDomain = 'referringDomain',
  referringDomainLinkbuilder = 'referringDomainLinkbuilder',
  referringDomainLinkbuilderAvailable = 'referringDomainLinkbuilderAvailable',
  referringDomainIssue = 'referringDomainIssue',
  referringDomainPenalty = 'referringDomainPenalty',
  referringDomainSnapshot = 'referringDomainSnapshot',
  referringLink = 'referringLink',
  referringLinkIssue = 'referringLinkIssue',
  referringLinkTemp = 'referringLinkTemp',
  referringLinkTempPopover = 'referringLinkTempPopover',
  referringLinkSecondary = 'referringLinkSecondary',
  referringLinkSecondaryPopover = 'referringLinkSecondaryPopover',
  referringLinkAdminOverview = 'referringLinkAdminOverview',
  referringPage = 'referringPage',
  referringPageIssue = 'referringPageIssue',
  referringPageSnapshot = 'referringPageSnapshot',
  site = 'site',
  taskAdmin = 'taskAdmin',
  taskTaskmaster = 'taskTaskmaster',
  taskLinkbuilder = 'taskLinkbuilder',
  taskLinkbuilderExplore = 'taskLinkbuilderExplore',
  taskLinkbuilderUserProfile = 'taskLinkbuilderUserProfile',
  taskTaskmasterUserProfile = 'taskTaskmasterUserProfile',
  taskTeam = 'taskTeam',
  taskPendingAdmin = 'taskPendingAdmin',
  taskPendingTaskmaster = 'taskPendingTaskmaster',
  taskPendingLinkbuilder = 'taskPendingLinkbuilder',
  taskPendingAdminPopover = 'taskPendingAdminPopover',
  taskPendingTaskmasterPopover = 'taskPendingTaskmasterPopover',
  taskPendingLinkbuilderPopover = 'taskPendingLinkbuilderPopover',
  taskAdminOverview = 'taskAdminOverview',
  taskLinkbuilderOverview = 'taskLinkbuilderOverview',
  taskLinkbuilderOverviewPending = 'taskLinkbuilderOverviewPending',
  taskSite = 'taskSite',
  team = 'team',
  submissionContent = 'submissionContent',
  submissionContentTaskmaster = 'submissionContentTaskmaster',
  submissionContentPending = 'submissionContentPending',
  submissionContentPendingPopover = 'submissionContentPendingPopover',
  submissionPayment = 'submissionPayment',
  submissionPaymentTaskmaster = 'submissionPaymentTaskmaster',
  submissionType = 'submissionType',
  thematicConformity = 'thematicConformity',
  user = 'user',
  userTaskmaster = 'userTaskmaster',
  userPendingAdmin = 'userPendingAdmin',
  userPendingTaskmaster = 'userPendingTaskmaster',
  userPendingAdminPopover = 'userPendingAdminPopover',
  userPendingTaskmasterPopover = 'userPendingTaskmasterPopover',
}
