import { ignoreUrlProtocol } from '@helpers/utils';
import { API_BASE_URL } from '@config/constants';
import { ENTITY_LIST, EntityType } from './constants';
import { authLogout } from 'src/store/auth/authActions';
import { RootState } from '@helpers/store';
import { AuthAccessToken } from '@config/types/auth';
import { status } from 'src/store/status/statusSlice';
const { start, finish } = status.actions;

import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import qs from 'query-string';
import { refreshAccessToken } from './auth/authSlice';
import { ErrorMessage } from './ErrorMessage';

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  paramsSerializer: (params) => qs.stringify(params, { skipEmptyString: true }),
  prepareHeaders: (headers, { getState }) => {
    const { accessToken } = (getState() as RootState).auth.tokens;
    accessToken && headers.set('Authorization', `Bearer ${accessToken}`);
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const jobId = api.endpoint + Math.random();

  api.dispatch(start(jobId));

  let finalArgs: any = args;

  //@ts-ignore
  if (['searchReferringDomains', 'searchReferringPages'].includes(api.endpoint) && args?.params?.url) {
    //@ts-ignore
    const updatedUrl = ignoreUrlProtocol(String(args?.params?.url)).replace(/\/+$/, '');
    //@ts-ignore
    const newParams = { ...args?.params, url: updatedUrl };
    // @ts-ignore
    finalArgs = { ...args, params: newParams };
  }

  // Original request
  let result = await baseQuery(finalArgs, api, extraOptions);
  api.dispatch(finish(jobId));

  // if request is successful do nothing
  if (!result.error) return result;

  const { status, data } = result.error;

  // Access token expired
  if (status === 401) {
    const { refreshToken } = (api.getState() as RootState).auth.tokens;

    // if no refresh token logout and return error
    if (!refreshToken) {
      api.dispatch(authLogout());
      return result;
    }

    // Swap tokens before refresh
    api.dispatch(refreshAccessToken({ accessToken: refreshToken }));

    try {
      // Automatic re-authorization by extending fetchBaseQuery
      const refreshResult = await baseQuery({ url: '/auth/refresh', method: 'POST' }, api, extraOptions);
      const { accessToken } = refreshResult.data as AuthAccessToken;
      api.dispatch(refreshAccessToken({ accessToken }));

      // Retry last query
      result = await baseQuery(args, api, extraOptions);
    } catch (e) {
      api.dispatch(authLogout()); // refresh access token failed!
    }
    // @ts-ignore
  } else if (status === 403 && /token/gi.test(data?.error)) {
    api.dispatch(authLogout());
  } else {
    // @ts-ignore
    new ErrorMessage(result.error, api.endpoint, args?.errorArgs);
  }

  return result;
};

// Define a service using app URL and expected endpoints
// Enhance generated endpoints with tags: providesTags & invalidatesTags
// https://redux-toolkit.js.org/rtk-query/usage/automated-refetching#tags
// More at: https://www.graphql-code-generator.com/plugins/typescript-rtk-query

export const emptyApi = createApi({
  reducerPath: '_api',
  tagTypes: ENTITY_LIST,
  refetchOnReconnect: true, // test it
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});

// Same tags for all entities!
// Works great!
export const getTags = (type: EntityType, dependentTypes?: EntityType[]) => ({
  // Mutations
  invalidatesTags: (result: any, error: any, args: any) => {
    const id = typeof args === 'number' ? args : args?.id;
    const tags: {
      type: EntityType;
      id?: string;
    }[] = [
      { type, id: `associations` },
      { type, id: `search` },
    ];

    // Single view
    if (id && Number(id)) tags.push({ type, id: `${id}` });

    dependentTypes?.forEach((tag) => {
      tags.push({ type: tag });
    });

    return tags;
  },
  // Queries
  providesTags: (result: any, error: any, id: any) => [{ type, id }], // for single view only
  // Search view ->  providesTags: () => [{ type, id: 'search' }],
  // Other entity associations view ->  providesTags: () => [{ type, id: 'associations' }],
});
