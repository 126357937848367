import {
  FinancialPenaltyDateField,
  FinancialPenaltySearchItem,
  FinancialPenaltySearchParams,
  FinancialPenaltySortFields,
  FinancialPenaltyStatuses,
} from '@config/types/financialPenalty';
import { GenericDynamicParams, GenericSearchPageReturnType } from '@config/types/shared';

export type FinancialPenaltyViewParams = GenericSearchPageReturnType<
  FinancialPenaltySearchParams,
  FinancialPenaltySearchItem
>;

export const financialPenaltyDefaultParams: FinancialPenaltySearchParams = {
  idStatus: [FinancialPenaltyStatuses.Unverified, FinancialPenaltyStatuses.Pending],
  sortBy: 'dateAdded',
  sortType: 'asc',
};

export const financialPenaltyProtectedParams: FinancialPenaltySearchParams = {};

export const financialPenaltyDateFields: FinancialPenaltyDateField[] = [
  'dateAdded',
  'dateUpdated',
  'dateDue',
];

export const financialPenaltySortFields: FinancialPenaltySortFields[] = [
  'dateAdded',
  'dateUpdated',
  'dateDue',
];

// Linkbuilder overview
export type FinancialPenaltyLinkbuilderOverviewViewParams = FinancialPenaltyViewParams;

export const financialPenaltyLinkbuilderOverviewDefaultParams: FinancialPenaltySearchParams = { size: 5 };

export const financialPenaltyLinkbuilderOverviewProtectedParams: FinancialPenaltySearchParams = {
  sortBy: 'dateAdded',
  sortType: 'asc',
};

export const financialPenaltyLinkbuilderOverviewDateFields = [];

export const financialPenaltyLinkbuilderOverviewSortFields = [];

// Linkbuilder single
export type FinancialPenaltyLinkbuilderSingleViewParams = FinancialPenaltyViewParams;

export const financialPenaltyLinkbuilderSingleDefaultParams: FinancialPenaltySearchParams = { size: 5 };

export const financialPenaltyLinkbuilderSingleProtectedParams: GenericDynamicParams<FinancialPenaltySearchParams> =
  {
    idRecipient: 'dynamic',
    sortBy: 'dateAdded',
    sortType: 'asc',
  };

export const financialPenaltyLinkbuilderSingleDateFields = [];

export const financialPenaltyLinkbuilderSingleSortFields = [];
