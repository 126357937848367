import { protectedParams } from '@/search/v2/searchEngine/config/protectedParams';
import { AppGetState, AppDispatch } from '@helpers/store';
import {
  setSearchPreferences,
  setPreferences,
  SearchDict,
  setView,
} from 'src/store/preferences/preferencesSlice';
import { views } from '@/search/v2/searchEngine/config/views';
import { omit, pick } from 'lodash';
import { ProfilePreferences } from '@config/types/profile';

export const addSearchPreference =
  ({ key, value }: { key: views; value: SearchDict }) =>
  (dispatch: AppDispatch, getState: AppGetState) => {
    const blackListedValues = [...Object.keys(protectedParams[key])];

    const { preferences } = getState();
    // clean up outdated views
    const updatedState = pick(preferences.search, Object.values(views));

    updatedState[key] = { ...omit(value, blackListedValues) };

    dispatch(setSearchPreferences(updatedState));
  };

export const updatePreferences = (preferences: ProfilePreferences) => (dispatch: AppDispatch) => {
  // clean up outdated views
  const updatedState = {
    ...preferences,
    search: { ...pick(preferences?.search, Object.values(views)) },
  };

  dispatch(setPreferences(updatedState));
};

export const toggleView = (pathname: string) => {
  return async (dispatch: AppDispatch, getState: AppGetState): Promise<void> => {
    const oldView = getState().preferences?.appearance?.views?.[pathname];
    dispatch(setView({ key: pathname, value: oldView === 'list' ? 'grid' : 'list' }));
  };
};
