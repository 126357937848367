import { createSlice } from '@reduxjs/toolkit';

export type RefLinkBulkCheckParams = {
  currentPage: number;
  totalPages: number;
};

export type RefPageCheckType = RefLinkBulkCheckParams & {
  launched: boolean;
};

type statusSliceStateType = {
  isProcessing: boolean;
  message: string;
  queue: (() => void)[];
  taskCount: number;
  celebrate: boolean; // show confetti! celebrate everyday
  resetPagination: boolean;
  refLinksCheck: RefPageCheckType;
  unindexedRefPagesCheck: RefPageCheckType;
};

export const statusSliceInitialState: statusSliceStateType = {
  isProcessing: false,
  message: '',
  queue: [],
  taskCount: 0,
  celebrate: false, // show confetti! celebrate everyday
  refLinksCheck: {
    launched: false,
    currentPage: 0,
    totalPages: 0,
  },
  unindexedRefPagesCheck: {
    launched: false,
    currentPage: 0,
    totalPages: 0,
  },
  resetPagination: false,
};

// Slice
export const status = createSlice({
  name: 'status',
  initialState: statusSliceInitialState,
  reducers: {
    start(state, { payload }) {
      const { name } = payload;
      const isJobExists = state.queue.find((job) => job === name) || false;
      !isJobExists && state.queue.push(name); // add item
      state.taskCount = state.queue.length;
      state.isProcessing = !!state.taskCount;
    },
    finish(state, { payload }) {
      const { name } = payload;
      const idx = state.queue.findIndex((job) => job === name);
      ~idx && state.queue.splice(idx, 1); // remove item from array
      state.taskCount = state.queue.length;
      state.isProcessing = !!state.taskCount;
    },
    clear(state) {
      state.queue = [];
      state.taskCount = 0;
    },
    celebrate(state, { payload }) {
      state.celebrate = payload;
    },
    resetPagination(state, { payload }) {
      state.resetPagination = payload;
    },
    launchRefLinksCheck(state) {
      state.refLinksCheck.launched = true;
    },
    stopRefLinksCheck(state) {
      state.refLinksCheck.launched = false;
      state.refLinksCheck.currentPage = 0;
      state.refLinksCheck.totalPages = 0;
    },
    updateRefLinksCheck(state, { payload }: { payload: RefLinkBulkCheckParams }) {
      state.refLinksCheck.currentPage = payload.currentPage;
      state.refLinksCheck.totalPages = payload.totalPages;
    },

    launchUnindexedRefPagesCheck(state) {
      state.unindexedRefPagesCheck.launched = true;
    },
    stopUnindexedRefPagesCheck(state) {
      state.unindexedRefPagesCheck.launched = false;
      state.unindexedRefPagesCheck.currentPage = 0;
      state.unindexedRefPagesCheck.totalPages = 0;
    },
    updateUnindexedRefPagesCheck(state, { payload }: { payload: RefLinkBulkCheckParams }) {
      state.unindexedRefPagesCheck.currentPage = payload.currentPage;
      state.unindexedRefPagesCheck.totalPages = payload.totalPages;
    },
  },
});

export const {
  launchRefLinksCheck,
  stopRefLinksCheck,
  updateRefLinksCheck,
  launchUnindexedRefPagesCheck,
  stopUnindexedRefPagesCheck,
  updateUnindexedRefPagesCheck,
} = status.actions;

export default status.reducer;

// Job =>> Action (thunk)
// Find job (by function name) in queue:
export const checkIsProcessing =
  (job: any) =>
  //@ts-ignore
  ({ status }: { status: statusSliceStateType }): boolean =>
    status.queue.includes(job.name);
export const isProcessingSelector = ({ status }: { status: statusSliceStateType }): boolean =>
  status.isProcessing;

export const celebrateSelector = ({ status }: { status: statusSliceStateType }): boolean => status.celebrate;

export const resetPaginationSelector = ({ status }: { status: statusSliceStateType }): boolean =>
  status.resetPagination;

export const refLinksCheckSelector = ({ status }: { status: statusSliceStateType }): RefPageCheckType =>
  status.refLinksCheck;

export const unindexedRefPagesCheckSelector = ({
  status,
}: {
  status: statusSliceStateType;
}): RefPageCheckType => status.unindexedRefPagesCheck;
