import { GenericSearchPageReturnType, GenericDynamicParams } from '@config/types/shared';
import {
  ReferringDomainContactStages,
  ReferringDomainDateField,
  ReferringDomainSearchItem,
  ReferringDomainSearchParams,
  ReferringDomainSortFields,
} from '@config/types/referringDomain';

export type ReferringDomainViewParams = GenericSearchPageReturnType<
  ReferringDomainSearchParams,
  ReferringDomainSearchItem
>;

export const referringDomainDefaultParams: ReferringDomainSearchParams = {
  size: 20,
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const referringDomainProtectedParams: ReferringDomainSearchParams = {};

export const referringDomainDateFields: ReferringDomainDateField[] = ['dateAdded', 'dateUpdated'];

export const referringDomainSortFields: ReferringDomainSortFields[] = [
  'name',
  'dateAdded',
  'dateUpdated',
  'domainRating',
  'traffic',
  'spamLevel',
];

// linkbuilder
export type ReferringDomainLinkbuilderViewParams = ReferringDomainViewParams;
export const referringDomainLinkbuilderDefaultParams: ReferringDomainSearchParams =
  referringDomainDefaultParams;
export const referringDomainLinkbuilderProtectedParams: GenericDynamicParams<ReferringDomainSearchParams> = {
  idAssignee: 'dynamic',
  idContactStage: 'dynamic',
};
export const referringDomainLinkbuilderDateFields: ReferringDomainDateField[] = referringDomainDateFields;
export const referringDomainLinkbuilderSortFields: ReferringDomainSortFields[] = referringDomainSortFields;

// linkbuilderAvailable
export type ReferringDomainLinkbuilderAvailableViewParams = ReferringDomainViewParams;
export const referringDomainLinkbuilderAvailableDefaultParams: ReferringDomainSearchParams =
  referringDomainDefaultParams;
export const referringDomainLinkbuilderAvailableProtectedParams: GenericDynamicParams<ReferringDomainSearchParams> =
  {
    unassigned: true,
    idContactStage: [
      ReferringDomainContactStages.FirstContact,
      ReferringDomainContactStages.FollowUp1,
      ReferringDomainContactStages.FollowUp2,
      ReferringDomainContactStages.NoResponse,
      ReferringDomainContactStages.NotImposed,
      ReferringDomainContactStages.OnHold,
      ReferringDomainContactStages.OpenDeal,
    ],
  };
export const referringDomainLinkbuilderAvailableDateFields: ReferringDomainDateField[] =
  referringDomainDateFields;
export const referringDomainLinkbuilderAvailableSortFields: ReferringDomainSortFields[] =
  referringDomainSortFields;
