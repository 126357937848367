import { PaymentDetailItem } from '@config/types/paymentDetail';
import { CommentSearchItem } from '@config/types/comment';
import { Ahrefs } from '@config/types/ahrefs';
import { AssociationTeam, AssociationTeamUserStatuses, Team } from '@config/types/team';
import { Task } from '@config/types/task';
import { AscDesc, Patch, PatchBulk } from '@config/types/shared';

export enum UserStatuses {
  Draft = 1,
  Active,
  Paused,
  Suspended,
  New,
  Dismissed,
}

export enum UserRoles {
  Admin = 1,
  Taskmaster,
  Linkbuilder,
}

export enum UserOnboardingStage {
  Pending = 1,
  Organized,
  Passed,
  Failed,
  NotImposed,
}

export enum UserLanguages {
  Russian = 1,
  English,
  German,
}

export enum UserAssociationStatuses {
  Claimed = 1,
  Assigned,
  Replaced,
  Abandoned,
  Removed,
  Reclaimed,
  Restored,
}

export enum PhoneStatuses {
  NotImposed = 1,
  Blacklisted,
}

export enum TelegramStatuses {
  NotImposed = 1,
  Blacklisted,
}

export type UserInterviewStatusActions = 'schedule' | 'organize' | 'pass' | 'fail';

export type User = {
  id: number;
  dateAdded: string;
  dateUpdated?: string;
  dateActive?: string;
  firstName: string;
  lastName: string;
  email: string;
  avatar?: string;
  referralCode?: string;
  idStatus: UserStatuses;
  idRole: UserRoles;
  idOnboardingStage: UserOnboardingStage;
  idUserLanguage: UserLanguages;
  limitSimultaneousTasks?: number;
  limitLooseReferringDomains?: number;
  limitChecksPerMonth?: number;
};

export type UserSearchItem = User & {
  author?: User;
  referrer?: User;
  ahrefsAccount?: Ahrefs;
  country?: Country;
  paymentDetail?: PaymentDetailItem;
  associationTeams?: AssociationTeam[];
};

export type UserDetails = UserSearchItem & {
  usersAuthored?: User[];
  usersReferred?: User[];
  teamsAsLeader?: Team[];
  tasksAsAssignee?: Task[];
  commentsAsBearer?: CommentSearchItem[];
  paymentDetailsAsRecipient?: PaymentDetailItem[];
  associationPhones?: UserAssociationPhone[];
  associationTelegrams?: UserAssociationDiscord[];
  currentSimultaneousTasks?: number;
  looseReferringDomainsCount?: number;
  currentChecksPerMonth?: number;
  attainmentAmountPending?: number;
  attainmentAmountPaid?: number;
  bonusAmountPending?: number;
  bonusAmountPaid?: number;
  financialPenaltyPending?: number;
  financialPenaltyPaid?: number;
};

export type UserCreatePayload = {
  firstName: string;
  lastName: string;
  email: string;
  avatar?: string;
  password: string;
  limitSimultaneousTasks: number;
  referralCode?: string;
  idRole: UserRoles;
  idCountry?: number;
  idUserLanguage: UserLanguages;
};

export type UserCreateRequest = UserCreatePayload;

export type UserEnlistPayload = {
  firstName: string;
  lastName: string;
  email: string;
  idCountry?: number;
  idUserLanguage: UserLanguages;
};

export type UserEnlistRequest = UserEnlistPayload;

export type UserSignUpPayload = {
  firstName: string;
  lastName: string;
  email: string;
  avatar?: string;
  password: string;
  referralCode?: string;
  phoneNumber?: string;
  valueDialInCode?: string;
  idUserLanguage: UserLanguages;
};

export type UserSignUpRequest = UserSignUpPayload;

export type UserUpdatePayload = {
  avatar?: string;
  firstName?: string;
  lastName?: string;
  referralCode?: string;
  limitSimultaneousTasks?: number;
  limitLooseReferringDomains?: number;
  limitChecksPerMonth?: number;
};

export type UserUpdateRequest = Patch<UserUpdatePayload>;

export type UserUpdatePasswordPayload = {
  password: string;
};

export type UserUpdatePasswordRequest = Patch<UserUpdatePasswordPayload>;

export type UserForgotPasswordPayload = {
  email: string;
};

export type UserForgotPasswordRequest = UserForgotPasswordPayload;

export type UserResetPasswordPayload = {
  hash: string;
  newPassword: string;
  repeatedPassword: string;
};

export type UserResetPasswordRequest = UserResetPasswordPayload;

export type UserSetAhrefsAccountParams = {
  id: number;
  idAhrefsAccount: number;
};

export type UserUpdateAssociationsRequest = PatchBulk<number[]>;

export type UserActivatePayload = {
  idAhrefsAccount?: number;
};

export type UserActivateRequest = Patch<UserActivatePayload>;

export type UserCountParams = {
  idFrom?: number;
  idTo?: number;
  dateAddedFrom?: string;
  dateAddedTo?: string;
  dateUpdatedFrom?: string;
  dateUpdatedTo?: string;
  name?: string;
  idRole?: UserRoles[];
  idStatus?: UserStatuses[];
  idOnboardingStage?: UserOnboardingStage[];
  idAuthor?: number[];
};

export type UserSearchParams = UserCountParams & {
  sortType?: AscDesc;
  sortBy?: UserSortFields;
  page?: number;
  size?: number;
};

export type UserSortFields =
  | 'id'
  | 'dateAdded'
  | 'dateUpdated'
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'idRole'
  | 'idStatus'
  | 'idOnboardingStage'
  | 'idAuthor';

export type UserDateField = 'dateAdded' | 'dateUpdated';

export interface UserPhone {
  rawValue: string;
  id: number;
  digitsOnly: string;
  idStatus: PhoneStatuses;
  callingCodeValue: string;
}

export type Country = {
  name: string;
  short: string;
  long: string;
  id: number;
};

export type UserAssociationPhone = {
  dateAdded: string;
  dateRestored?: string;
  dateRemoved?: string;
  idStatus: UserAssociationStatuses;
  phone: UserPhone;
};

export type UserAssociationDiscord = {
  dateAdded: string;
  dateRestored?: string;
  dateRemoved?: string;
  idStatus: UserAssociationStatuses;
  telegram: Telegram;
};

export type Telegram = {
  value: string;
  id: number;
  idStatus: TelegramStatuses;
};

export type TeamUser = User & {
  associationPhones?: UserAssociationPhone[];
  associationTelegrams?: UserAssociationDiscord[];
};

export type TeamAssociationUser = {
  dateAdded: string;
  dateRestored?: string;
  dateRemoved?: string;
  idStatus: AssociationTeamUserStatuses;
  user: User;
};
