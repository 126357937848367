import { submissionContentPendingPopoverProtectedParams } from './../../../../entity/submissionContent/submissionContentSearchConfig';
import {
  attainmentPaymentBatchProtectedParams,
  attainmentPaymentBatchLinkbuilderSingleProtectedParams,
} from './../../../../entity/attainmentPaymentBatch/attainmentPaymentBatchSearchConfig';
import {
  userPendingAdminPopoverProtectedParams,
  userPendingAdminProtectedParams,
  userPendingTaskmasterPopoverProtectedParams,
  userPendingTaskmasterProtectedParams,
  userTaskmasterProtectedParams,
} from './../../../../entity/user/userSearchConfig';
import {
  landingPendingPopoverProtectedParams,
  landingPendingProtectedParams,
} from './../../../../entity/landing/landingSearchConfig';
import {
  taskLinkbuilderProtectedParams,
  taskLinkbuilderExploreProtectedParams,
  taskLinkbuilderUserProfileProtectedParams,
  taskTaskmasterUserProfileProtectedParams,
  taskTeamProtectedParams,
  taskPendingAdminProtectedParams,
  taskPendingLinkbuilderProtectedParams,
  taskPendingTaskmasterProtectedParams,
  taskAdminOverviewProtectedParams,
  taskLinkbuilderOverviewProtectedParams,
  taskSiteProtectedParams,
  taskPendingAdminPopoverProtectedParams,
  taskPendingTaskmasterPopoverProtectedParams,
  taskPendingLinkbuilderPopoverProtectedParams,
  taskLinkbuilderOverviewPendingProtectedParams,
} from './../../../../entity/task/taskSearchConfig';
import { taskTaskmasterProtectedParams } from '@/entity/task/taskSearchConfig';
import { userProtectedParams } from '@/entity/user/userSearchConfig';
import {
  issueAuthorProtectedParams,
  issueProtectedParams,
  issueAssigneeProtectedParams,
  issueTeamProtectedParams,
  issueUserProtectedParams,
} from '@/entity/issue/issueSearchConfig';
import { submissionTypeProtectedParams } from '@/entity/submissionType/submissionTypeSearchConfig';
import { thematicConformityProtectedParams } from '@/entity/thematicConf/thematicConfSearchConfig';
import { anchorProtectedParams } from '@/entity/anchor/anchorSearchConfig';
import { bonusPaymentProtectedParams } from '@/entity/bonusPayment/bonusPaymentSearchConfig';
import {
  donatedLandingLinkbuilderProtectedParams,
  donatedLandingProtectedParams,
} from '@/entity/donatedLanding/donatedLandingSearchConfig';
import {
  donatedSiteProtectedParams,
  donatedSiteLinkbuilderProtectedParams,
} from '@/entity/donatedSite/donatedSiteSearchConfig';
import { siteProtectedParams } from '@/entity/site/siteSearchConfig';
import { ImplementsView, views } from '@/search/v2/searchEngine/config/views';
import { linkAttributeProtectedParams } from '@/entity/linkAttribute/linkAttributeSearchConfig';
import { priceSettingProtectedParams } from '@/entity/priceSetting/priceSettingSearchConfig';
import { landingProtectedParams } from '@/entity/landing/landingSearchConfig';
import {
  feedbackPendingAdminPopoverProtectedParams,
  feedbackPendingAdminProtectedParams,
  feedbackPendingLinkbuilderPopoverProtectedParams,
  feedbackPendingLinkbuilderProtectedParams,
  feedbackPendingTaskmasterPopoverProtectedParams,
  feedbackPendingTaskmasterProtectedParams,
  feedbackProtectedParams,
} from '@/entity/feedback/feedbackSearchConfig';
import {
  linkPlanPendingAdminPopoverProtectedParams,
  linkPlanPendingAdminProtectedParams,
  linkPlanPendingTaskmasterPopoverProtectedParams,
  linkPlanPendingTaskmasterProtectedParams,
  linkPlanProtectedParams,
  linkPlanTaskmasterProtectedParams,
  linkPlanTaskmasterSearchProtectedParams,
} from '@/entity/linkPlan/linkPlanSearchConfig';
import {
  referringLinkAdminOverviewProtectedParams,
  referringLinkProtectedParams,
  referringLinkSecondaryPopoverProtectedParams,
  referringLinkSecondaryProtectedParams,
  referringLinkTempPopoverProtectedParams,
  referringLinkTempProtectedParams,
} from '@/entity/refLink/referringLinkSearchConfig';
import { taskAdminProtectedParams } from '@/entity/task/taskSearchConfig';
import { ahrefsProtectedParams } from '@/entity/ahrefs/ahrefsSearchConfig';
import { financialPenaltyProtectedParams } from '@/entity/financialPenalty/financialPenaltySearchConfig';
import {
  attainmentPaymentLinkbuilderOverviewProtectedParams,
  attainmentPaymentLinkbuilderrSingleProtectedParams,
  attainmentPaymentProtectedParams,
} from '@/entity/attainmentPayment/attainmentPaymentsSearchConfig';
import { teamProtectedParams } from '@/entity/team/teamSearchConfig';
import { eventProtectedParams, eventTaskProtectedParams } from '@/entity/event/eventSearchConfig';
import { paymentOptionProtectedParams } from '@/entity/paymentOption/paymentOptionSearchConfig';
import { paymentDetailProtectedParams } from '@/entity/paymentDetail/paymentDetailSearchConfig';
import {
  referringDomainLinkbuilderAvailableProtectedParams,
  referringDomainLinkbuilderProtectedParams,
  referringDomainProtectedParams,
} from '@/entity/refDomain/referringDomainSearchConfig';
import { referringPageProtectedParams } from '@/entity/refPage/referringPageSearchConfig';
import { referringDomainIssueProtectedParams } from '@/entity/refDomainIssue/referringDomainIssueSearchConfig';
import { referringLinkIssueProtectedParams } from '@/entity/refLinkIssue/referringLinkIssueSearchConfig';
import { referringPageIssueProtectedParams } from '@/entity/refPageIssue/refPageIssuseSearchConfig';
import { referringDomainSnapshotProtectedParams } from '@/entity/refDomainSnapshot/referringDomainSnapshotSearchConfig';
import { referringPageSnapshotProtectedParams } from '@/entity/refPageSnapshot/referringPageSnapshotSearchConfig';
import {
  submissionContentPendingProtectedParams,
  submissionContentProtectedParams,
  submissionContentTaskmasterProtectedParams,
} from '@/entity/submissionContent/submissionContentSearchConfig';
import {
  submissionPaymentProtectedParams,
  submissionPaymentTaskmasterProtectedParams,
} from '@/entity/submissionPayment/submissionPaymentSearchConfig';
import {
  guideQuestionAdminProtectedParams,
  guideQuestionProtectedParams,
} from '@/entity/guideQuestion/guideQuestionSearchConfig';
import { fileProtectedParams } from '@/entity/file/fileSearchConfig';

// typically not needed; use for values not responsive to clear all params
export const protectedParams: ImplementsView = {
  [views.ahrefs]: ahrefsProtectedParams,
  [views.anchor]: anchorProtectedParams,
  [views.attainmentPayment]: attainmentPaymentProtectedParams,
  [views.attainmentPaymentLinkbuilderOverview]: attainmentPaymentLinkbuilderOverviewProtectedParams,
  [views.attainmentPaymentLinkbuilderSingle]: attainmentPaymentLinkbuilderrSingleProtectedParams,
  [views.attainmentPaymentBatch]: attainmentPaymentBatchProtectedParams,
  [views.attainmentPaymentBatchLinkbuilderSingle]: attainmentPaymentBatchLinkbuilderSingleProtectedParams,
  [views.bonusPayment]: bonusPaymentProtectedParams,
  [views.donatedSite]: donatedSiteProtectedParams,
  [views.donatedSiteLinkbuilder]: donatedSiteLinkbuilderProtectedParams,
  [views.donatedLanding]: donatedLandingProtectedParams,
  [views.donatedLandingLinkbuilder]: donatedLandingLinkbuilderProtectedParams,
  [views.event]: eventProtectedParams,
  [views.eventTask]: eventTaskProtectedParams,
  [views.feedback]: feedbackProtectedParams,
  [views.feedbackPendingAdmin]: feedbackPendingAdminProtectedParams,
  [views.feedbackPendingTaskmaster]: feedbackPendingTaskmasterProtectedParams,
  [views.feedbackPendingLinkbuilder]: feedbackPendingLinkbuilderProtectedParams,
  [views.feedbackPendingAdminPopover]: feedbackPendingAdminPopoverProtectedParams,
  [views.feedbackPendingTaskmasterPopover]: feedbackPendingTaskmasterPopoverProtectedParams,
  [views.feedbackPendingLinkbuilderPopover]: feedbackPendingLinkbuilderPopoverProtectedParams,
  [views.file]: fileProtectedParams,
  [views.financialPenalty]: financialPenaltyProtectedParams,
  [views.guideQuestion]: guideQuestionProtectedParams,
  [views.guideQuestionAdmin]: guideQuestionAdminProtectedParams,
  [views.issue]: issueProtectedParams,
  [views.issueTeam]: issueTeamProtectedParams,
  [views.issueUser]: issueUserProtectedParams,
  [views.issueAuthor]: issueAuthorProtectedParams,
  [views.issueAssignee]: issueAssigneeProtectedParams,
  [views.landing]: landingProtectedParams,
  [views.landingPending]: landingPendingProtectedParams,
  [views.landingPendingPopover]: landingPendingPopoverProtectedParams,
  [views.linkAttribute]: linkAttributeProtectedParams,
  [views.linkPlan]: linkPlanProtectedParams,
  [views.linkPlanPendingAdmin]: linkPlanPendingAdminProtectedParams,
  [views.linkPlanPendingAdminPopover]: linkPlanPendingAdminPopoverProtectedParams,
  [views.linkPlanPendingTaskmaster]: linkPlanPendingTaskmasterProtectedParams,
  [views.linkPlanPendingTaskmasterPopover]: linkPlanPendingTaskmasterPopoverProtectedParams,
  [views.linkPlanTaskmaster]: linkPlanTaskmasterProtectedParams,
  [views.linkPlanTaskmasterSearch]: linkPlanTaskmasterSearchProtectedParams,
  [views.paymentDetail]: paymentDetailProtectedParams,
  [views.paymentOption]: paymentOptionProtectedParams,
  [views.priceSetting]: priceSettingProtectedParams,
  [views.referringDomain]: referringDomainProtectedParams,
  [views.referringDomainLinkbuilder]: referringDomainLinkbuilderProtectedParams,
  [views.referringDomainLinkbuilderAvailable]: referringDomainLinkbuilderAvailableProtectedParams,
  [views.referringDomainIssue]: referringDomainIssueProtectedParams,
  [views.referringDomainPenalty]: referringDomainProtectedParams,
  [views.referringDomainSnapshot]: referringDomainSnapshotProtectedParams,
  [views.referringLink]: referringLinkProtectedParams,
  [views.referringLinkTemp]: referringLinkTempProtectedParams,
  [views.referringLinkTempPopover]: referringLinkTempPopoverProtectedParams,
  [views.referringLinkSecondary]: referringLinkSecondaryProtectedParams,
  [views.referringLinkSecondaryPopover]: referringLinkSecondaryPopoverProtectedParams,
  [views.referringLinkAdminOverview]: referringLinkAdminOverviewProtectedParams,
  [views.referringLinkIssue]: referringLinkIssueProtectedParams,
  [views.referringPage]: referringPageProtectedParams,
  [views.referringPageIssue]: referringPageIssueProtectedParams,
  [views.referringPageSnapshot]: referringPageSnapshotProtectedParams,
  [views.site]: siteProtectedParams,
  [views.submissionContent]: submissionContentProtectedParams,
  [views.submissionContentPending]: submissionContentPendingProtectedParams,
  [views.submissionContentPendingPopover]: submissionContentPendingPopoverProtectedParams,
  [views.submissionContentTaskmaster]: submissionContentTaskmasterProtectedParams,
  [views.submissionType]: submissionTypeProtectedParams,
  [views.submissionPayment]: submissionPaymentProtectedParams,
  [views.submissionPaymentTaskmaster]: submissionPaymentTaskmasterProtectedParams,
  [views.taskAdmin]: taskAdminProtectedParams,
  [views.taskTaskmaster]: taskTaskmasterProtectedParams,
  [views.taskLinkbuilder]: taskLinkbuilderProtectedParams,
  [views.taskLinkbuilderExplore]: taskLinkbuilderExploreProtectedParams,
  [views.taskLinkbuilderUserProfile]: taskLinkbuilderUserProfileProtectedParams,
  [views.taskTaskmasterUserProfile]: taskTaskmasterUserProfileProtectedParams,
  [views.taskTeam]: taskTeamProtectedParams,
  [views.taskPendingAdmin]: taskPendingAdminProtectedParams,
  [views.taskPendingTaskmaster]: taskPendingTaskmasterProtectedParams,
  [views.taskPendingLinkbuilder]: taskPendingLinkbuilderProtectedParams,
  [views.taskPendingAdminPopover]: taskPendingAdminPopoverProtectedParams,
  [views.taskPendingTaskmasterPopover]: taskPendingTaskmasterPopoverProtectedParams,
  [views.taskPendingLinkbuilderPopover]: taskPendingLinkbuilderPopoverProtectedParams,
  [views.taskAdminOverview]: taskAdminOverviewProtectedParams,
  [views.taskLinkbuilderOverview]: taskLinkbuilderOverviewProtectedParams,
  [views.taskLinkbuilderOverviewPending]: taskLinkbuilderOverviewPendingProtectedParams,
  [views.taskSite]: taskSiteProtectedParams,
  [views.team]: teamProtectedParams,
  [views.thematicConformity]: thematicConformityProtectedParams,
  [views.user]: userProtectedParams,
  [views.userTaskmaster]: userTaskmasterProtectedParams,
  [views.userPendingAdmin]: userPendingAdminProtectedParams,
  [views.userPendingTaskmaster]: userPendingTaskmasterProtectedParams,
  [views.userPendingAdminPopover]: userPendingAdminPopoverProtectedParams,
  [views.userPendingTaskmasterPopover]: userPendingTaskmasterPopoverProtectedParams,
};
