import { AscDesc, Patch, PatchBulk } from '@config/types/shared';
import { User } from '@config/types/user';
import { Anchor } from '@config/types/anchor';
import { Landing } from '@config/types/landing';
import { PriceSetting } from '@config/types/priceSetting';
import { File } from '@config/types/file';

export enum LinkPlanStatuses {
  Draft = 1,
  ToDo,
  InProgress,
  Completed,
  Canceled,
  Removed,
  Paused,
}

export type LinkPlan = {
  id: number;
  dateAdded: string;
  dateDue?: string;
  dateCanceled?: string;
  dateUpdated?: string;
  name: string;
  description?: string;
  tasksCount: number;
  idStatus: LinkPlanStatuses;
  idAssignee?: number;
};

export type LinkPlanSearchItem = LinkPlan & {
  author: User;
  assignee?: User;
  landing: Landing;
  priceSetting: PriceSetting;
  tasksLinked?: number;
};

export type LinkPlanDetails = LinkPlan & {
  author: User;
  assignee?: User;
  landing: Landing;
  priceSetting: PriceSetting;
  linkedAnchors: Anchor[];
  linkedFiles: File[];
};

export type LinkPlanCreatePayload = {
  dateDue?: string;
  name: string;
  description?: string;
  tasksCount: number;
  idAssignee?: number;
  idLanding: number;
  idPriceSetting: number;
};

export type LinkPlanCreateRequest = LinkPlanCreatePayload;

export type LinkPlanUpdatePayload = {
  dateDue?: string;
  name?: string;
  description?: string;
  tasksCount?: number;
  idSubmissionType?: number;
  idPriceSetting?: number;
  idLanguage?: number;
};

export type LinkPlanUpdateRequest = Patch<LinkPlanUpdatePayload>;

export type LinkPlanAssignPayload = {
  idAssignee: number;
};

export type LinkPlanAssignRequest = Patch<LinkPlanAssignPayload>;

export type LinkPlanUpdateDateDuePayload = {
  dateDue: string;
};

export type LinkPlanUpdateDateDueRequest = Patch<LinkPlanUpdateDateDuePayload>;

export type LinkPlanUpdateAssociationsRequest = PatchBulk<number[]>;

export type LinkPlanCountParams = {
  idFrom?: number;
  idTo?: number;
  dateAddedFrom?: string;
  dateAddedTo?: string;
  dateUpdatedFrom?: string;
  dateUpdatedTo?: string;
  dateDueFrom?: string;
  dateDueTo?: string;
  dateClosedFrom?: string;
  dateClosedTo?: string;
  name?: string;
  tasksCountFrom?: number;
  tasksCountTo?: number;
  idStatus?: LinkPlanStatuses[];
  idAuthor?: number[];
  idAssignee?: number[];
  idLanding?: number[];
  idPriceSetting?: number[];
};

export type LinkPlanSearchParams = LinkPlanCountParams & {
  sortType?: AscDesc;
  sortBy?: LinkPlanSortFields;
  page?: number;
  size?: number;
};

export type LinkPlanSortFields =
  | 'id'
  | 'dateAdded'
  | 'dateUpdated'
  | 'dateDue'
  | 'dateClosed'
  | 'name'
  | 'tasksCount'
  | 'idStatus'
  | 'idAuthor'
  | 'idAssignee'
  | 'idLanding'
  | 'idPriceSetting';

export type LinkPlanDateField = 'dateAdded' | 'dateUpdated' | 'dateDue' | 'dateClosed';
