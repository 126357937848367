import { GenericDynamicParams, GenericSearchPageReturnType, StandardStatuses } from '@config/types/shared';
import {
  GuideQuestionDateField,
  GuideQuestionSearchItem,
  GuideQuestionSearchParams,
  GuideQuestionSortFields,
} from '@config/types/guideQuestion';

// Admin
export type GuideQuestionAdminViewParams = GenericSearchPageReturnType<
  GuideQuestionSearchParams,
  GuideQuestionSearchItem
>;

export const guideQuestionAdminDefaultParams: GuideQuestionSearchParams = {
  size: 50,
  idStatus: [StandardStatuses.Draft, StandardStatuses.Active, StandardStatuses.Paused],
};

export const guideQuestionAdminProtectedParams: GenericDynamicParams<GuideQuestionSearchParams> = {
  sortBy: 'dateAdded',
  sortType: 'desc',
};

export const guideQuestionAdminDateFields: GuideQuestionDateField[] = [];

export const guideQuestionAdminSortFields: GuideQuestionSortFields[] = [];

// Other roles
export type GuideQuestionViewParams = GuideQuestionAdminViewParams;

export const guideQuestionDefaultParams: GuideQuestionSearchParams = guideQuestionAdminDefaultParams;

export const guideQuestionProtectedParams: GenericDynamicParams<GuideQuestionSearchParams> = {
  sortBy: 'dateAdded',
  sortType: 'desc',
  idStatus: [StandardStatuses.Active],
};

export const guideQuestionDateFields: GuideQuestionDateField[] = [];

export const guideQuestionSortFields: GuideQuestionSortFields[] = [];
