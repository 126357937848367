import { RootState } from '@helpers/store';
import { AuthTokenPair } from '@config/types/auth';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  isLoggedIn: boolean;
  tokens: AuthTokenPair;
}

export const authInitialState: AuthState = {
  isLoggedIn: false,
  tokens: {
    refreshToken: '',
    accessToken: '',
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    setTokens: (state: AuthState, { payload }: PayloadAction<AuthTokenPair>) => {
      state.tokens = payload;
      state.isLoggedIn = true;
    },
    refreshAccessToken: (state: AuthState, { payload }: PayloadAction<{ accessToken: string }>) => {
      state.tokens.accessToken = payload.accessToken;
    },
    logout: () => authInitialState,
  },
});

export default authSlice.reducer;

export const { setTokens, refreshAccessToken, logout } = authSlice.actions;

// Selectors
export const selectAuth = ({ auth }: RootState) => auth;
export const selectIsLoggedIn = ({ auth }: RootState) => auth.isLoggedIn;
