import { GenericDynamicParams, GenericSearchPageReturnType } from '@config/types/shared';
import {
  UserDateField,
  UserOnboardingStage,
  UserSearchItem,
  UserSearchParams,
  UserSortFields,
  UserStatuses,
} from '@config/types/user';

export type UserViewParams = GenericSearchPageReturnType<UserSearchParams, UserSearchItem>;

export const userDefaultParams: UserSearchParams = {
  idStatus: [UserStatuses.Draft, UserStatuses.Active, UserStatuses.Paused],
  size: 20,
  sortBy: 'dateAdded',
  sortType: 'desc',
};

export const userProtectedParams: UserSearchParams = {};

export const userDateFields: UserDateField[] = ['dateAdded', 'dateUpdated'];

export const userSortFields: UserSortFields[] = ['dateAdded', 'dateUpdated'];

//userTaskmaster
export type UserTaskmasterViewParams = UserViewParams;

export const userTaskmasterDefaultParams: UserSearchParams = userDefaultParams;

export const userTaskmasterProtectedParams: UserSearchParams = {};

export const userTaskmasterDateFields: UserDateField[] = userDateFields;

export const userTaskmasterSortFields: UserSortFields[] = userSortFields;

// pending Admin
export type UserPendingAdminViewParams = UserViewParams;

export const userPendingAdminDefaultParams = {};

export const userPendingAdminProtectedParams: GenericDynamicParams<UserSearchParams> = {
  idOnboardingStage: [UserOnboardingStage.Pending],
  size: 100,
};
export const userPendingAdminDateFields = [];
export const userPendingAdminSortFields = [];

// pending Admin Popover
export type UserPendingAdminPopoverViewParams = UserPendingAdminViewParams;

export const userPendingAdminPopoverDefaultParams = {
  size: 5,
};

export const userPendingAdminPopoverProtectedParams: GenericDynamicParams<UserSearchParams> = {
  idOnboardingStage: [UserOnboardingStage.Pending],
};
export const userPendingAdminPopoverDateFields = userPendingAdminDateFields;
export const userPendingAdminPopoverSortFields = userPendingAdminSortFields;

// pending taskmaster
export type UserPendingTaskmasterViewParams = UserViewParams;
export const userPendingTaskmasterDefaultParams = {};
export const userPendingTaskmasterProtectedParams: GenericDynamicParams<UserSearchParams> = {
  idOnboardingStage: [UserOnboardingStage.Pending],
  size: 100,
};
export const userPendingTaskmasterDateFields = [];
export const userPendingTaskmasterSortFields = [];

// pending taskmaster popover
export type UserPendingTaskmasterPopoverViewParams = UserPendingTaskmasterViewParams;
export const userPendingTaskmasterPopoverDefaultParams = {
  size: 5,
};
export const userPendingTaskmasterPopoverProtectedParams: GenericDynamicParams<UserSearchParams> = {
  idOnboardingStage: [UserOnboardingStage.Pending],
};
export const userPendingTaskmasterPopoverDateFields = userPendingTaskmasterDateFields;
export const userPendingTaskmasterPopoverSortFields = userPendingTaskmasterSortFields;
