import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';

// DO NOT PUT IT IN GLOBAL CONSTANTS. IT SIMPLY WON'T WORK FOR SOME REASONS...
const isTestEnv = process.env.NODE_ENV === 'test';

if (isTestEnv) {
  i18n.use(initReactI18next).init({
    lng: 'en',
    fallbackLng: 'en',
    // have a common namespace used around the full app
    ns: ['translation'],
    defaultNS: 'translation',
    debug: false,
    resources: {
      en: {
        translation: require('src/tests/locales/en/translation.json'),
      },
    },
  });
} else {
  i18n
    .use(Backend)
    // .use(LanguageDetector)
    .use(initReactI18next)
    .init(
      {
        fallbackLng: 'en',
        returnEmptyString: false,
        debug: process.env.NODE_ENV !== 'production',
      },
      () => {
        moment.locale(i18n.language);
      }
    );
}

export const _ = (
  args?: string,
  params?: Record<
    string,
    string | number | boolean | Record<string, string | number | boolean | undefined> | undefined
  >
): any => i18n.t(String(args), params);
export const translator = i18n;
export const changeLanguage = (val: string): void => {
  i18n.changeLanguage(val, () => {
    moment.locale(val);
  });
};
