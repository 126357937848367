import {
  PaymentOptionDateField,
  PaymentOptionSearchItem,
  PaymentOptionSearchParams,
  PaymentOptionSortFields,
  PaymentOptionStatuses,
} from '@config/types/paymentOption';
import { GenericSearchPageReturnType } from '@config/types/shared';

export type PaymentOptionViewParams = GenericSearchPageReturnType<
  PaymentOptionSearchParams,
  PaymentOptionSearchItem
>;

export const paymentOptionDefaultParams: PaymentOptionSearchParams = {
  idStatus: [PaymentOptionStatuses.Active],
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const paymentOptionProtectedParams: PaymentOptionSearchParams = {};

export const paymentOptionDateFields: PaymentOptionDateField[] = ['dateAdded', 'dateUpdated'];

export const paymentOptionSortFields: PaymentOptionSortFields[] = ['dateAdded', 'dateUpdated'];
