import {
  SubmissionTypeDateField,
  SubmissionTypeSearchItem,
  SubmissionTypeSearchParams,
  SubmissionTypeSortFields,
} from '@config/types/submissionType';
import { GenericSearchPageReturnType, StandardStatuses } from '@config/types/shared';

export type SubmissionTypeViewParams = GenericSearchPageReturnType<
  SubmissionTypeSearchParams,
  SubmissionTypeSearchItem
>;

export const submissionTypeDefaultParams: SubmissionTypeSearchParams = {
  idStatus: [StandardStatuses.Draft, StandardStatuses.Active, StandardStatuses.Paused],
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const submissionTypeProtectedParams: SubmissionTypeSearchParams = {};

export const submissionTypeDateFields: SubmissionTypeDateField[] = ['dateAdded', 'dateUpdated'];

export const submissionTypeSortFields: SubmissionTypeSortFields[] = ['dateAdded', 'dateUpdated'];
