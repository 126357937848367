// Docs: https://ant.design/@/notification/
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { message, notification } from 'antd';

message.config({ top: 5, duration: 3, maxCount: 1 });

notification.config({
  placement: 'bottomLeft',
  bottom: 40,
  duration: 0,
  maxCount: 5,
});

export const msg = {
  success: (...args: any) => {
    //@ts-ignore
    message.success(...args);
  },
  loading: (...args: any) => {
    //@ts-ignore
    message.loading(...args);
  },
  warn: (...args: any) => {
    //@ts-ignore
    message.warn(...args);
  },
  warning: (...args: any) => {
    notification.warning(args);
  },
  error: (args: any) => {
    notification.error({ ...args, icon: <ExclamationCircleOutlined style={{ color: '#fa5c7c' }} /> });
  },
  destroy: () => {
    message.destroy();
  },
};
