import { _ } from 'src/translator';

export const getCustomError = (
  endpoint: string,
  status: number,
  report: any,
  errorArgs: any,
  errorMsg: any
) => {
  const checkReport = (cb: () => boolean) => {
    if (!Array.isArray(report)) return false;
    return cb();
  };

  const customErrors = [
    {
      endpoints: ['makeReferringPageSnapshot'],
      condition: errorArgs?.suppress,
      suppress: true,
      title: '',
      description: '',
    },
    {
      endpoints: ['abandonReferringDomain'],
      condition: status === 400,
      suppress: true,
      title: '',
      description: '',
    },
    {
      endpoints: ['claimReferringDomain'],
      condition: status === 403 && errorMsg.includes('exceeded'),
      title: _('refDomains.limitReached', { limit: errorArgs?.domainLimit }),
      description: '',
    },
    {
      endpoints: ['claimReferringDomain'],
      condition: status === 403,
      title: _('refDomains.problematicDomain'),
      description: '',
    },
    {
      endpoints: ['assignReferringDomain', 'claimReferringDomain'],
      condition:
        status === 400 &&
        checkReport(() => !!report?.find((obj: any) => obj?.attributeName === 'unresolved_linked_issues')),
      title: _('refDomains.problematicDomain'),
      description: '',
    },
    {
      endpoints: ['assignReferringDomain', 'claimReferringDomain'],
      // condition: status === 400 && !!givenReport?.find((obj: any) => obj?.attributeName === 'id_Assignee'),
      condition: true,
      title: _('refDomains.failedToAssign', { name: errorArgs?.name }),
      description: '',
    },
    {
      endpoints: ['createAhrefs'],
      condition: status === 400,
      title: _('ahrefsAccounts.alreadyExits'),
      description: '',
    },
    {
      endpoints: ['makeReferringDomainSnapshot'],
      condition: status === 400,
      suppress: errorArgs?.suppress,
      title: _('misc.serpApiError'),
      description: '',
    },
    {
      endpoints: ['makeReferringDomainSnapshot'],
      suppress: errorArgs?.suppress,
      condition: status !== 400,
      title: _('misc.failedToFetchMetrics'),
      description: '',
    },
    {
      endpoints: ['setTaskReferringDomain'],
      condition:
        status === 400 && checkReport(() => !!report?.find((obj: any) => obj?.attributeName === 'is_legacy')),
      title: _('refDomains.domainIsTaken'),
      description: '',
    },
    {
      endpoints: ['setTaskReferringDomain'],
      condition:
        status === 400 &&
        checkReport(
          () =>
            !!report?.find(
              (obj: any) => obj?.attributeName === 'occupied_by_tasks' || obj?.attributeName === 'id_Role'
            )
        ),
      title: _('refDomains.domainIsTakenByUser'),
      description: '',
    },
    {
      endpoints: ['setTaskReferringDomain'],
      condition: status !== 400,
      title: _('refDomains.failedToLink'),
      description: '',
    },
    {
      endpoints: ['claimTaskReferringDomain'],
      condition: true,
      title: _('refDomains.domainBusy'),
      description: '',
    },
    {
      endpoints: ['claimTaskReferringPage', 'setTaskReferringPage'],
      condition: checkReport(() => !!report?.find((obj: any) => obj?.attributeName === 'response_code')),
      title: _('refPages.failedToFetchMetrics'),
      description: '',
    },
    {
      endpoints: ['claimTaskReferringPage', 'setTaskReferringPage'],
      condition: checkReport(() => !!report?.find((obj: any) => obj?.attributeName === 'id_Task')),
      title: _('refPages.alreadyLinked'),
      description: '',
    },
    {
      endpoints: ['claimTaskDonatedLanding'],
      condition: checkReport(() => !!report?.find((obj: any) => obj?.attributeName === 'tasks')),
      title: _('donatedLandings.alreadyLinked'),
      description: '',
    },
    {
      endpoints: ['setTaskReferringLink', 'claimTaskReferringLink'],
      condition:
        status === 400 && checkReport(() => !!report?.find((obj: any) => obj?.attributeName === 'id_Task')),
      title: _('refLinks.alreadyLinked'),
      description: '',
    },
    {
      endpoints: ['setTaskReferringLink', 'claimTaskReferringLink'],
      condition: status === 404,
      title: _('refLinks.noAnchor'),
      description: '',
    },
    {
      endpoints: ['checkPriceSettingPagePrice'],
      condition: status === 400,
      title: _(
        errorMsg?.indexOf('Domain Rating') > -1 ? 'tasks.lowDomainRating' : 'tasks.failedToCalculateReward'
      ),
      description: '',
    },
    {
      endpoints: ['updateProfileReferralCode'],
      condition: status === 409,
      title: _('users.referralCodeInUse'),
      description: '',
    },
    {
      endpoints: ['createUser'],
      condition: status === 409,
      title: _('users.userAlreadyExists'),
      description: '',
    },
    {
      endpoints: ['authLogin'],
      condition: true,
      title: _('auth.authFailed'),
      description: '',
    },
    {
      endpoints: ['setUserPasswordEmail'],
      condition: true,
      title: _('users.sendNewPasswordToEmailError'),
      description: '',
    },
    {
      endpoints: ['resetUserPassword'],
      condition: true,
      title: _('users.passwordUpdateFailed'),
      description: '',
    },
    {
      endpoints: ['checkPriceSettingFormula'],
      condition: true,
      title: '',
      description: '',
      suppress: true,
    },
    {
      endpoints: ['createReferringDomain'],
      condition: true,
      title: '',
      description: '',
      suppress: true,
    },
    {
      endpoints: ['makeReferringDomainsSnapshots'],
      condition: true,
      title: '',
      description: '',
      suppress: true,
    },
  ];

  return customErrors.find((error) => error.endpoints.includes(endpoint) && error.condition);
};
