import packageJSON from '../../package.json';

export const SITE_NAME = 'JustLinkIt LMS'; // sync with <title> from index.html
export const DEBUG_MODE = process.env.REACT_APP_DEBUG;
export const APP_VERSION = packageJSON.version;

export const COUNTER_UPDATE_INTERVAL = 60;
export const BREADCRUMBS_MAX_ITEMS_COUNT = 6;

export const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export const ROLES = { ADMIN: 1, TASKMASTER: 2, LINKBUILDER: 3 };

export const DATE_TIME_FORMAT_FULL = 'DD.MM.YYYY HH:mm';
export const DATE_TIME_FORMAT_SHORT = 'MMM Do, HH:mm';

export const DEFAULT_FIRST_COLOR = '#4361ff';
export const DEFAULT_SECOND_COLOR = '#8541fe';

export const getFileExtension = (name: string) => {
  return '.' + name.split('.').pop() || '';
};

export const ACCEPTED_IMAGE_FILE_EXTENSIONS = ['.gif', '.jpeg', '.jpg', '.png', '.svg', '.webp'];

export const ACCEPTED_DOCS_EXTENSIONS = [
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.rtf',
  '.csv',
  '.zip',
  '.odt',
  '.html',
];

export const ACCEPTED_DOC_INLINE_EXTENSIONS = ['.pdf', '.txt'];

export const ACCEPTED_VIDEO_EXTENSIONS = ['.avi', '.mp4', '.ogg', '.webm'];

export const ACCEPTED_AUDIO_EXTENSIONS = ['.mp3', '.wav'];

export const ACCEPTED_FILE_EXTENSIONS = [
  ...ACCEPTED_IMAGE_FILE_EXTENSIONS,
  ...ACCEPTED_DOCS_EXTENSIONS,
  ...ACCEPTED_DOC_INLINE_EXTENSIONS,
  ...ACCEPTED_VIDEO_EXTENSIONS,
  ...ACCEPTED_AUDIO_EXTENSIONS,
];

export const INLINE_FILE_EXTENSIONS = [
  ...['.gif', '.jpeg', '.jpg', '.png', '.svg'],
  ...ACCEPTED_DOC_INLINE_EXTENSIONS,
  ...['.mp4', '.webm'],
  ...ACCEPTED_AUDIO_EXTENSIONS,
];

export const LANGUAGES: {
  id: string;
  name: string;
  active: boolean;
  debug?: boolean;
}[] = [
  {
    id: 'en',
    name: 'languages.english',
    active: true,
  },
  // {
  //   id: 'de',
  //   name: 'languages.german',
  //   active: false,
  // },
  // {
  //   id: 'af',
  //   name: 'misc.test',
  //   active: false,
  //   debug: true
  // }
];

export const JIRA_PROJECT = {
  FIN: 'FNEW',
};

export type EntityType = {
  title: string;
  icon: string;
  url: string;
  slug: string;
};

export const PAGE_SIZE_OPTIONS = ['5', '10', '20', '30', '40', '50', '60', '70', '80', '90', '100'];

export const ENTITY = {
  // API
  getIcon: ({ icon }: { icon: string }, mod?: string): string => `${icon} ${mod}`,
  getEndpoint: ({ url }: { url: string }, path?: string | number | [number, string]): string =>
    `${url}${path ? '/' : ''}${Array.isArray(path) ? path.filter((i) => i).join('/') : path || ''}`,
  // List
  ANCHORS: {
    title: 'anchors.anchors',
    icon: 'fa-anchor',
    url: '/anchors',
    slug: 'anchor',
  },
  AHREFS: {
    title: 'ahrefsAccounts.ahrefsAccounts',
    icon: 'fa-address-card',
    url: '/ahrefs',
    slug: 'ahrefs',
  },
  ATTAINMENT_PAYMENTS: {
    title: 'attainmentPayments.attainmentPayments',
    icon: 'fa-comment-alt-dollar',
    url: '/attainment-payments',
    slug: 'attainmentPayment',
  },
  ATTAINMENT_PAYMENTS_BATCH: {
    title: 'attainmentPaymentBatches.attainmentPaymentBatches',
    icon: 'fa-file-invoice-dollar',
    url: '/attainment-payment-batches',
    slug: 'attainmentPaymentBatch',
  },
  BONUS_PAYMENTS: {
    title: 'bonusPayments.bonusPayments',
    icon: 'fa-sack-dollar',
    url: '/bonus-payments',
    slug: 'bonusPayment',
  },
  DONATED_LANDINGS: {
    title: 'donatedLandings.donatedLandings',
    icon: 'fa-hand-holding-box',
    url: '/donated-landings',
    slug: 'donatedLanding',
  },
  DONATED_SITES: {
    title: 'donatedSites.donatedSites',
    icon: 'fa-hand-holding-usd',
    url: '/donated-sites',
    slug: 'donatedSite',
  },
  EVENTS: {
    title: 'events.events',
    icon: 'fa-calendar-exclamation',
    url: '/events',
    slug: 'event',
  },
  FAQS: {
    title: 'faqs.faq',
    icon: 'fa-comments',
    url: '/faq',
    slug: 'faq',
  },
  FEEDBACKS: {
    title: 'feedbacks.feedbacks',
    icon: 'fa-comment-alt-smile',
    url: '/feedbacks',
    slug: 'feedback',
  },
  FILES: {
    title: 'files.files',
    icon: 'fa-download',
    url: '/files',
    slug: 'file',
  },
  ISSUES: {
    title: 'issues.issues',
    icon: 'fa-exclamation-triangle',
    url: '/issues',
    slug: 'issue',
  },
  LANDINGS: {
    title: 'landings.landings',
    icon: 'fa-copy',
    url: '/landings',
    slug: 'landing',
  },
  LINK_ATTRIBUTES: {
    title: 'linkAttrs.linkAttrs',
    icon: 'fa-chess',
    url: '/link-attributes',
    slug: 'linkAttribute',
  },
  LINK_PLANS: {
    title: 'linkPlans.linkPlans',
    icon: 'fa-bullseye-arrow',
    url: '/link-plans',
    slug: 'linkPlan',
  },
  ORGANICNESS: {
    title: 'organicness.organicness',
    icon: 'fa-leaf',
    url: '/organicness',
    slug: 'organicness',
  },
  PAYMENT_DETAILS: {
    title: 'paymentDetails.paymentDetails',
    icon: 'fa-money-check-edit-alt',
    url: '/payment-details',
    slug: 'paymentDetail',
  },
  PAYMENT_OPTIONS: {
    title: 'paymentOptions.paymentOptions',
    icon: 'fa-credit-card',
    url: '/payment-options',
    slug: 'paymentOption',
  },
  PENALTIES: {
    title: 'penalties.penalties',
    icon: 'fa-exclamation-square',
    url: '/financial-penalty',
    slug: 'penalty',
  },
  PRICE_SETTINGS: {
    title: 'priceSettings.priceSettings',
    icon: 'fa-calculator',
    url: '/price-settings',
    slug: 'priceSetting',
  },
  REF_DOMAIN_ISSUES: {
    title: 'refDomainIssues.refDomainIssues',
    icon: 'fa-sensor-alert',
    url: '/referring-domains-issues',
    slug: 'refDomainIssue',
  },
  REF_DOMAIN_SNAPSHOTS: {
    title: 'refDomainSnapshots.refDomainSnapshots',
    icon: 'fa-camera-retro',
    url: '/referring-domains-snapshots',
    slug: 'refDomainSnapshot',
  },
  REF_DOMAINS: {
    title: 'refDomains.refDomains',
    icon: 'fa-cabinet-filing',
    url: '/referring-domains',
    slug: 'refDomain',
  },
  REF_DOMAIN_PENALTY: {
    title: 'refDomainPenalties.refDomainPenalties',
    icon: 'fa-exclamation-square',
    url: '/webmasters',
    slug: 'webmaster',
  },
  REF_LINK_ISSUES: {
    title: 'refLinkIssues.refLinkIssues',
    icon: 'fa-sensor-alert',
    url: '/referring-domains-penalties',
    slug: 'refDomainPenalty',
  },
  REF_LINKS: {
    title: 'refLinks.refLinksShort',
    icon: 'fa-code',
    url: '/referring-links',
    slug: 'refLink',
  },
  REF_PAGE_ISSUES: {
    title: 'refPageIssues.refPageIssues',
    icon: 'fa-comment-exclamation',
    url: '/referring-pages-issues',
    slug: 'refPageIssue',
  },
  REF_PAGE_SNAPSHOTS: {
    title: 'refPageSnapshots.refPageSnapshots',
    icon: 'fa-camera-retro',
    url: '/referring-pages-snapshots',
    slug: 'refPageSnapshot',
  },
  REF_PAGES: {
    title: 'refPages.refPagesShort',
    icon: 'fa-file-code',
    url: '/referring-pages',
    slug: 'refPage',
  },
  REF_USERS: {
    title: 'users.refUsers',
    icon: 'fa-users-class',
    url: '/referred-users',
    slug: 'refUser',
  },
  SITES: {
    title: 'sites.sites',
    icon: 'fa-solar-system',
    url: '/sites',
    slug: 'site',
  },
  SUBMISSION_CONTENTS: {
    title: 'subContents.subContents',
    icon: 'fa-hand-receiving',
    url: '/submission-contents',
    slug: 'submissionContent',
  },
  SUBMISSION_PAYMENTS: {
    title: 'subPayments.subPayments',
    icon: 'fa-hands-usd',
    url: '/submission-payments',
    slug: 'submissionPayment',
  },
  SUBMISSION_TYPES: {
    title: 'subTypes.subTypes',
    icon: 'fa-handshake ',
    url: '/submission-types',
    slug: 'submissionType',
  },
  TASKS: {
    title: 'tasks.tasks',
    icon: 'fa-clipboard-check',
    url: '/tasks',
    slug: 'task',
  },
  TEAM: {
    title: 'team.teams',
    icon: 'fa-user-friends',
    url: '/teams',
    slug: 'team',
  },
  THEMATIC_CONFORMITY: {
    title: 'thematicConf.thematicConf',
    icon: 'fa-tags',
    url: '/thematic-conformity',
    slug: 'thematicConf',
  },
  USERS: {
    title: 'users.users',
    icon: 'fa-users',
    url: '/users',
    slug: 'user',
  },
  WEBMASTERS: {
    title: 'webmasters.webmasters',
    icon: 'fa-address-book',
    url: '/webmasters',
    slug: 'webmaster',
  },
};
