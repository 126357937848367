import {
  AttainmentPaymentDateField,
  AttainmentPaymentSearchItem,
  AttainmentPaymentSearchParams,
  AttainmentPaymentSortFields,
  AttainmentPaymentStatuses,
} from '@config/types/attainmentPayment';
import { GenericDynamicParams, GenericSearchPageReturnType } from '@config/types/shared';

export type AttainmentPaymentPaymentViewParams = GenericSearchPageReturnType<
  AttainmentPaymentSearchParams,
  AttainmentPaymentSearchItem
>;

export const attainmentPaymentDefaultParams: AttainmentPaymentSearchParams = {
  idStatus: [AttainmentPaymentStatuses.Unverified, AttainmentPaymentStatuses.Pending],
  sortBy: 'dateDue',
  sortType: 'asc',
};

export const attainmentPaymentProtectedParams: AttainmentPaymentSearchParams = {};

export const attainmentPaymentDateFields: AttainmentPaymentDateField[] = [
  'dateAdded',
  'dateUpdated',
  'dateDue',
];

export const attainmentPaymentSortFields: AttainmentPaymentSortFields[] = [
  'dateAdded',
  'dateUpdated',
  'dateDue',
  'amount',
];

// OverviewLinkbuilder
export type AttainmentPaymentLinkbuilderOverviewViewParams = AttainmentPaymentPaymentViewParams;
export const attainmentPaymentLinkbuilderOverviewDefaultParams: AttainmentPaymentSearchParams = {
  size: 5,
  sortBy: 'dateUpdated',
  sortType: 'desc',
};
export const attainmentPaymentLinkbuilderOverviewProtectedParams: AttainmentPaymentSearchParams = {};
export const attainmentPaymentLinkbuilderOverviewDateFields = attainmentPaymentDateFields;
export const attainmentPaymentLinkbuilderOverviewSortFields = attainmentPaymentSortFields;

// Linkbuilder single
export type AttainmentPaymentLinkbuilderSingleViewParams = AttainmentPaymentPaymentViewParams;
export const attainmentPaymentLinkbuilderSingleDefaultParams: AttainmentPaymentSearchParams = {
  size: 5,
  sortBy: 'dateUpdated',
  sortType: 'desc',
};
export const attainmentPaymentLinkbuilderrSingleProtectedParams: GenericDynamicParams<AttainmentPaymentSearchParams> =
  {
    idRecipient: 'dynamic',
  };
export const attainmentPaymentLinkbuilderSingleDateFields = attainmentPaymentDateFields;
export const attainmentPaymentLinkbuilderSingleSortFields = attainmentPaymentSortFields;
