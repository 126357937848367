import { ProfilePreferences } from '@config/types/profile';
import { omit } from 'lodash';
import { SearchDictInterface } from 'src/store/preferences/preferencesSlice';

export const getPreferencesWithoutPages = (preferences: ProfilePreferences) => {
  const updatedSearch: SearchDictInterface = {};

  if (preferences.search) {
    Object.keys(preferences.search).forEach((key) => {
      updatedSearch[key] = omit(preferences?.search?.[key] || {}, 'page');
    });
  }
  return {
    ...preferences,
    search: updatedSearch,
  };
};
