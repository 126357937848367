// *
// * CELEBRATE EVERYDAY! :)
// *
import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@helpers/store';
import { celebrateSelector } from 'src/store/status/statusSlice';
import { celebrate } from 'src/store/status/statusActions';

import styles from './Confetti.module.less';
import cn from 'classnames';
import { UserRoles } from '@config/types/user';

// ! Using state.status.celebrate
// Show confetti -> dispatch(celebrate('👏'));

export const Confetti: FC<{ userRole?: UserRoles }> = ({ userRole }) => {
  const msg = useAppSelector(celebrateSelector);
  const c = new Array(150).fill(0, 0);
  const dispatch = useAppDispatch();
  const isAdmin = userRole === UserRoles.Admin;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      msg && dispatch(celebrate(false)); // hide confetti after 2.5 ms
    }, 2500);
    return () => clearTimeout(timeoutId);
  }, [msg]);

  if (isAdmin) return null; // admin don't celebrate! admin do work!

  return msg ? (
    <div className={styles.wrapper}>
      {c.map((_, idx) => (
        <div key={idx} className={cn(styles.confetti, styles[`confetti-${idx}`])}></div>
      ))}
      {msg && <div className={styles.emoji}>{msg}</div>}
    </div>
  ) : null;
};
