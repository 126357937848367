import { GenericDynamicParams, GenericSearchPageReturnType } from '@config/types/shared';
import {
  IssueDateField,
  IssueSearchItem,
  IssueSearchParams,
  IssueSortFields,
  IssueStatuses,
} from '@config/types/issue';

export type IssueViewParams = GenericSearchPageReturnType<IssueSearchParams, IssueSearchItem>;

export const issueDefaultParams: IssueSearchParams = {
  idStatus: [IssueStatuses.Unresolved],
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const issueProtectedParams: IssueSearchParams = {};

export const issueDateFields: IssueDateField[] = ['dateAdded', 'dateUpdated'];

export const issueSortFields: IssueSortFields[] = ['dateAdded', 'dateUpdated'];

// Issue Author viewew
export type IssueAuthorViewParams = IssueViewParams;

export const issueAuthorDefaultParams: IssueSearchParams = {
  sortBy: 'dateUpdated',
  sortType: 'desc',
  size: 5,
};

export const issueAuthorProtectedParams: GenericDynamicParams<IssueSearchParams> = {
  idStatus: [IssueStatuses.Unresolved],
  idAuthor: 'dynamic',
};

export const issueAuthorDateFields: IssueDateField[] = issueDateFields;

export const issueAuthorSortFields: IssueSortFields[] = issueSortFields;

// Issue Author view
export type IssueAssigneeViewParams = IssueViewParams;

export const issueAssigneeDefaultParams: IssueSearchParams = {
  sortBy: 'dateUpdated',
  sortType: 'desc',
  size: 5,
};

export const issueAssigneeProtectedParams: GenericDynamicParams<IssueSearchParams> = {
  idStatus: [IssueStatuses.Unresolved],
  idAssignee: 'dynamic',
};

export const issueAssigneeDateFields: IssueDateField[] = issueDateFields;

export const issueAssigneeSortFields: IssueSortFields[] = issueSortFields;

// Issue Team
export type IssueTeamViewParams = IssueViewParams;

export const issueTeamDefaultParams: IssueSearchParams = {
  sortBy: 'dateUpdated',
  sortType: 'desc',
  size: 10,
};

export const issueTeamProtectedParams: GenericDynamicParams<IssueSearchParams> = {
  idStatus: [IssueStatuses.Unresolved],
  idAssignee: 'dynamic',
};
export const issueTeamDateFields: IssueDateField[] = issueDateFields;

export const issueTeamSortFields: IssueSortFields[] = issueSortFields;

// Issue UserProfile
export type IssueUserViewParams = IssueViewParams;

export const issueUserDefaultParams: IssueSearchParams = {
  sortBy: 'dateUpdated',
  sortType: 'desc',
  size: 10,
};

export const issueUserProtectedParams: GenericDynamicParams<IssueSearchParams> = {
  idStatus: [IssueStatuses.Unresolved],
  idAssignee: 'dynamic',
};
export const issueUserDateFields: IssueDateField[] = issueDateFields;

export const issueUserSortFields: IssueSortFields[] = issueSortFields;
