import { GenericSearchPageReturnType } from '@config/types/shared';
import {
  ReferringDomainIssueDateField,
  ReferringDomainIssueSearchItem,
  ReferringDomainIssueSearchParams,
  ReferringDomainIssueSortFields,
  ReferringDomainIssueStatuses,
} from '@config/types/referringDomainIssue';

export type ReferringDomainIssueViewParams = GenericSearchPageReturnType<
  ReferringDomainIssueSearchParams,
  ReferringDomainIssueSearchItem
>;

export const referringDomainIssueDefaultParams: ReferringDomainIssueSearchParams = {
  sortBy: 'dateAdded',
  sortType: 'desc',
  idStatus: [ReferringDomainIssueStatuses.Active],
};

export const referringDomainIssueProtectedParams: ReferringDomainIssueSearchParams = {};

export const referringDomainIssueDateFields: ReferringDomainIssueDateField[] = ['dateAdded', 'dateUpdated'];

export const referringDomainIssueSortFields: ReferringDomainIssueSortFields[] = ['dateAdded', 'dateUpdated'];
