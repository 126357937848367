import { GenericDynamicParams, GenericSearchPageReturnType } from '@config/types/shared';
import {
  SubmissionPaymentDateField,
  SubmissionPaymentSearchItem,
  SubmissionPaymentSearchParams,
  SubmissionPaymentSortFields,
  SubmissionPaymentStatuses,
} from '@config/types/submissionPayment';

export type SubmissionPaymentViewParams = GenericSearchPageReturnType<
  SubmissionPaymentSearchParams,
  SubmissionPaymentSearchItem
>;

export const submissionPaymentDefaultParams: SubmissionPaymentSearchParams = {
  idStatus: [SubmissionPaymentStatuses.Requested, SubmissionPaymentStatuses.Pending],
  sortBy: 'dateDue',
  sortType: 'asc',
};

export const submissionPaymentProtectedParams: SubmissionPaymentSearchParams = {};
export const submissionPaymentDateFields: SubmissionPaymentDateField[] = [
  'dateUpdated',
  'dateAdded',
  'dateDue',
  'datePaid',
];
export const submissionPaymentSortFields: SubmissionPaymentSortFields[] = [
  'dateUpdated',
  'dateAdded',
  'dateDue',
  'datePaid',
];

export type SubmissionPaymentTaskmasterViewParams = SubmissionPaymentViewParams;
export const submissionPaymentTaskmasterDefaultParams: SubmissionPaymentSearchParams =
  submissionPaymentDefaultParams;

export const submissionPaymentTaskmasterProtectedParams: GenericDynamicParams<SubmissionPaymentSearchParams> =
  {
    idTaskAuthor: 'dynamic',
  };
export const submissionPaymentTaskmasterDateFields: SubmissionPaymentDateField[] =
  submissionPaymentDateFields;
export const submissionPaymentTaskmasterSortFields: SubmissionPaymentSortFields[] =
  submissionPaymentSortFields;
