import { AscDesc, Patch } from './shared';
import { User } from '@config/types/user';
import { PaymentDetailItem } from '@config/types/paymentDetail';
import { IssueFinancialPenalty } from '@config/types/issue';

export enum FinancialPenaltyStatuses {
  Unverified = 1,
  Pending,
  Executed,
  Canceled,
  Reverted,
  Declined,
  Discarded,
}

export type FinancialPenaltyStatusActions =
  | 'verify'
  | 'execute'
  | 'discard'
  | 'decline'
  | 'cancel'
  | 'revert';

export type FinancialPenalty = {
  id: number;
  dateAdded: string;
  dateUpdated?: string;
  dateDue?: string;
  name: string;
  description?: string;
  amount: number;
  idStatus: FinancialPenaltyStatuses;
};

export type FinancialPenaltySearchItem = FinancialPenalty & {
  author: User;
  recipient: User;
  paymentDetail?: PaymentDetailItem;
  issue?: IssueFinancialPenalty;
};

export type FinancialPenaltyDetails = FinancialPenaltySearchItem;

export type FinancialPenaltySuggestPayload = {
  name: string;
  description?: string;
  amount: number;
  idRecipient: number;
  idIssue?: number;
  idTeam: number;
};

export type FinancialPenaltySuggestRequest = FinancialPenaltySuggestPayload;

export type FinancialPenaltyCreatePayload = {
  name: string;
  description?: string;
  amount: number;
  idRecipient: number;
  idIssue?: number;
};

export type FinancialPenaltyCreateRequest = FinancialPenaltyCreatePayload;

export type FinancialPenaltyUpdatePayload = {
  name?: string;
  description?: string;
  amount?: number;
};

export type FinancialPenaltyUpdateRequest = Patch<FinancialPenaltyUpdatePayload>;

export type FinancialPenaltyExecutePayload = {
  idPaymentDetail: number;
};

export type FinancialPenaltyExecuteRequest = Patch<FinancialPenaltyExecutePayload>;
export type FinancialPenaltyStatsParams = {
  idFrom?: number;
  idTo?: number;
  dateAddedFrom?: string;
  dateAddedTo?: string;
  dateUpdatedFrom?: string;
  dateUpdatedTo?: string;
  dateDueFrom?: string;
  dateDueTo?: string;
  amountFrom?: number;
  amountTo?: number;
  name?: string;
  idStatus?: FinancialPenaltyStatuses[];
  idAuthor?: number[];
  idRecipient?: number[];
  idIssue?: number[];
  idPaymentDetail?: number[];
};

export type FinancialPenaltySearchParams = FinancialPenaltyStatsParams & {
  sortType?: AscDesc;
  sortBy?: FinancialPenaltySortFields;
  page?: number;
  size?: number;
};

export type FinancialPenaltySortFields =
  | 'id'
  | 'dateAdded'
  | 'dateUpdated'
  | 'dateDue'
  | 'name'
  | 'amount'
  | 'idStatus'
  | 'idType'
  | 'idRecipient'
  | 'idPaymentDetail';

export type FinancialPenaltyDateField = 'dateAdded' | 'dateUpdated' | 'dateDue';
