import { GenericSearchPageReturnType, GenericDynamicParams } from '@config/types/shared';
import {
  LinkPlanDateField,
  LinkPlanSearchItem,
  LinkPlanSearchParams,
  LinkPlanSortFields,
  LinkPlanStatuses,
} from '@config/types/linkPlan';

export type LinkPlanViewParams = GenericSearchPageReturnType<LinkPlanSearchParams, LinkPlanSearchItem>;

export const linkPlanDefaultParams: LinkPlanSearchParams = {
  idStatus: [LinkPlanStatuses.Draft, LinkPlanStatuses.InProgress, LinkPlanStatuses.Paused],
  sortBy: 'dateDue',
  sortType: 'asc',
};

export const linkPlanProtectedParams: LinkPlanSearchParams = {};

export const linkPlanDateFields: LinkPlanDateField[] = ['dateAdded', 'dateUpdated', 'dateDue', 'dateClosed'];

export const linkPlanSortFields: LinkPlanSortFields[] = [
  'name',
  'tasksCount',
  'dateDue',
  'dateAdded',
  'dateUpdated',
];

// Pending admin
export type LinkPlanPendingAdminViewParams = LinkPlanViewParams;
export const linkPlanPendingAdminDefaultParams = {};
export const linkPlanPendingAdminProtectedParams: GenericDynamicParams<LinkPlanSearchParams> = {
  idStatus: [LinkPlanStatuses.Draft],
  size: 100,
};
export const linkPlanPendingAdminDateFields = [];
export const linkPlanPendingAdminSortFields = [];

// Pending admin popover
export type LinkPlanPendingAdminPopoverViewParams = LinkPlanPendingAdminViewParams;
export const linkPlanPendingAdminPopoverDefaultParams = { size: 5 };
export const linkPlanPendingAdminPopoverProtectedParams: GenericDynamicParams<LinkPlanSearchParams> = {
  idStatus: [LinkPlanStatuses.Draft],
};
export const linkPlanPendingAdminPopoverDateFields = linkPlanPendingAdminDateFields;
export const linkPlanPendingAdminPopoverSortFields = linkPlanPendingAdminDateFields;

// Pending taskmaster
export type LinkPlanPendingTaskmasterViewParams = LinkPlanViewParams;
export const linkPlanPendingTaskmasterDefaultParams = {};
export const linkPlanPendingTaskmasterProtectedParams: GenericDynamicParams<LinkPlanSearchParams> = {
  idStatus: [LinkPlanStatuses.ToDo],
  idAssignee: 'dynamic',
  size: 100,
};
export const linkPlanPendingTaskmasterDateFields = [];
export const linkPlanPendingTaskmasterSortFields = [];

// Pending taskmaster popover
export type LinkPlanPendingTaskmasterPopoverViewParams = LinkPlanPendingTaskmasterViewParams;
export const linkPlanPendingTaskmasterPopoverDefaultParams = { size: 5 };
export const linkPlanPendingTaskmasterPopoverProtectedParams: GenericDynamicParams<LinkPlanSearchParams> = {
  idStatus: [LinkPlanStatuses.ToDo],
  idAssignee: 'dynamic',
};
export const linkPlanPendingTaskmasterPopoverDateFields = linkPlanPendingTaskmasterDateFields;
export const linkPlanPendingTaskmasterPopoverSortFields = linkPlanPendingTaskmasterSortFields;

// taskmaster
export type LinkPlanTaskmasterViewParams = LinkPlanViewParams;
export const linkPlanTaskmasterDefaultParams = {
  idStatus: [LinkPlanStatuses.ToDo, LinkPlanStatuses.InProgress, LinkPlanStatuses.Paused],
  size: 10,
};
export const linkPlanTaskmasterProtectedParams: GenericDynamicParams<LinkPlanSearchParams> = {
  idAssignee: 'dynamic',
};
export const linkPlanTaskmasterDateFields = [];
export const linkPlanTaskmasterSortFields = [];

// taskmasterSearch
export type LinkPlanTaskmasterSearchViewParams = LinkPlanViewParams;
export const linkPlanTaskmasterSearchDefaultParams = {
  idStatus: [LinkPlanStatuses.ToDo, LinkPlanStatuses.InProgress, LinkPlanStatuses.Paused],
  sortBy: 'dateDue',
  sortType: 'asc',
};
export const linkPlanTaskmasterSearchProtectedParams: GenericDynamicParams<LinkPlanSearchParams> = {
  idAssignee: 'dynamic',
};
export const linkPlanTaskmasterSearchDateFields = linkPlanDateFields;
export const linkPlanTaskmasterSearchSortFields = linkPlanSortFields;
