import { createSlice } from '@reduxjs/toolkit';

export type BreadcrumbType = {
  from: string;
  to: string;
};

type BreadcrumbsStateType = {
  items: BreadcrumbType[];
};

const initialState: BreadcrumbsStateType = {
  items: [],
};

export const breadcrumbs = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setItems(
      state,
      {
        payload,
      }: {
        payload: {
          items: BreadcrumbType[];
        };
      }
    ) {
      state.items = payload.items;
    },
  },
});

export default breadcrumbs.reducer;

export const breadcrumbsSelector = ({
  breadcrumbs,
}: {
  breadcrumbs: BreadcrumbsStateType;
}): BreadcrumbType[] => breadcrumbs.items;
