import { configureStore, combineReducers, AnyAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { emptyApi } from 'src/store/emptyApi';

//New Reducers
import auth from 'src/store/auth/authSlice';
import preferences from 'src/store/preferences/preferencesSlice';

// Reducers
import breadcrumbs from 'src/store/breadcrumbs/breadcrumbsSlice';
import status from 'src/store/status/statusSlice';

const devTools = !!process.env.REACT_APP_DEVTOOLS || process.env.NODE_ENV !== 'production';

export const appReducer = combineReducers({
  auth,
  preferences,
  status,
  breadcrumbs,
  // Entities
  [emptyApi.reducerPath]: emptyApi.reducer,
});

export type RootState = ReturnType<typeof appReducer>;

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'preferences'],
};

const createStore = () =>
  configureStore({
    reducer: persistReducer(persistConfig, appReducer),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
        thunk: true,
        devTools,
      }).concat([emptyApi.middleware]),
  });

export const store = createStore();
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type LocalDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type AppGetState = () => RootState;
export const useAppDispatch = (): LocalDispatch => useDispatch<LocalDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
