import { User } from '@config/types/user';
import moment from 'moment';
import { isArray, uniqBy, upperFirst } from 'lodash';
import { isMoment } from 'moment';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { TeamDetails } from '@config/types/team';
import { MouseEvent } from 'react';

export const trunc = (str: string, max: number): string =>
  str.length > max ? str.substr(0, max - 1) + '…' : str;

export const extractIds = (object: Record<string | number, unknown>): number[] =>
  Object.keys(object).map((i) => +i);

export const serializeSearchParams = (
  inputParams: Record<string | number, unknown>
): Record<string | number, unknown> => {
  const params = { ...inputParams }; // is enough shallow copy?

  // Clear bad\empty params
  const skipList = ['pages', 'total'];
  Object.keys(params).forEach((key) => {
    // Cleanup undefined and empty values
    if ((typeof params[key] !== 'boolean' && !params[key]) || skipList.includes(key)) {
      delete params[key];
    }
    // Check & format special date time range fields
    else if (isArray(params[key]) && isMoment((params[key] as moment.Moment[])[0])) {
      const dates = params[key] as moment.Moment[];
      params[key] = dates.map((date: moment.Moment) => date.utc().format());
    }
    // Trim all string spaces
    else if (typeof params[key] === 'string') {
      params[key] = String(params[key]).trim();
    }
  });

  return params;
};

export const filterOptionsByName = (
  inputValue = '',
  option: React.ReactElement
): RegExpMatchArray | null | boolean => {
  if (!inputValue) return true;
  try {
    const query = inputValue.trim().replace(/\s/g, '.*?');
    const item = option.props.name,
      regEx = new RegExp(query, 'gmi');
    return String(item).match(regEx);
  } catch (e) {
    //
  }
  return false;
};

export const filterOptions = (inputValue = '', option: DefaultOptionType | undefined): boolean => {
  return !!~String(option?.children).toLowerCase().indexOf(inputValue.toLowerCase());
};

export const regExp = (str: string): RegExp | string => {
  if (!str) return '';
  try {
    const result = new RegExp(str.replace(/\s/g, '.*?'), 'gmi');
    return result || '';
  } catch (e) {
    //
  }
  return '';
};

export const filterListByKey = <T extends Record<string, any>>({
  source,
  key = 'name',
  query = '',
}: {
  source?: T[];
  key?: string;
  query: string;
}): T[] | undefined => source?.filter((item) => String(item[key]).match(regExp(query)));

export const columnFilterFrom = (object: Record<string | number, React.ReactNode>): ColumnFilterItem[] =>
  Object.entries(object).map(([value, text]) => ({ value, text }));

export const checkOverdue = (dateDue: string): boolean | undefined => {
  if (!dateDue) return;
  const now = moment.utc(); // from client -> to UTC
  return moment.utc(dateDue).diff(now, 'seconds') <= 0;
};

export const sortByStatusId = (
  tasks: {
    statusId: number;
    [key: string]: unknown;
  }[]
): {
  statusId: number;
  [key: string]: unknown;
}[] => {
  return [...tasks]
    .sort((a, b) => a.statusId - b.statusId) // 2, 1
    .sort((a) => (a.statusId >= 3 ? -1 : 1));
};

export const nameSorter = (
  a: {
    name: string;
    [key: string]: unknown;
  },
  b: {
    name: string;
    [key: string]: unknown;
  }
): number => String(a.name).localeCompare(b.name);

interface DateUpdatedInterface {
  dateUpdated?: string;
}

export const dateUpdatedSorter = (a: DateUpdatedInterface, b: DateUpdatedInterface): number =>
  new Date(b.dateUpdated || 0).getTime() - new Date(a.dateUpdated || 0).getTime();

export const getCurrencyTypeFromKey = (key: unknown, defaultKey = ''): string => {
  const match = String(key).match(/(USD|EUR)/gi) || [];
  // @ts-ignore
  return match.length ? match[0].toUpperCase() : defaultKey;
};

export const splitCamelCaseString = (str: unknown): string => {
  return str
    ? String(str)
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, function (str) {
          return str.toUpperCase();
        })
    : '';
};

export const getUrlParams = ({
  search,
}: {
  search: string;
}): Record<string, unknown> | Record<string, never> => {
  const params: Record<string, unknown> | Record<string, never> = {};
  if (!search) return params;
  const hashes = search.slice(search.indexOf('?') + 1).split('&');
  hashes.map((hash) => {
    const [key, val] = hash.split('=');
    params[key] = decodeURIComponent(val);
  });
  return params;
};

export const parseUrl = (
  link = ''
):
  | {
      url: string;
      page: string;
      domain: string;
    }
  | { [key: string]: never } => {
  if (!link) return {}; // TODO: catch empty links
  try {
    const url = new URL(link);
    const domain = url.hostname.replace(/www\./gi, '');
    const params = getUrlParams(url);
    const pathname = url.pathname;
    const breadcrumbs = pathname.split('/');
    let page = String(breadcrumbs.pop() || pathname).replace(/\//g, '') || 'homepage';

    //
    page = upperFirst(page).replace(/_/g, '-');

    // Youtube
    if (domain.match(/youtube\.com/)) {
      page = `Youtube video (${params.v})`;
    }

    return { url: link, page, domain };
  } catch (e) {
    return { url: link, page: link, domain: link };
  }
};

export const checkNumber = (value: unknown, finalValue: string, noValue?: string) =>
  typeof value === 'number' ? finalValue : noValue || '';

export const debouncePromise = (inner: (...args: any) => any, ms = 0): ((...args: any) => Promise<any>) => {
  let timer: ReturnType<typeof setTimeout> | null = null;
  let resolves: any[] = [];

  return (...args: any[]) => {
    // Run the function after a certain amount of time
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      // Get the result of the inner function, then apply it to the resolve function of
      // each promise that has been created since the last time the inner function was run
      const result = inner(...args);
      resolves.forEach((r) => (typeof r === 'function' ? r(result) : null));
      resolves = [];
    }, ms);

    return new Promise((r) => resolves.push(r));
  };
};

export const getDefaultTab = (defaultTab: string | number | null): string | number | null => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  return params.get('tab') || defaultTab;
};

export const isEmptyHTML = (html: string | undefined): boolean =>
  typeof html === 'undefined' ||
  !new DOMParser().parseFromString(`${html}`, 'text/html')?.body?.textContent?.trim();

export const getTagClassNameByResponseCode = ({ responseCode }: { responseCode: number }): string =>
  String(responseCode).replace(/\d$/, 's'); // replace last digit with *s 200 - 20s (group status code)

export const beautifyDate = (date: Date | string, format = 'MMM Do, HH:mm'): string =>
  moment.utc(date).local().format(format);

export const parseRefDomain = (url: string): string =>
  url?.replace(/^https:\/\/|www\.|\/$/gi, '')?.replace(/\/.*/g, '');
export const parseRefPage = (url: string): string =>
  url?.replace(/^https:\/\/|www\.|\/$/gi, '')?.replace(/\?.*/g, '');

export const getPercent = (completed: number, total: number): number =>
  +(total ? +((completed / total) * 100).toFixed() : 0);

export const isImage = (extension: string): boolean =>
  ['png', 'webp', 'svg', 'jpg', 'jpeg', 'gif'].includes(extension.toLowerCase());

export const arrayDuplicates = (arr: unknown[]): unknown[] => {
  const uniqueElements = new Set(arr);

  const duplicates = arr.filter((el) => {
    if (uniqueElements.has(el)) {
      uniqueElements.delete(el);
    } else {
      return el;
    }
  });

  return [...new Set(duplicates)];
};

export const prettifyNumber = (string: string | number): string | number => {
  const value = +string;
  if (value > 1000 && value < 1000000) {
    return Math.floor(value / 1000) + 'K';
  } else if (value >= 1000000) {
    return Math.floor(value / 1000000) + 'M';
  }
  return value;
};

const assumeObject = (obj: unknown): Record<string, unknown> | null =>
  typeof obj === 'object' ? (obj as Record<string, unknown>) : null;

export const getErrorData = (error: unknown): Record<string, unknown> | null => {
  return assumeObject(assumeObject(assumeObject(error)?.response)?.data);
};

export const getErrorStatus = (error: unknown): unknown => {
  return assumeObject(assumeObject(error)?.response)?.status;
};

export type ErrorReportType = Record<
  string,
  {
    attributeName: string;
    expectedValue: string;
  }[]
>;

type DateAddedRestored = {
  dateRestored?: string;
  dateAdded: string;
};

type DateAddedUpdated = {
  dateUpdated?: string;
  dateAdded: string;
};

export const sortAssociationsByDateAddedRestored = (a: DateAddedRestored, b: DateAddedRestored) =>
  moment(a.dateRestored || a.dateAdded).isBefore(b.dateRestored || b.dateAdded) ? 1 : -1;

export const sortAssociationsByDateAddedUpdated = (a: DateAddedUpdated, b: DateAddedUpdated) =>
  moment(a.dateUpdated || a.dateAdded).isBefore(b.dateUpdated || b.dateAdded) ? 1 : -1;

export const sortAssociationsByDateAdded = (a: DateAddedUpdated, b: DateAddedUpdated) =>
  moment(a.dateAdded).isBefore(b.dateAdded) ? 1 : -1;

export const getTaskmasterUsers = async (idTeams: number[], getTeam: LazyQueryTrigger<any>) => {
  let users: User[] = [];
  if (!idTeams) return [];
  for (const idTeam of idTeams) {
    try {
      const team = (await getTeam(idTeam).unwrap()) as TeamDetails;
      users = [...users, ...(team?.linkedUsers || [])];
    } catch (err) {
      //
    }
  }

  return uniqBy(users, 'id');
};

export const saveEntityPopoverScrollPosition = (event: MouseEvent<HTMLSpanElement>, visible: boolean) => {
  if (visible) return;
  const winHeight = window.innerHeight;
  const { top } = (event.target as HTMLSpanElement).getBoundingClientRect();
  const currentScroll = window.scrollY;

  if (winHeight - top < 170) {
    setTimeout(() => {
      window.scrollTo({ left: 0, top: currentScroll + 170 });
    }, 0);
  }
};

export const ignoreUrlProtocol = (value: string) => value.replace(/^https?:\/\//, '').replace(/www\./, '');
