import React, { Suspense, useEffect } from 'react';
import { SITE_NAME } from '@config/constants';
import { SpinFullscreen } from '@/misc/SpinFullscreen';
import { RoutePropsType } from '@config/routes/routes';
import { useLocation } from 'react-router-dom';

/*
 * Component which serves the purpose of a "root route component".
 */
export const LazyPageHOC = ({
  component: PageComponent,
  ...props
}: {
  component: React.ComponentType<RoutePropsType>;
} & RoutePropsType): React.ReactElement => {
  const location = useLocation();

  useEffect(() => {
    document.title = props.title + ' – ' + SITE_NAME;
  }, [location]);

  return (
    <Suspense fallback={<SpinFullscreen />}>
      {/* @ts-ignore */}
      <PageComponent {...props} />
    </Suspense>
  );
};
