import { GenericDynamicParams, GenericSearchPageReturnType } from '@config/types/shared';
import {
  TaskDateField,
  TaskSearchItem,
  TaskSearchParams,
  TaskSortFields,
  TaskStatuses,
} from '@config/types/task';

// Admin search view
export type TaskAdminViewParams = GenericSearchPageReturnType<TaskSearchParams, TaskSearchItem>;

export const taskAdminDefaultParams: TaskSearchParams = {
  idStatus: [
    TaskStatuses.Draft,
    TaskStatuses.ToDo,
    TaskStatuses.InProgress,
    TaskStatuses.Submitted,
    TaskStatuses.Paused,
  ],
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const taskAdminProtectedParams: TaskSearchParams = {};

export const taskAdminDateFields: TaskDateField[] = ['dateAdded', 'dateUpdated', 'dateDue', 'dateClosed'];

export const taskAdminSortFields: TaskSortFields[] = ['dateUpdated', 'dateDue'];

// Taskmaster search view
export type TaskTaskmasterViewParams = TaskAdminViewParams;

export const taskTaskmasterDefaultParams: TaskSearchParams = taskAdminDefaultParams;

export const taskTaskmasterProtectedParams: GenericDynamicParams<TaskSearchParams> = {};

export const taskTaskmasterDateFields: TaskDateField[] = taskAdminDateFields;

export const taskTaskmasterSortFields: TaskSortFields[] = taskAdminSortFields;

// Linkbuilder search view
export type TaskLinkbuilderViewParams = TaskAdminViewParams;

export const taskLinkbuilderDefaultParams: TaskSearchParams = {
  idStatus: [TaskStatuses.ToDo, TaskStatuses.InProgress, TaskStatuses.Submitted],
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const taskLinkbuilderProtectedParams: GenericDynamicParams<TaskSearchParams> = {};

export const taskLinkbuilderDateFields: TaskDateField[] = taskAdminDateFields;

export const taskLinkbuilderSortFields: TaskSortFields[] = taskAdminSortFields;

// Linkbuilder explore search view
export type TaskLinkbuilderExploreViewParams = TaskAdminViewParams;

export const taskLinkbuilderExploreDefaultParams: TaskSearchParams = {
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const taskLinkbuilderExploreProtectedParams: GenericDynamicParams<TaskSearchParams> = {};

export const taskLinkbuilderExploreDateFields: TaskDateField[] = ['dateUpdated', 'dateDue'];

export const taskLinkbuilderExploreSortFields: TaskSortFields[] = taskAdminSortFields;

// Linkbuilder profile search view
export type TaskLinkbuilderUserProfileViewParams = TaskAdminViewParams;

export const taskLinkbuilderUserProfileDefaultParams: TaskSearchParams = {
  size: 10,
};

export const taskLinkbuilderUserProfileProtectedParams: GenericDynamicParams<TaskSearchParams> = {
  idAssignee: 'dynamic',
};

export const taskLinkbuilderUserProfileDateFields: TaskDateField[] = ['dateUpdated', 'dateDue'];

export const taskLinkbuilderUserProfileSortFields: TaskSortFields[] = taskAdminSortFields;

// Taskmaster  profile search view
export type TaskTaskmasterUserProfileViewParams = TaskAdminViewParams;

export const taskTaskmasterUserProfileDefaultParams: TaskSearchParams = {
  size: 10,
};
export const taskTaskmasterUserProfileProtectedParams: GenericDynamicParams<TaskSearchParams> = {
  idAuthor: 'dynamic',
};
export const taskTaskmasterUserProfileDateFields: TaskDateField[] = ['dateUpdated', 'dateDue'];
export const taskTaskmasterUserProfileSortFields: TaskSortFields[] = taskAdminSortFields;

// Team  search view
export type TaskTeamViewParams = TaskAdminViewParams;
export const taskTeamDefaultParams: TaskSearchParams = {
  size: 10,
};
export const taskTeamProtectedParams: GenericDynamicParams<TaskSearchParams> = {
  idAssignee: 'dynamic',
};
export const taskTeamDateFields: TaskDateField[] = ['dateUpdated', 'dateDue'];
export const taskTeamSortFields: TaskSortFields[] = taskAdminSortFields;

// Pending admin
export type TaskPendingAdminViewParams = TaskAdminViewParams;
export const taskPendingAdminDefaultParams: TaskSearchParams = {};
export const taskPendingAdminProtectedParams: GenericDynamicParams<TaskSearchParams> = {
  size: 100,
};
export const taskPendingAdminDateFields: TaskDateField[] = [];
export const taskPendingAdminSortFields: TaskSortFields[] = [];

// Pending admin popover
export type TaskPendingAdminPopoverViewParams = TaskPendingAdminViewParams;
export const taskPendingAdminPopoverDefaultParams: TaskSearchParams = { size: 5 };
export const taskPendingAdminPopoverProtectedParams: GenericDynamicParams<TaskSearchParams> = {};
export const taskPendingAdminPopoverDateFields = taskPendingAdminDateFields;
export const taskPendingAdminPopoverSortFields = taskPendingAdminSortFields;

// Pending taskmaster
export type TaskPendingTaskmasterViewParams = TaskAdminViewParams;
export const taskPendingTaskmasterDefaultParams: TaskSearchParams = {};
export const taskPendingTaskmasterProtectedParams: GenericDynamicParams<TaskSearchParams> = {
  idAuthor: 'dynamic',
  size: 100,
};
export const taskPendingTaskmasterDateFields: TaskDateField[] = [];
export const taskPendingTaskmasterSortFields: TaskSortFields[] = [];

// Pending taskmaster popover
export type TaskPendingTaskmasterPopoverViewParams = TaskPendingTaskmasterViewParams;
export const taskPendingTaskmasterPopoverDefaultParams: TaskSearchParams = { size: 5 };
export const taskPendingTaskmasterPopoverProtectedParams: GenericDynamicParams<TaskSearchParams> = {
  idAuthor: 'dynamic',
};
export const taskPendingTaskmasterPopoverDateFields = taskPendingTaskmasterDateFields;
export const taskPendingTaskmasterPopoverSortFields = taskPendingTaskmasterSortFields;

// Pending linkbuilder
export type TaskPendingLinkbuilderViewParams = TaskAdminViewParams;
export const taskPendingLinkbuilderDefaultParams: TaskSearchParams = {};
export const taskPendingLinkbuilderProtectedParams: GenericDynamicParams<TaskSearchParams> = {
  idStatus: [TaskStatuses.ToDo],
  idAssignee: 'dynamic',
  size: 100,
};
export const taskPendingLinkbuilderDateFields: TaskDateField[] = [];
export const taskPendingLinkbuilderSortFields: TaskSortFields[] = [];

export type TaskPendingLinkbuilderPopoverViewParams = TaskAdminViewParams;
export const taskPendingLinkbuilderPopoverDefaultParams: TaskSearchParams = { size: 5 };
export const taskPendingLinkbuilderPopoverProtectedParams: GenericDynamicParams<TaskSearchParams> = {
  idStatus: [TaskStatuses.ToDo],
  idAssignee: 'dynamic',
};
export const taskPendingLinkbuilderPopoverDateFields = taskPendingLinkbuilderDateFields;
export const taskPendingLinkbuilderPopoverSortFields = taskPendingLinkbuilderSortFields;

// Overview admin
export type TaskAdminOverviewViewParams = GenericSearchPageReturnType<TaskSearchParams, TaskSearchItem>;

export const taskAdminOverviewDefaultParams: TaskSearchParams = { size: 10 };

export const taskAdminOverviewProtectedParams: TaskSearchParams = {
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const taskAdminOverviewDateFields: TaskDateField[] = [];

export const taskAdminOverviewSortFields: TaskSortFields[] = [];

// Overview linkbuilder
export type TaskLinkbuilderOverviewViewParams = GenericSearchPageReturnType<TaskSearchParams, TaskSearchItem>;

export const taskLinkbuilderOverviewDefaultParams: TaskSearchParams = {
  size: 10,
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const taskLinkbuilderOverviewProtectedParams: GenericDynamicParams<TaskSearchParams> = {};

export const taskLinkbuilderOverviewDateFields: TaskDateField[] = taskLinkbuilderDateFields;

export const taskLinkbuilderOverviewSortFields: TaskSortFields[] = taskLinkbuilderSortFields;

// Overview linkbuilder pending
export type TaskLinkbuilderOverviewPendingViewParams = GenericSearchPageReturnType<
  TaskSearchParams,
  TaskSearchItem
>;
export const taskLinkbuilderOverviewPendingDefaultParams: TaskSearchParams = {
  size: 10,
  sortBy: 'dateUpdated',
  sortType: 'desc',
};
export const taskLinkbuilderOverviewPendingProtectedParams: GenericDynamicParams<TaskSearchParams> = {};
export const taskLinkbuilderOverviewPendingDateFields: TaskDateField[] = taskLinkbuilderDateFields;
export const taskLinkbuilderOverviewPendingSortFields: TaskSortFields[] = taskLinkbuilderSortFields;

// sitetasks
export type TaskSiteViewParams = GenericSearchPageReturnType<TaskSearchParams, TaskSearchItem>;

export const taskSiteDefaultParams: TaskSearchParams = {
  size: 10,
};

export const taskSiteProtectedParams: GenericDynamicParams<TaskSearchParams> = {
  idLanding: 'dynamic',
};

export const taskSiteDateFields: TaskDateField[] = [];

export const taskSiteSortFields: TaskSortFields[] = [];
