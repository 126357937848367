import { GenericSearchPageReturnType, StandardStatuses } from '@config/types/shared';
import {
  DonatedSiteDateField,
  DonatedSiteSearchItem,
  DonatedSiteSearchParams,
  DonatedSiteSortFields,
} from '@config/types/donatedSite';

// Search view
export type DonatedSiteViewParams = GenericSearchPageReturnType<
  DonatedSiteSearchParams,
  DonatedSiteSearchItem
>;

export const donatedSiteDefaultParams: DonatedSiteSearchParams = {
  idStatus: [StandardStatuses.Draft, StandardStatuses.Active, StandardStatuses.Paused],
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const donatedSiteProtectedParams: DonatedSiteSearchParams = {};

export const donatedSiteDateFields: DonatedSiteDateField[] = ['dateAdded', 'dateUpdated'];

export const donatedSiteSortFields: DonatedSiteSortFields[] = ['dateAdded', 'dateUpdated'];

// Search view linkbuilder
export type DonatedSiteLinkbuilderViewParams = DonatedSiteViewParams;

export const donatedSiteLinkbuilderDefaultParams: DonatedSiteSearchParams = {
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const donatedSiteLinkbuilderProtectedParams: DonatedSiteSearchParams = {
  idStatus: [StandardStatuses.Active],
};

export const donatedSiteLinkbuilderDateFields = donatedSiteDateFields;
export const donatedSiteLinkbuilderSortFields = donatedSiteSortFields;
