import { PaymentDetail } from '@config/types/paymentDetail';
import { AscDesc, Patch } from '@config/types/shared';
export enum PaymentOptionStatuses {
  Inactive = 1,
  Active,
}

export enum PaymentOptionTypes {
  payoneer = 'Payoneer',
  iban = 'IBAN',
  payPal = 'PayPal',
}

export const PaymentOptionLogos = {
  [PaymentOptionTypes.payoneer]: '/assets/img/logo/payoneer.svg',
  [PaymentOptionTypes.iban]: '/assets/img/logo/iban.svg',
  [PaymentOptionTypes.payPal]: '/assets/img/logo/paypal.svg',
};

export type PaymentOption = {
  id: number;
  dateAdded: string;
  dateUpdated?: string;
  name: string;
  description?: string;
  logo?: string;
  idStatus: PaymentOptionStatuses;
};

export type PaymentOptionSearchItem = PaymentOption;

export type PaymentOptionDetails = PaymentOption & {
  paymentDetails?: PaymentDetail[];
};

export type PaymentOptionCreatePayload = {
  name: string;
  description?: string;
  logo?: string;
};

export type PaymentOptionCreateRequest = PaymentOptionCreatePayload;

export type PaymentOptionUpdatePayload = {
  name?: string;
  description?: string;
  logo?: string;
};

export type PaymentOptionUpdateRequest = Patch<PaymentOptionUpdatePayload>;

export type PaymentOptionSearchParams = {
  idFrom?: number;
  idTo?: number;
  dateAddedFrom?: string;
  dateAddedTo?: string;
  dateUpdatedFrom?: string;
  dateUpdatedTo?: string;
  name?: string;
  idStatus?: PaymentOptionStatuses[];
  sortType?: AscDesc;
  sortBy?: PaymentOptionSortFields;
  page?: number;
  size?: number;
};

export type PaymentOptionSortFields = 'id' | 'dateAdded' | 'dateUpdated' | 'name' | 'idStatus';

export type PaymentOptionDateField = 'dateAdded' | 'dateUpdated';
