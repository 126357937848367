import {
  LinkAttributeDateField,
  LinkAttributeSearchItem,
  LinkAttributeSearchParams,
  LinkAttributeSortFields,
} from '@config/types/linkAttribute';
import { GenericSearchPageReturnType, StandardStatuses } from '@config/types/shared';

export type linkAttributeViewParams = GenericSearchPageReturnType<
  LinkAttributeSearchParams,
  LinkAttributeSearchItem
>;

export const linkAttributeDefaultParams: LinkAttributeSearchParams = {
  idStatus: [StandardStatuses.Draft, StandardStatuses.Active, StandardStatuses.Paused],
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const linkAttributeProtectedParams: LinkAttributeSearchParams = {};

export const linkAttributeDateFields: LinkAttributeDateField[] = ['dateAdded', 'dateUpdated'];

export const linkAttributeSortFields: LinkAttributeSortFields[] = ['dateAdded', 'dateUpdated'];
