import { GenericSearchPageReturnType } from '@config/types/shared';
import {
  AhrefsDateField,
  AhrefsSearchItem,
  AhrefsSearchParams,
  AhrefsSortFields,
} from '@config/types/ahrefs';

export type AhrefsViewParams = GenericSearchPageReturnType<AhrefsSearchParams, AhrefsSearchItem>;

export const ahrefsDefaultParams: AhrefsSearchParams = {
  size: 20,
  sortBy: 'dateAdded',
  sortType: 'desc',
};

export const ahrefsProtectedParams: AhrefsSearchParams = {};

export const ahrefsDateFields: AhrefsDateField[] = ['dateAdded', 'dateUpdated'];

export const ahrefsSortFields: AhrefsSortFields[] = ['dateAdded', 'dateUpdated'];
