import { GenericSearchPageReturnType, StandardStatuses } from '@config/types/shared';
import { SiteDateField, SiteSearchItem, SiteSearchParams, SiteSortFields } from '@config/types/site';

export type SiteViewParams = GenericSearchPageReturnType<SiteSearchParams, SiteSearchItem>;

export const siteDefaultParams: SiteSearchParams = {
  idStatus: [StandardStatuses.Draft, StandardStatuses.Active, StandardStatuses.Paused],
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const siteProtectedParams: SiteSearchParams = {};

export const siteDateFields: SiteDateField[] = ['dateAdded', 'dateUpdated'];

export const siteSortFields: SiteSortFields[] = ['dateAdded', 'dateUpdated'];
