import { AscDesc, Patch, PatchBulk } from '@config/types/shared';
import { FinancialPenalty } from './financialPenalty';
import { User } from '@config/types/user';
import { Task } from '@config/types/task';
import { MessageSearchItem } from '@config/types/message';
import { File } from '@config/types/file';

export enum IssueStatuses {
  Unresolved = 1,
  Resolved,
}

export type Issue = {
  id: number;
  dateAdded: string;
  dateUpdated?: string;
  dateDue?: string;
  name: string;
  description?: string;
  idStatus: IssueStatuses;
};

export type IssueSearchItem = Issue & {
  author: User;
  assignee?: User;
  task: Task;
  parentIssue?: Issue;
};

export type IssueDetails = Issue & {
  author: User;
  assignee?: User;
  task: Task;
  parentIssue?: Issue;
  childrenIssues?: Issue[];
  messages?: MessageSearchItem[];
};

export type IssueTaskDetails = Issue & {
  author?: User;
  assignee?: User;
  financialPenalties: FinancialPenalty[];
  linkedFiles?: File[];
};

//TODO: refactor
export type IssueItem = Issue & {
  author?: User;
  assignee?: User;
};

export type IssueFinancialPenalty = Issue & {
  task: Task;
};

export type IssueCreatePayload = {
  dateDue?: string;
  name: string;
  description?: string;
  idTask: number;
  idSubmissionContent?: number;
  idSubmissionPayment?: number;
  idIssue?: number;
  idAssignee?: number;
};

export type IssueCreateRequest = IssueCreatePayload;

export type IssueUpdatePayload = {
  dateDue?: string;
  name: string;
  description?: string;
  idType?: 1 | 2 | 3;
};

export type IssueUpdateRequest = Patch<IssueUpdatePayload>;

export type IssueCountParams = {
  idFrom?: number;
  idTo?: number;
  dateAddedFrom?: string;
  dateAddedTo?: string;
  dateUpdatedFrom?: string;
  dateUpdatedTo?: string;
  name?: string;
  idStatus?: IssueStatuses[];
  idAuthor?: number[];
  idAssignee?: number[];
  idTask?: number[];
};

export type IssueSearchParams = IssueCountParams & {
  sortType?: AscDesc;
  sortBy?: IssueSortFields;
  page?: number;
  size?: number;
};

export type IssueUpdateFilesPayload = number[];

export type IssueUpdateFilesRequest = PatchBulk<IssueUpdateFilesPayload>;

export type IssueSortFields =
  | 'id'
  | 'dateAdded'
  | 'dateUpdated'
  | 'name'
  | 'idStatus'
  | 'idAuthor'
  | 'idAssignee'
  | 'idTask';

export type IssueDateField = 'dateAdded' | 'dateUpdated';
