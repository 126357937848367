import { Dispatch, SetStateAction } from 'react';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  MutationDefinition,
} from '@reduxjs/toolkit/dist/query/react';
import {
  MutationTrigger,
  UseLazyQuery,
  UseMutation,
  UseQuery,
} from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/query';
import { EntityType } from 'src/store/constants';
import { SerializedError } from '@reduxjs/toolkit';

export enum StandardStatuses {
  Draft = 1,
  Active,
  Paused,
  Suspended,
}

export type StandardStatusesActions = 'activate' | 'pause' | 'suspend';

export enum Languages {
  MultiLanguage = 1,
  English,
  German,
}

export type SearchViewReturnType<T> = {
  page: number;
  total: number;
  size: number;
  items: T[];
};

export type SearchParams = Record<string, any> & {
  size?: number;
  page?: number;
};

export type StatsResponse = {
  count: number;
  amount: number;
};

export type CountResponse = {
  amount: number;
};

export interface UseStateParamsHook {
  params: SearchParams; // params
  setParams: Dispatch<SetStateAction<SearchParams>>; // setParams
}

export type Patch<T> = { id: number; body: T };
export type PatchBulk<T> = { id: number; body: T; mode: SyncMerge };

export type AscDesc = 'asc' | 'desc';

export type SyncMerge = 'sync' | 'merge';

type BaseQuery = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;

export type RTKQueryHook<QueryArg, ResultType> = UseQuery<
  QueryDefinition<QueryArg, BaseQuery, EntityType, ResultType>
>;

export type RTKLazyQueryHook<QueryArg, ResultType> = UseLazyQuery<
  QueryDefinition<QueryArg, BaseQuery, EntityType, ResultType>
>;

export type RTKQMutationHook<QueryArg, ResultType> = UseMutation<
  MutationDefinition<QueryArg, BaseQuery, EntityType, ResultType>
>;

export type RTKQMutationTrigger<QueryArg, ResultType> = MutationTrigger<
  MutationDefinition<QueryArg, BaseQuery, EntityType, ResultType>
>;

export type GenericSearchPageReturnType<P, D> = {
  params: P;
  setParams: Dispatch<SetStateAction<P>>;
  data?: SearchViewReturnType<D>;
  error?: FetchBaseQueryError | SerializedError;
  isLoading: boolean;
  isFetching: boolean;
  refreshPage: (resetPagination?: boolean) => void;
};

export type GenericDynamicParams<T> = { [P in keyof T]: T[P] | 'dynamic' };

export type CallingCodesDict = Record<
  string,
  {
    id: number;
    id_Status: number;
    long: string;
    name: string;
    short: string;
  }[]
>;

export type LogMessage = {
  message?: string;
};

export type SnapshotPayload = {
  ahrefs?: boolean;
  scraper?: boolean;
};

export type SnapshotRequest = Patch<SnapshotPayload> & {
  invalidateTags?: boolean;
};

export type WithErrorArgs<T, E> = {
  args: T;
  errorArgs: E;
};

export type AhrefsChecksResponse = {
  checksLastDay?: number;
  checksLastWeek?: number;
  checksLastMonth?: number;
  checksLeft?: number;
};
