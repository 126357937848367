import {
  AnchorDateField,
  AnchorSearchItem,
  AnchorSearchParams,
  AnchorSortFields,
} from '@config/types/anchor';

import { GenericSearchPageReturnType } from '@config/types/shared';

export type AnchorViewParams = GenericSearchPageReturnType<AnchorSearchParams, AnchorSearchItem>;

export const anchorDefaultParams: AnchorSearchParams = {
  sortBy: 'dateAdded',
  sortType: 'desc',
};

export const anchorProtectedParams: AnchorSearchParams = {};

export const anchorDateFields: AnchorDateField[] = ['dateAdded', 'dateUpdated'];

export const anchorSortFields: AnchorSortFields[] = ['dateAdded', 'dateUpdated'];
