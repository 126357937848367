import { AscDesc } from '@config/types/shared';
import { User } from '@config/types/user';
import { ReferringDomainReferringDomainIssue } from '@config/types/referringDomain';

export enum ReferringDomainIssueStatuses {
  Active = 1,
  Inactive,
}

export type ReferringDomainIssue = {
  name: string;
  description?: string;
  id: number;
  dateAdded: string;
  dateUpdated?: string;
  idStatus: ReferringDomainIssueStatuses;
};

export type ReferringDomainIssueSearchItem = ReferringDomainIssue & {
  author: User;
};

export type ReferringDomainIssueDetails = ReferringDomainIssue & {
  author: User;
  linkedDomains?: ReferringDomainReferringDomainIssue[];
};

export type ReferringDomainIssueCreatePayload = {
  name: string;
  description?: string;
};

export type ReferringDomainIssueCreateRequest = ReferringDomainIssueCreatePayload;

export type ReferringDomainIssueSearchParams = {
  idFrom?: number;
  idTo?: number;
  dateAddedFrom?: string;
  dateAddedTo?: string;
  dateUpdatedFrom?: string;
  dateUpdatedTo?: string;
  name?: string;
  idStatus?: ReferringDomainIssueStatuses[];
  idAuthor?: number[];
  sortType?: AscDesc;
  sortBy?: ReferringDomainIssueSortFields;
  page?: number;
  size?: number;
};

export type ReferringDomainIssueSortFields =
  | 'id'
  | 'dateAdded'
  | 'dateUpdated'
  | 'name'
  | 'idStatus'
  | 'idAuthor';

export type ReferringDomainIssueDateField = 'dateAdded' | 'dateUpdated';
