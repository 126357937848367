import {
  BonusPaymentDateField,
  BonusPaymentSearchItem,
  BonusPaymentSearchParams,
  BonusPaymentSortFields,
  BonusPaymentStatuses,
} from '@config/types/bonusPayment';
import { GenericDynamicParams, GenericSearchPageReturnType } from '@config/types/shared';

export type BonusPaymentViewParams = GenericSearchPageReturnType<
  BonusPaymentSearchParams,
  BonusPaymentSearchItem
>;

export const bonusPaymentDefaultParams: BonusPaymentSearchParams = {
  idStatus: [BonusPaymentStatuses.Unverified, BonusPaymentStatuses.Pending],
  sortBy: 'dateDue',
  sortType: 'asc',
};

export const bonusPaymentProtectedParams: BonusPaymentSearchParams = {};

export const bonusPaymentDateFields: BonusPaymentDateField[] = ['dateAdded', 'dateUpdated', 'dateDue'];

export const bonusPaymentSortFields: BonusPaymentSortFields[] = ['dateAdded', 'dateUpdated', 'dateDue'];

// OverviewLinkbuilder
export type bonusPaymentLinkbuilderOverviewViewParams = BonusPaymentViewParams;
export const bonusPaymentLinkbuilderOverviewDefaultParams: BonusPaymentSearchParams = {
  size: 5,
};
export const bonusPaymentLinkbuilderOverviewProtectedParams: BonusPaymentSearchParams = {
  sortBy: 'dateUpdated',
  sortType: 'desc',
};
export const bonusPaymentLinkbuilderOverviewDateFields = [];
export const bonusPaymentLinkbuilderOverviewSortFields = [];

// Linkbuilder single
export type bonusPaymentLinkbuilderSingleViewParams = BonusPaymentViewParams;
export const bonusPaymentLinkbuilderSingleDefaultParams: BonusPaymentSearchParams = {
  size: 5,
};
export const bonusPaymentLinkbuilderSingleProtectedParams: GenericDynamicParams<BonusPaymentSearchParams> = {
  idRecipient: 'dynamic',
  sortBy: 'dateUpdated',
  sortType: 'desc',
};
export const bonusPaymentLinkbuilderSingleDateFields = [];
export const bonusPaymentLinkbuilderSingleSortFields = [];
