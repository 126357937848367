import { AscDesc } from '@config/types/shared';
import { User } from '@config/types/user';
import { ReferringPageItem } from '@config/types/referringPage';

export enum ReferringPageIssueStatuses {
  Active = 1,
  Inactive,
}

export type ReferringPageIssue = {
  name: string;
  description?: string;
  id: number;
  dateAdded: string;
  idStatus: ReferringPageIssueStatuses;
};

export type ReferringPageIssueSearchItem = ReferringPageIssue & {
  author: User;
};

export type ReferringPageIssueDetails = ReferringPageIssue & {
  author: User;
  linkedPages?: ReferringPageItem[];
};

export type ReferringPageIssueCreatePayload = {
  name: string;
  description?: string;
};

export type ReferringPageIssueCreateRequest = ReferringPageIssueCreatePayload;

export type ReferringPageIssueSearchParams = {
  idFrom?: number;
  idTo?: number;
  dateAddedFrom?: string;
  dateAddedTo?: string;
  name?: string;
  idStatus?: ReferringPageIssueStatuses[];
  idAuthor?: number[];
  sortType?: AscDesc;
  sortBy?: ReferringPageIssueSortFields;
  page?: number;
  size?: number;
};

export type ReferringPageIssueSortFields =
  | 'id'
  | 'dateAdded'
  | 'dateUpdated'
  | 'name'
  | 'idStatus'
  | 'idAuthor';

export type ReferringPageIssueDateField = 'dateAdded' | 'dateUpdated';
