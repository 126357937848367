import { TeamUser, TeamAssociationUser } from './user';
import { AscDesc, Patch } from '@config/types/shared';
import { User } from '@config/types/user';

export enum TeamStatuses {
  Draft = 1,
  Active,
  Paused,
  Removed,
}

export enum AssociationTeamUserStatuses {
  Linked = 1,
  Unlinked,
}

export type Team = {
  id: number;
  dateAdded: string;
  dateUpdated?: string;
  name: string;
  description?: string;
  idStatus: TeamStatuses;
};

export type TeamSearchItem = Team & {
  author: User;
  leader?: User;
};

export type TeamDetails = TeamSearchItem & {
  linkedUsers?: User[];
};

export type AssociationTeam = {
  dateAdded: string;
  dateRestored?: string;
  dateRemoved?: string;
  idStatus: AssociationTeamUserStatuses;
  team: Team;
};

export type TeamsCreatePayload = {
  name: string;
  description?: string;
  idLeader?: number;
};

export type TeamCreateRequest = TeamsCreatePayload;

export type TeamUpdatePayload = {
  name?: string;
  description?: string;
};

export type TeamUpdateRequest = Patch<TeamUpdatePayload>;

export type TeamUpdateStatusPayload = {
  message?: string;
};

export type TeamUpdateStatusRequest = Patch<TeamUpdateStatusPayload>;

export type TeamAssignLeaderPayload = {
  message?: string;
  idLeader: number;
};

export type TeamAssignLeaderRequest = Patch<TeamAssignLeaderPayload>;

export type TeamUnassignLeaderPayload = {
  message?: string;
};

export type TeamUnassignLeaderRequest = Patch<TeamUnassignLeaderPayload>;

export type TeamGetMembersResponse = TeamUser[];

export type TeamGetAssociationMembersResponse = TeamAssociationUser[];

export type TeamAddRemoveMemberPayload = {
  message?: string;
  idUser: number;
};

export type TeamAddRemoveMemberRequest = Patch<TeamAddRemoveMemberPayload>;

export type TeamSearchParams = {
  idFrom?: number;
  idTo?: number;
  dateAddedFrom?: string;
  dateAddedTo?: string;
  dateUpdatedFrom?: string;
  dateUpdatedTo?: string;
  name?: string;
  idStatus?: TeamStatuses[];
  idAuthor?: number[];
  idLeader?: number[];
  sortType?: AscDesc;
  sortBy?: TeamSortFields;
  page?: number;
  size?: number;
};

export type TeamSortFields =
  | 'id'
  | 'dateAdded'
  | 'dateUpdated'
  | 'name'
  | 'idStatus'
  | 'idAuthor'
  | 'idLeader';

export type TeamDateField = 'dateAdded' | 'dateUpdated';
