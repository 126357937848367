import { AscDesc } from '@config/types/shared';
import { User } from '@config/types/user';
import { Task } from '@config/types/task';
import { Issue } from '@config/types/issue';
import { Patch } from './shared';

export enum SubmissionContentStatuses {
  Requested = 1,
  InProgress,
  Received,
  ToAdjust,
  Canceled,
  Declined,
  Discarded,
  Futile,
}

export enum SubmissionContentTypes {
  External = 1,
  Internal,
}

export enum SubmissionContentOrigins {
  Generated = 1,
  Handwritten,
  SelfPrepared,
}

export type SubmissionContent = {
  name: string;
  description?: string;
  id: number;
  dateAdded: string;
  dateUpdated?: string;
  googleDocsLink?: string;
  jiraLink?: string;
  idStatus: SubmissionContentStatuses;
  idType?: SubmissionContentTypes;
  idOrigin?: SubmissionContentOrigins;
  isSeen?: boolean;
};

export type SubmissionContentSearchItem = SubmissionContent & {
  author: User;
  task: Task;
};

export type SubmissionContentDetails = SubmissionContent & {
  author: User;
  task: Task;
  issues?: Issue[];
};

export type SubmissionContentUpdatePayload = {
  name?: string;
  description?: string;
  googleDocsLink?: string;
};

export type SubmissionContentUpdateRequest = Patch<SubmissionContentUpdatePayload>;

export type SubmissionContentSearchParams = {
  idFrom?: number;
  idTo?: number;
  dateAddedFrom?: string;
  dateAddedTo?: string;
  dateUpdatedFrom?: string;
  dateUpdatedTo?: string;
  name?: string;
  idStatus?: SubmissionContentStatuses[];
  idType?: SubmissionContentTypes[];
  idAuthor?: number[];
  idTask?: number[];
  idTaskAuthor?: number[];
  idTaskAssignee?: number[];
  sortType?: AscDesc;
  sortBy?: SubmissionContentSortFields;
  page?: number;
  size?: number;
};

export type SubmissionContentSortFields =
  | 'id'
  | 'dateAdded'
  | 'dateUpdated'
  | 'name'
  | 'googleDocsLink'
  | 'idStatus'
  | 'idType'
  | 'idAuthor'
  | 'idTask'
  | 'idTaskAuthor'
  | 'idTaskAssignee';

export type SubmissionContentDateField = 'dateAdded' | 'dateUpdated';

export type SubmissionContentCreateRequest = {
  name: string;
  description?: string;
  jiraLink?: string;
  idOrigin?: SubmissionContentOrigins;
};

export type SubmissionContentRequestRequest = {
  name: string;
  description?: string;
  idOrigin?: SubmissionContentOrigins;
};

export type SubmissionContentReceiveRequest = {
  googleDocsLink: string;
};

export type SubmissionContentCommenceRequest = {
  jiraLink: string;
};
