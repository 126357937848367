import { AscDesc, Patch } from '@config/types/shared';
import { Task } from '@config/types/task';
import { User } from '@config/types/user';
import { Issue } from '@config/types/issue';

export enum SubmissionPaymentStatuses {
  Requested = 1,
  Pending,
  Paid,
  Refunded,
  Canceled,
  Declined,
  Discarded,
}

export enum Currencies {
  USD = 'USD',
  EUR = 'EUR',
  UAH = 'UAH',
}

export type SubmissionPayment = {
  dateDue?: string;
  name: string;
  description?: string;
  jiraLink?: string;
  id: number;
  dateAdded: string;
  dateUpdated?: string;
  datePaid?: string;
  dateCanceled?: string;
  amount: number;
  currency?: Currencies;
  idStatus: SubmissionPaymentStatuses;
};

export type SubmissionPaymentSearchItem = SubmissionPayment & {
  author: User;
  task: Task;
};

export type SubmissionPaymentDetails = SubmissionPayment & {
  author: User;
  task: Task;
  issues?: Issue[];
};

export type SubmissionPaymentUpdatePayload = {
  name?: string;
  description?: string;
  amount?: number;
  currency?: Currencies;
  jiraLink?: string;
};

export type SubmissionPaymentUpdateRequest = Patch<SubmissionPaymentUpdatePayload>;

export type SubmissionPaymentVerifyPayload = {
  jiraLink: string;
};

export type SubmissionPaymentVerifyRequest = Patch<SubmissionPaymentVerifyPayload>;

export type SubmissionPaymentSearchParams = {
  idFrom?: number;
  idTo?: number;
  dateAddedFrom?: string;
  dateAddedTo?: string;
  dateUpdatedFrom?: string;
  dateUpdatedTo?: string;
  dateDueFrom?: string;
  dateDueTo?: string;
  datePaidFrom?: string;
  datePaidTo?: string;
  dateCanceledFrom?: string;
  dateCanceledTo?: string;
  name?: string;
  currency?: Currencies[];
  idStatus?: SubmissionPaymentStatuses[];
  idAuthor?: number[];
  idTask?: number[];
  idTaskAuthor?: number[];
  idTaskAssignee?: number[];
  sortType?: AscDesc;
  sortBy?: SubmissionPaymentSortFields;
  page?: number;
  size?: number;
};

export type SubmissionPaymentSortFields =
  | 'id'
  | 'dateAdded'
  | 'dateUpdated'
  | 'dateDue'
  | 'datePaid'
  | 'dateCanceled'
  | 'name'
  | 'amount'
  | 'currency'
  | 'idStatus'
  | 'idAuthor'
  | 'idTask'
  | 'idTaskAuthor'
  | 'idTaskAssignee';

export type SubmissionPaymentDateField =
  | 'dateAdded'
  | 'dateUpdated'
  | 'dateDue'
  | 'datePaid'
  | 'dateCanceled';

export type SubmissionPaymentCreateRequest = {
  dateDue?: string;
  name: string;
  description?: string;
  jiraLink: string;
};

export type SubmissionPaymentProclaimRequest = {
  dateDue?: string;
  name: string;
  description?: string;
  jiraLink?: string;
};
