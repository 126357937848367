import { useAppSelector, RootState, useAppDispatch } from '@helpers/store';
import { useUpdateProfilePreferencesMutation } from 'src/api/profile';
import { useAuthLogoutMutation } from 'src/api/auth';
import { preferencesSelector } from 'src/store/preferences/preferencesSlice';
import { authLogout } from 'src/store/auth/authActions';
import { emptyApi } from 'src/store/emptyApi';
import { getPreferencesWithoutPages } from '@helpers/preferences';

export const useAuth = (): { isLoggedIn: boolean } => {
  const isLoggedIn: boolean = useAppSelector(({ auth }: RootState) => auth.isLoggedIn);
  return { isLoggedIn };
};

export const useGracefulLogout = () => {
  const [requestLogout] = useAuthLogoutMutation();
  const [updatePreferences] = useUpdateProfilePreferencesMutation();
  const currentPreferences = useAppSelector(preferencesSelector);

  const dispatch = useAppDispatch();

  const logoutFn = async () => {
    // force update search preferences
    await updatePreferences({
      preferences: getPreferencesWithoutPages(currentPreferences),
    });

    // logout with endpoint
    await requestLogout();
    // reset auth and search slices
    await dispatch(authLogout());
    // clear rtq cache
    await dispatch(emptyApi.util.resetApiState());
    location.reload();
  };

  return {
    logout: logoutFn,
  };
};
