import { GenericSearchPageReturnType, StandardStatuses } from '@config/types/shared';
import {
  DonatedLandingDateField,
  DonatedLandingSearchItem,
  DonatedLandingSearchParams,
  DonatedLandingSortFields,
} from '@config/types/donatedLanding';

// Search view
export type DonatedLandingViewParams = GenericSearchPageReturnType<
  DonatedLandingSearchParams,
  DonatedLandingSearchItem
>;

export const donatedLandingDefaultParams: DonatedLandingSearchParams = {
  size: 20,
  idStatus: [StandardStatuses.Draft, StandardStatuses.Active, StandardStatuses.Paused],
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const donatedLandingProtectedParams: DonatedLandingSearchParams = {};

export const donatedLandingDateFields: DonatedLandingDateField[] = ['dateAdded', 'dateUpdated'];

export const donatedLandingSortFields: DonatedLandingSortFields[] = ['dateAdded', 'dateUpdated'];

// Search view linkbuilder
export type DonatedLandingLinkbuilderViewParams = DonatedLandingViewParams;

export const donatedLandingLinkbuilderDefaultParams: DonatedLandingSearchParams = {
  size: 20,
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const donatedLandingLinkbuilderProtectedParams: DonatedLandingSearchParams = {
  idStatus: [StandardStatuses.Active],
};

export const donatedLandingLinkbuilderDateFields = donatedLandingDateFields;
export const donatedLandingLinkbuilderSortFields = donatedLandingSortFields;
