import { authLogout } from 'src/store/auth/authActions';
import { emptyApi as api } from 'src/store/emptyApi';
import { AuthAccessToken, AuthLoginCredentials, AuthTokenPair } from '@config/types/auth';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    authLogin: build.mutation<AuthTokenPair, AuthLoginCredentials>({
      query: (body) => ({
        url: `/auth/login`,
        method: 'POST',
        body,
      }),
    }),
    authRefresh: build.mutation<AuthAccessToken, void>({
      query: () => ({
        url: `/auth/refresh`,
        method: 'POST',
      }),
    }),
    authLogout: build.mutation<unknown, void>({
      query: () => ({
        url: `/auth/logout`,
        method: 'POST',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(authLogout());
        dispatch(api.util.resetApiState());
      },
    }),
    authGlobalLogout: build.mutation<unknown, void>({
      query: () => ({
        url: `/auth/global-logout`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});

export { injectedRtkApi as enhancedApi };

export const {
  useAuthLoginMutation,
  useAuthRefreshMutation,
  useAuthLogoutMutation,
  useAuthGlobalLogoutMutation,
} = injectedRtkApi;
