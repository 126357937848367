import { User } from '@config/types/user';
import { AttainmentPayment } from '@config/types/attainmentPayment';
import { PaymentDetailItem } from '@config/types/paymentDetail';
import { AscDesc, Patch } from './shared';

export enum BonusPaymentStatuses {
  Unverified = 1,
  Pending,
  Paid,
  Canceled,
  Reverted,
  Declined,
  Discarded,
}

export type BonusPaymentStatusActions = 'verify' | 'execute' | 'discard' | 'decline' | 'cancel' | 'revert';

export enum BonusPaymentTypes {
  ReferralAttainment = 1,
  Performance,
  Contribution,
}

export type BonusPayment = {
  dateDue?: string;
  name: string;
  description?: string;
  id: number;
  dateAdded: string;
  dateUpdated?: string;
  amount: number;
  jiraLink?: string;
  idStatus: BonusPaymentStatuses;
  idType: BonusPaymentTypes;
};

export type BonusPaymentSearchItem = BonusPayment & {
  author: User;
  recipient?: User;
  attainmentPayment?: AttainmentPayment;
  paymentDetail?: PaymentDetailItem;
};

export type BonusPaymentDetails = BonusPaymentSearchItem;

export type BonusPaymentSuggestPayload = {
  dateDue?: string;
  name: string;
  description?: string;
  amount: number;
  idType: BonusPaymentTypes;
  idRecipient: number;
};

export type BonusPaymentSuggestRequest = BonusPaymentSuggestPayload;

export type BonusPaymentCreatePayload = BonusPaymentSuggestPayload & {
  jiraLink: string;
};

export type BonusPaymentCreateRequest = BonusPaymentCreatePayload;

export type BonusPaymentUpdatePayload = {
  name?: string;
  description?: string;
  amount?: number;
  jiraLink?: string;
};

export type BonusPaymentUpdateRequest = Patch<BonusPaymentUpdatePayload>;

export type BonusPaymentVerifyPayload = {
  jiraLink: string;
};

export type BonusPaymentVerifyRequest = Patch<BonusPaymentVerifyPayload>;

export type BonusPaymentExecutePayload = {
  idPaymentDetail: number;
};

export type BonusPaymentExecuteRequest = Patch<BonusPaymentExecutePayload>;

export type BonusPaymentStatsParams = {
  idFrom?: number;
  idTo?: number;
  dateAddedFrom?: string;
  dateAddedTo?: string;
  dateUpdatedFrom?: string;
  dateUpdatedTo?: string;
  dateDueFrom?: string;
  dateDueTo?: string;
  amountFrom?: number;
  amountTo?: number;
  name?: string;
  idStatus?: BonusPaymentStatuses[];
  idType?: BonusPaymentTypes[];
  idAuthor?: number[];
  idRecipient?: number[];
  idAttainmentPayment?: number[];
  idPaymentDetail?: number[];
};

export type BonusPaymentSearchParams = BonusPaymentStatsParams & {
  sortType?: AscDesc;
  sortBy?: BonusPaymentSortFields;
  page?: number;
  size?: number;
};

export type BonusPaymentSortFields =
  | 'id'
  | 'dateAdded'
  | 'dateUpdated'
  | 'dateDue'
  | 'name'
  | 'amount'
  | 'idStatus'
  | 'idType'
  | 'idAuthor'
  | 'idRecipient'
  | 'idAttainmentPayment'
  | 'idPaymentDetail';

export type BonusPaymentDateField = 'dateAdded' | 'dateUpdated' | 'dateDue';
