import { GenericSearchPageReturnType } from '@config/types/shared';
import {
  ReferringLinkDateField,
  ReferringLinkSearchItem,
  ReferringLinkSearchParams,
  ReferringLinkSortFields,
} from '@config/types/referringLink';

export type ReferringLinkViewParams = GenericSearchPageReturnType<
  ReferringLinkSearchParams,
  ReferringLinkSearchItem
>;

export const referringLinkDefaultParams: ReferringLinkSearchParams = {
  sortBy: 'dateChecked',
  sortType: 'desc',
};

export const referringLinkProtectedParams: ReferringLinkSearchParams = {};

export const referringLinkDateFields: ReferringLinkDateField[] = ['dateAdded', 'dateChecked'];

export const referringLinkSortFields: ReferringLinkSortFields[] = ['dateAdded', 'dateChecked'];

// Temp view
export type ReferringLinkTempViewParams = ReferringLinkViewParams;

export const referringLinkTempDefaultParams = {};

export const referringLinkTempProtectedParams = {
  size: 100,
};

export const referringLinkTempDateFields = [];

export const referringLinkTempSortFields = [];

// Temp view popover
export type ReferringLinkTempPopoverViewParams = ReferringLinkTempViewParams;

export const referringLinkTempPopoverDefaultParams = {
  size: 5,
};
export const referringLinkTempPopoverProtectedParams = {};
export const referringLinkTempPopoverDateFields = referringLinkTempDateFields;
export const referringLinkTempPopoverSortFields = referringLinkTempSortFields;

// secondary view
export type ReferringLinkSecondaryViewParams = ReferringLinkTempViewParams;

export const referringLinkSecondaryDefaultParams = referringLinkTempDefaultParams;

export const referringLinkSecondaryProtectedParams = referringLinkTempProtectedParams;

export const referringLinkSecondaryDateFields = referringLinkTempDateFields;

export const referringLinkSecondarySortFields = referringLinkTempSortFields;

// secondary view popover
export type ReferringLinkSecondaryPopoverViewParams = ReferringLinkTempPopoverViewParams;

export const referringLinkSecondaryPopoverDefaultParams = referringLinkTempPopoverDefaultParams;
export const referringLinkSecondaryPopoverProtectedParams = referringLinkTempPopoverProtectedParams;
export const referringLinkSecondaryPopoverDateFields = referringLinkTempPopoverDateFields;
export const referringLinkSecondaryPopoverSortFields = referringLinkTempPopoverSortFields;

// Admin Overview

export type ReferringLinkAdminOverviewViewParams = ReferringLinkViewParams;
export const referringLinkAdminOverviewDefaultParams = {
  size: 10,
};
export const referringLinkAdminOverviewProtectedParams = {};

export const referringLinkAdminOverviewDateFields = [];

export const referringLinkAdminOverviewSortFields = [];
