import { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store, persistor } from '@helpers/store';
import { App } from '@/App';
import 'react-moment';
import { PersistGate } from 'redux-persist/integration/react';

import { APP_VERSION } from '@config/constants';

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
// import 'moment/locale/af';
// import 'moment/locale/de';
import 'src/translator';

// Antd styles -> https://ant.design/docs/react/customize-theme
import 'antd/lib/style/themes/default.less';
import 'antd/dist/antd.less'; // Import Ant Design styles by less entry
import 'react-phone-input-2/lib/style.css';
import '@styles/_index.less';
import { SpinFullscreen } from '@/misc/SpinFullscreen';
import { BrowserRouter } from 'react-router-dom';

Sentry.init({
  dsn: 'https://c8858366f922427b9c3acbaa884ac64c@o1028159.ingest.sentry.io/6007583',

  // if your build tool supports it.
  release: `outreach@${APP_VERSION}`,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

render(
  <Provider store={store}>
    {/* @ts-ignore */}
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Suspense fallback={<SpinFullscreen />}>
          {/* @ts-ignore */}
          <App />
        </Suspense>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
