import { GenericSearchPageReturnType } from '@config/types/shared';
import {
  ReferringPageIssueDateField,
  ReferringPageIssueSearchItem,
  ReferringPageIssueSearchParams,
  ReferringPageIssueSortFields,
  ReferringPageIssueStatuses,
} from '@config/types/referringPageIssue';

export type ReferringPageIssueViewParams = GenericSearchPageReturnType<
  ReferringPageIssueSearchParams,
  ReferringPageIssueSearchItem
>;

export const referringPageIssueDefaultParams: ReferringPageIssueSearchParams = {
  sortBy: 'dateUpdated',
  sortType: 'desc',
  idStatus: [ReferringPageIssueStatuses.Active],
};

export const referringPageIssueProtectedParams: ReferringPageIssueSearchParams = {};

export const referringPageIssueDateFields: ReferringPageIssueDateField[] = ['dateUpdated'];

export const referringPageIssueSortFields: ReferringPageIssueSortFields[] = ['dateUpdated'];
