import {
  ThematicConformityDateField,
  ThematicConformitySearchItem,
  ThematicConformitySearchParams,
  ThematicConformitySortFields,
} from '@config/types/thematicConformity';
import { GenericSearchPageReturnType, StandardStatuses } from '@config/types/shared';

export type ThematicConformityViewParams = GenericSearchPageReturnType<
  ThematicConformitySearchParams,
  ThematicConformitySearchItem
>;

export const thematicConformityDefaultParams: ThematicConformitySearchParams = {
  idStatus: [StandardStatuses.Draft, StandardStatuses.Active, StandardStatuses.Paused],
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const thematicConformityProtectedParams: ThematicConformitySearchParams = {};

export const thematicConformityDateFields: ThematicConformityDateField[] = ['dateAdded', 'dateUpdated'];

export const thematicConformitySortFields: ThematicConformitySortFields[] = ['dateAdded', 'dateUpdated'];
