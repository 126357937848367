import { EventDateField, EventSearchItem, EventSearchParams, EventSortFields } from '@config/types/event';
import { GenericDynamicParams, GenericSearchPageReturnType } from '@config/types/shared';

export type EventViewParams = GenericSearchPageReturnType<EventSearchParams, EventSearchItem>;

export const eventDefaultParams: EventSearchParams = {
  size: 40,
  sortBy: 'dateAdded',
  sortType: 'desc',
};

export const eventProtectedParams: EventSearchParams = {};

export const eventDateFields: EventDateField[] = ['dateAdded'];

export const eventSortFields: EventSortFields[] = ['dateAdded', 'responseCode', 'processTime', 'idUser'];

export type EventTaskViewParams = EventViewParams;

export const eventTaskDefaultParams: EventSearchParams = {
  size: 10,
  sortBy: 'dateAdded',
  sortType: 'desc',
};

export const eventTaskProtectedParams: GenericDynamicParams<EventSearchParams> = {
  url: 'dynamic',
};

export const eventTaskDateFields: EventDateField[] = ['dateAdded'];

export const eventTaskSortFields: EventSortFields[] = ['dateAdded', 'responseCode', 'processTime', 'idUser'];
