import { msg } from '@helpers/msg';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ReactNode } from 'react';
import ReactJson from 'react-json-view';
import { _ } from 'src/translator';
import { getCustomError } from './customErrors';

export class ErrorMessage {
  protected errorArgs: any;
  protected status: number;
  protected report: any;
  protected customError?: {
    title: string;
    description: string;
    suppress?: boolean;
  };
  protected title: string;
  protected description: ReactNode;

  constructor(error: FetchBaseQueryError | undefined, endpoint: string, errorArgs: any) {
    this.errorArgs = errorArgs;
    this.status = typeof error?.status === 'number' ? error?.status : 500;
    this.report = (error?.data as any)?.report;
    this.customError = getCustomError(
      endpoint,
      this.status,
      this.report,
      this.errorArgs,
      (error?.data as any)?.error
    );
    this.title = this.getErrorTitle();
    this.description = this.getErrorDescription(error?.data);
    this.showMessage();
  }

  protected getErrorTitle() {
    return this?.customError
      ? this.customError.title
      : _('misc.errorStatus', {
          number: this.status,
        });
  }

  protected getErrorDescription(data: any) {
    return this?.customError ? (
      this.customError.description
    ) : (
      <>
        <p>{data?.error || _('misc.unknownServerError')}</p>

        {this.report && (
          // @ts-ignore
          <ReactJson
            src={this.report}
            name={_('misc.details') + ':'}
            collapsed
            displayDataTypes={false}
            indentWidth={1}
            displayObjectSize={false}
            enableClipboard={false}
            // @ts-ignore
            displayArrayKey={false}
            style={{ fontSize: 12 }}
          />
        )}
      </>
    );
  }

  protected showMessage() {
    if (!this?.customError?.suppress) {
      msg.error({
        message: this.title,
        description: this.description,
      });
    }
  }
}
