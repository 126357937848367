import { GenericSearchPageReturnType } from '@config/types/shared';
import {
  ReferringPageDateField,
  ReferringPageSearchItem,
  ReferringPageSearchParams,
  ReferringPageSortFields,
} from '@config/types/referringPage';

export type ReferringPageViewParams = GenericSearchPageReturnType<
  ReferringPageSearchParams,
  ReferringPageSearchItem
>;

export const referringPageDefaultParams: ReferringPageSearchParams = {
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const referringPageProtectedParams: ReferringPageSearchParams = {};

export const referringPageDateFields: ReferringPageDateField[] = ['dateAdded', 'dateUpdated', 'dateIndexed'];

export const referringPageSortFields: ReferringPageSortFields[] = ['dateUpdated', 'dateIndexed'];
