import React, { Suspense, useEffect } from 'react';

import { withTranslation } from 'react-i18next';
// import de from 'antd/lib/locale/de_DE';
import en from 'antd/lib/locale/en_US';
// import af from 'antd/lib/locale/he_IL';
import { ConfigProvider } from 'antd';
import { SpinFullscreen } from '@/misc/SpinFullscreen';
import { LazyPageHOC } from '@/misc/LazyPageHOC';
import { Confetti } from '@/misc/Confetti';
import { _, translator } from 'src/translator';
import { Locale as AntLocale } from 'antd/lib/locale-provider';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { getUserRoles } from '@helpers/dicts';
import { useAuth } from 'src/store/auth/authHooks';
import { useLazyViewProfileQuery } from 'src/api/profile';

// Lazy modules
// Without export default:
// import().then((module) => ({ default: module.Dashboard }))
const Dashboard = React.lazy(() => import(/* webpackChunkName: "dashboard" */ '@/Dashboard'));
const UserLoginPage = React.lazy(
  () => import(/* webpackChunkName: "login" */ '@/entity/user/login/UserLoginPage')
);

const antLanguagesDict: Record<string, AntLocale> = {
  // af,
  en,
  //de,
};

export const App = withTranslation()(() => {
  // Redux
  const [loadProfile, { data: auth }] = useLazyViewProfileQuery();

  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  const watchSleepingTab = () => {
    const urlParams = new URL(location.href).searchParams;
    if (urlParams.get('sleep')) {
      navigate(location.pathname);
      loadProfile();
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      const urlParams = new URL(location.href).searchParams;

      if (urlParams.get('sleep')) {
        const sleep = Number(urlParams.get('sleep'));
        setTimeout(() => {
          navigate(location.pathname);
          loadProfile();
        }, sleep * 5000);
      } else {
        loadProfile();
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    window.addEventListener('focus', watchSleepingTab);

    return () => {
      window.removeEventListener('focus', watchSleepingTab);
    };
  }, []);

  const dashboardCondition = isLoggedIn && !/^\/reset-password\//.test(location.pathname);

  if (!auth && isLoggedIn) return <SpinFullscreen />;
  const roleName = auth?.idRole ? getUserRoles()[auth.idRole] : null;

  // Render Login page or Dashboard
  return (
    <ConfigProvider locale={antLanguagesDict[translator.language]}>
      <div className={`dashboard is${roleName || 'Guest'} ${roleName ? 'isLoggedIn' : ''}`}>
        <Suspense fallback={<SpinFullscreen />}>
          {dashboardCondition ? (
            <Dashboard />
          ) : (
            <Routes>
              <Route
                path="*"
                element={<LazyPageHOC component={UserLoginPage} title={_('users.loginNoun')} />}
              />
            </Routes>
          )}
        </Suspense>
      </div>
      <Confetti userRole={auth?.idRole} />
    </ConfigProvider>
  );
});
