import { SubmissionContentOrigins } from '@config/types/submissionContent';
import { AscDesc, Languages, Patch, PatchBulk } from '@config/types/shared';
import { User } from '@config/types/user';
import { SubmissionType } from '@config/types/submissionType';
import { ReferringDomain } from '@config/types/referringDomain';
import { LinkPlan } from '@config/types/linkPlan';
import { Team } from '@config/types/team';
import { Landing } from '@config/types/landing';
import { DonatedLanding } from '@config/types/donatedLanding';
import { PriceSetting } from '@config/types/priceSetting';
import { ThematicConformity } from '@config/types/thematicConformity';
import { TaskReferringDomain } from '@config/types/referringDomain';
import { ReferringPageTask } from '@config/types/referringPage';
import { TaskReferringLink } from '@config/types/referringLink';
import { Anchor } from '@config/types/anchor';
import { Site } from '@config/types/site';
import { Organicness } from '@config/types/organicness';
import { IssueTaskDetails } from '@config/types/issue';
import { SubmissionContent } from '@config/types/submissionContent';
import { SubmissionPayment } from '@config/types/submissionPayment';
import { AttainmentPayment } from '@config/types/attainmentPayment';
import { CustomEffort } from '@config/types/customEffort';
import { File } from '@config/types/file';

export enum TaskStatuses {
  Draft = 1,
  ToDo,
  InProgress,
  Submitted,
  Completed,
  Canceled,
  Paused,
  Removed,
  UpForGrabs,
}

export enum TaskTypes {
  Branded = 1,
  Generic,
  Anchorless,
  Selling,
  Image,
}

export enum TaskSubmissionPriceStatuses {
  Unverified = 1,
  Verified,
  Declined,
}

export enum TaskReferringDomainStatuses {
  Unverified = 1,
  Verified,
  Declined,
}

export enum TaskReferringPageStatuses {
  Unverified = 1,
  Verified,
  Declined,
}

export enum TaskReferringLinkStatuses {
  Unverified = 1,
  Verified,
  Declined,
}

export enum TaskSubmissionTypeStatuses {
  Unverified = 1,
  Verified,
  Declined,
}

export enum TaskDonatedLandingStatuses {
  Unverified = 1,
  Verified,
  Declined,
}

export type Task = {
  version?: number;
  id: number;
  dateAdded: string;
  dateUpdated?: string;
  dateClosed?: string;
  dateDue?: string;
  name: string;
  description?: string;
  submissionPrice?: number;
  idStatus: TaskStatuses;
  idType?: TaskTypes;
  idLanguage?: Languages;
  idSubmissionPriceStatus?: TaskSubmissionPriceStatuses;
  idReferringDomainStatus?: TaskReferringDomainStatuses;
  idReferringPageStatus?: TaskReferringPageStatuses;
  idReferringLinkStatus?: TaskReferringLinkStatuses;
  idSubmissionTypeStatus?: TaskSubmissionTypeStatuses;
  idDonatedLandingStatus?: TaskDonatedLandingStatuses;
};

export type TaskSearchItem = Task & {
  author: User;
  assignee?: User;
  team?: Team;
  donatedLanding: DonatedLanding;
  linkPlan?: LinkPlan;
  submissionType?: SubmissionType;
  thematicConformity?: ThematicConformity;
  submissionContent?: SubmissionContent;
  customEffort?: CustomEffort;
  donatedSubmissionContent?: SubmissionContent;
  priceSetting: PriceSetting;
  landing: Landing;
  referringDomain?: TaskReferringDomain;
  referringPage?: ReferringPageTask;
  referringLink?: TaskReferringLink;
  referenceScore?: number;
  drMultiplier?: number;
  spamMultiplier?: number;
  submissionTypeMultiplier?: number;
  organicnessMultiplier?: number;
  thematicConformityMultiplier?: number;
  linkAttributesMultiplier?: number;
  finalScore?: number;
  site?: Site;
  organicness?: Organicness;
  anchor?: string;
};

export type TaskDetails = TaskSearchItem & {
  issues?: IssueTaskDetails[];
  referringLinks?: TaskReferringLink[];
  submissionContents?: SubmissionContent[];
  customEfforts?: CustomEffort[];
  submissionPayments?: SubmissionPayment[];
  attainmentPayments?: AttainmentPayment[];
  linkedAnchors?: Anchor[];
  linkedFiles?: File[];
  linkedSubmissionTypes?: SubmissionType[];
  submissionAmountPaid?: number;
  submissionAmountRefunded?: number;
  submissionAmountDue?: number;
  attainmentCost?: number;
  attainmentAmountPending?: number;
  attainmentAmountPaid?: number;
  attainmentAmountReverted?: number;
  attainmentAmountDue?: number;
  basicPrice?: number;
  trafficSurcharge?: number;
  domainRatingSurcharge?: number;
  urlRatingSurcharge?: number;
  domainSpamSurcharge?: number;
  thematicConformitySurcharge?: number;
  subtotalPrice?: number;
  linkAttributePercentagePenalty?: number;
  bargainingSavings?: number;
  bargainingBonus?: number;
  totalPrice?: number;

  submissionBasicPrice?: number;
  submissionTrafficSurcharge?: number;
  submissionDomainRatingSurcharge?: number;
  submissionDomainSpamSurcharge?: number;
  submissionSubtotalRecommendedPrice?: number;
  submissionDomainPenaltyDeduction?: number;
  submissionTotalRecommendedPrice?: number;
  extraLinksPrice?: number;

  defaultAttainmentAmountUnpaid?: number;
  defaultAttainmentAmountPaid?: number;
  bonusAttainmentAmountUnpaid?: number;
  bonusAttainmentAmountPaid?: number;
  penaltyAttainmentAmountUnpaid?: number;
  penaltyAttainmentAmountPaid?: number;
};

export type TaskLinkPlanItem = Task & {
  author: User;
  assignee?: User;
  submissionType?: SubmissionType;
  referringDomain?: ReferringDomain;
};

export type TaskItem = Task & {
  author: User;
  assignee?: User;
  linkPlan?: LinkPlan;
  submissionType?: SubmissionType;
};

export type TaskLanding = Task & {
  author: User;
  assignee?: User;
  linkPlan?: LinkPlan;
  submissionType?: SubmissionType;
  referringDomain?: ReferringDomain;
};

export type TaskCreatePayload = {
  dateDue?: string;
  name: string;
  description?: string;
  idType?: TaskTypes;
  submissionPrice?: number;
  idSubmissionType?: number;
  idLanding: number;
  idReferringDomain?: number;
  idReferringPage?: number;
  idPriceSetting: number;
  idLinkPlan?: number;
};

export type TaskCreateRequest = TaskCreatePayload;

export type TaskUpdatePayload = {
  dateDue?: string;
  name?: string;
  description?: string;
  idType?: TaskTypes;
  idSubmissionType?: number;
  idPriceSetting?: number;
};

export type TaskUpdateRequest = Patch<TaskUpdatePayload>;

export type TaskMakeAvailablePayload = { idTeam: number };

export type TaskMakeAvailableRequest = Patch<TaskMakeAvailablePayload>;

export type TaskEnforceAssigneePayload = {
  idAssignee: number;
};

export type TaskEnforceAssigneeRequest = Patch<TaskEnforceAssigneePayload>;

export type TaskDeclareAssigneePayload = {
  idAssignee: number;
  idTeam: number;
};

export type TaskDeclareAssigneeRequest = Patch<TaskDeclareAssigneePayload>;

export type TaskUpdateDateDuePayload = {
  dateDue: string;
};

export type TaskUpdateDateDueRequest = Patch<TaskUpdateDateDuePayload>;

export type UpdateTaskFilesRequest = PatchBulk<number[]>;

export type UpdateTaskAnchorsRequest = PatchBulk<number[]>;

export type TaskCreateAttainmentPaymentPayload = {
  dateDue?: string;
  name: string;
  description?: string;
  jiraLink: string;
};

export type TaskCreateAttainmentPaymentRequest = Patch<TaskCreateAttainmentPaymentPayload>;

export type TaskProclaimAttainmentPaymentPayload = {
  dateDue?: string;
  name: string;
  description?: string;
};

export type TaskProclaimAttainmentPaymentRequest = Patch<TaskProclaimAttainmentPaymentPayload>;

export type TaskCreateCustomEffortPayload = {
  name: string;
  description?: string;
};

export type TaskCreateCustomEffortRequest = Patch<TaskCreateCustomEffortPayload>;

export type TaskReceiveCustomEffortPayload = {
  completionInfo?: string;
};

export type TaskReceiveCustomEffortRequest = Patch<TaskReceiveCustomEffortPayload>;

export type TaskSetDonatedLandingPayload = {
  idDonatedLanding: number;
};

export type TaskSetDonatedLandingRequest = Patch<TaskSetDonatedLandingPayload>;

export type TaskCreateSubmissionContentPayload = {
  name: string;
  description?: string;
  jiraLink: string;
  idOrigin?: SubmissionContentOrigins;
};

export type TaskCreateSubmissionContentRequest = Patch<TaskCreateSubmissionContentPayload>;

export type TaskRequestSubmissionContentPayload = {
  name: string;
  description?: string;
  idOrigin?: SubmissionContentOrigins;
};

export type TaskRequestSubmissionContentRequest = Patch<TaskRequestSubmissionContentPayload>;

export type TaskSubmissionContentCommencePayload = {
  jiraLink?: string;
};

export type TaskSubmissionContentCommenceRequest = Patch<TaskSubmissionContentCommencePayload>;

export type TaskReceiveSubmissionContentPayload = {
  jiraLink?: string;
};

export type TaskReceiveSubmissionContentRequest = Patch<TaskReceiveSubmissionContentPayload>;

export type TaskSetOrganicnessPayload = {
  idOrganicness: number;
};

export type TaskSetOrganicnessRequest = Patch<TaskSetOrganicnessPayload>;

export type TaskSetThematicConformityPayload = {
  idThematicConformity: number;
};

export type TaskSetThematicConformityRequest = Patch<TaskSetThematicConformityPayload>;

export type TaskSetReferringLinkPayload = {
  idReferringLink: number;
};

export type TaskSetReferringLinkRequest = Patch<TaskSetReferringLinkPayload>;

export type TaskSetReferringDomainPayload = {
  idReferringDomain: number;
};

export type TaskSetReferringDomainRequest = Patch<TaskSetReferringDomainPayload>;

export type TaskSetReferringPagePayload = {
  idReferringPage: number;
};

export type TaskSetReferringPageRequest = Patch<TaskSetReferringPagePayload>;

export type TaskCreateSubmissionPaymentPayload = {
  dateDue?: string;
  name: string;
  description?: string;
  jiraLink: string;
};

export type TaskUpdateLandingPayload = {
  idLanding: number;
};

export type TaskUpdateLandingRequest = Patch<TaskUpdateLandingPayload>;

export type TaskCreateSubmissionPaymentRequest = Patch<TaskCreateSubmissionPaymentPayload>;

export type TaskProclaimSubmissionPaymentPayload = {
  dateDue?: string;
  name: string;
  description?: string;
  jiraLink?: string;
};

export type TaskProclaimSubmissionPaymentRequest = Patch<TaskProclaimSubmissionPaymentPayload>;

export type TaskSetSubmissionPricePayload = {
  submissionPrice: number;
};

export type TaskSetSubmissionPriceRequest = Patch<TaskSetSubmissionPricePayload>;

export type TaskSetSubmissionTypePayload = {
  idSubmissionType: number;
};

export type TaskSetSubmissionTypeRequest = Patch<TaskSetSubmissionTypePayload>;

export type TaskSetReferringDomainPenaltyPayload = {
  idPenalty: number;
};

export type TaskSetReferringDomainPenaltyRequest = Patch<TaskSetReferringDomainPenaltyPayload>;

export type TaskCountParams = {
  idFrom?: number;
  idTo?: number;
  dateAddedFrom?: string;
  dateAddedTo?: string;
  dateUpdatedFrom?: string;
  dateUpdatedTo?: string;
  dateDueFrom?: string;
  dateDueTo?: string;
  dateClosedFrom?: string;
  dateClosedTo?: string;
  name?: string;
  idStatus?: TaskStatuses[];
  idType?: TaskTypes[];
  idTypeNone?: boolean;
  idAuthor?: number[];
  idAssignee?: number[];
  idLanding?: number[];
  idLinkPlan?: number[];
  idReferringDomain?: number[];
  idReferringPage?: number[];
  idReferringLink?: number[];
  idPriceSetting?: number[];
  idSubmissionType?: number[];
  idThematicConformity?: number[];
  idSubmissionContent?: number[];
  idReferringDomainStatus?: TaskReferringDomainStatuses[];
  idReferringPageStatus?: TaskReferringPageStatuses[];
  idReferringLinkStatus?: TaskReferringLinkStatuses[];
  idSubmissionTypeStatus?: TaskSubmissionTypeStatuses[];
  orphan?: boolean;
};

export type TaskSearchParams = TaskCountParams & {
  sortType?: AscDesc;
  sortBy?: TaskSortFields;
  page?: number;
  size?: number;
};

export type TaskExploreSearchParams = {
  idFrom?: number;
  idTo?: number;
  dateAddedFrom?: string;
  dateAddedTo?: string;
  dateUpdatedFrom?: string;
  dateUpdatedTo?: string;
  name?: string;
  sortType?: AscDesc;
  sortBy?: TaskSortFields;
  page?: number;
  size?: number;
};

export type TaskSortFields =
  | 'id'
  | 'dateAdded'
  | 'dateUpdated'
  | 'dateDue'
  | 'dateClosed'
  | 'name'
  | 'idStatus'
  | 'idReferringDomainStatus'
  | 'idReferringPageStatus'
  | 'idReferringLinkStatus'
  | 'idSubmissionTypeStatus'
  | 'idAuthor'
  | 'idAssignee'
  | 'idLanding'
  | 'idLinkPlan'
  | 'idReferringDomain'
  | 'idReferringPage'
  | 'idReferringLink'
  | 'idPriceSetting'
  | 'idSubmissionType'
  | 'idThematicConformity'
  | 'idSubmissionContent';

export type TaskDateField = 'dateAdded' | 'dateUpdated' | 'dateDue' | 'dateClosed';
