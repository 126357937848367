import { GenericDynamicParams, GenericSearchPageReturnType } from '@config/types/shared';
import {
  SubmissionContentDateField,
  SubmissionContentSearchItem,
  SubmissionContentSearchParams,
  SubmissionContentSortFields,
  SubmissionContentStatuses,
} from '@config/types/submissionContent';

export type SubmissionContentViewParams = GenericSearchPageReturnType<
  SubmissionContentSearchParams,
  SubmissionContentSearchItem
>;

export const submissionContentDefaultParams: SubmissionContentSearchParams = {
  idStatus: [
    SubmissionContentStatuses.Requested,
    SubmissionContentStatuses.InProgress,
    SubmissionContentStatuses.ToAdjust,
  ],
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const submissionContentProtectedParams: SubmissionContentSearchParams = {};
export const submissionContentDateFields: SubmissionContentDateField[] = ['dateUpdated', 'dateAdded'];
export const submissionContentSortFields: SubmissionContentSortFields[] = ['dateUpdated', 'dateAdded'];

export type SubmissionContentTaskmasterViewParams = SubmissionContentViewParams;
export const submissionContentTaskmasterDefaultParams: SubmissionContentSearchParams =
  submissionContentDefaultParams;

export const submissionContentTaskmasterProtectedParams: GenericDynamicParams<SubmissionContentSearchParams> =
  {
    idTaskAuthor: 'dynamic',
  };
export const submissionContentTaskmasterDateFields: SubmissionContentDateField[] =
  submissionContentDateFields;
export const submissionContentTaskmasterSortFields: SubmissionContentSortFields[] =
  submissionContentSortFields;

// pending view
export type SubmissionContentPendingViewParams = SubmissionContentViewParams;

export const submissionContentPendingDefaultParams = {};

export const submissionContentPendingProtectedParams = {
  size: 100,
};

export const submissionContentPendingDateFields = [];

export const submissionContentPendingSortFields = [];

// pending view popover
export type submissionContentPendingPopoverViewParams = SubmissionContentViewParams;

export const submissionContentPendingPopoverDefaultParams = {
  size: 5,
};
export const submissionContentPendingPopoverProtectedParams = {};
export const submissionContentPendingPopoverDateFields = submissionContentPendingDateFields;
export const submissionContentPendingPopoverSortFields = submissionContentPendingSortFields;
