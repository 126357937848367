import {
  TeamDateField,
  TeamSearchItem,
  TeamSearchParams,
  TeamSortFields,
  TeamStatuses,
} from '@config/types/team';
import { GenericSearchPageReturnType } from '@config/types/shared';

export type TeamViewParams = GenericSearchPageReturnType<TeamSearchParams, TeamSearchItem>;

export const teamDefaultParams: TeamSearchParams = {
  idStatus: [TeamStatuses.Active],
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const teamProtectedParams: TeamSearchParams = {};

export const teamDateFields: TeamDateField[] = ['dateAdded', 'dateUpdated'];

export const teamSortFields: TeamSortFields[] = ['dateAdded', 'dateUpdated'];
