import {
  LandingDateField,
  LandingSearchItem,
  LandingSearchParams,
  LandingSortFields,
} from '@config/types/landing';
import { GenericSearchPageReturnType, StandardStatuses } from '@config/types/shared';

export type LandingViewParams = GenericSearchPageReturnType<LandingSearchParams, LandingSearchItem>;

export const landingDefaultParams: LandingSearchParams = {
  idStatus: [StandardStatuses.Draft, StandardStatuses.Active, StandardStatuses.Paused],
  sortBy: 'dateUpdated',
  sortType: 'desc',
  size: 20,
};

export const landingProtectedParams: LandingSearchParams = {};

export const landingDateFields: LandingDateField[] = ['dateAdded', 'dateUpdated'];

export const landingSortFields: LandingSortFields[] = ['dateAdded', 'dateUpdated', 'idPriority', 'name'];

// Pending landings
export type LandingPendingViewParams = LandingViewParams;

export const landingPendingDefaultParams: LandingSearchParams = {};

export const landingPendingProtectedParams: LandingSearchParams = {
  idStatus: [StandardStatuses.Draft],
  size: 100,
};
export const landingPendingDateFields: LandingDateField[] = [];
export const landingPendingSortFields: LandingSortFields[] = [];

// Pending landings popover
export type LandingPendingPopoverViewParams = LandingPendingViewParams;

export const landingPendingPopoverDefaultParams: LandingSearchParams = { size: 5 };

export const landingPendingPopoverProtectedParams: LandingSearchParams = {
  idStatus: [StandardStatuses.Draft],
};
export const landingPendingPopoverDateFields = landingPendingDateFields;
export const landingPendingPopoverSortFields = landingPendingSortFields;
