export type EntityType = typeof ENTITY_LIST[number];

export const ENTITY_LIST = [
  'Ahrefs',
  'Anchor',
  'Profile',
  'AttainmentPayment',
  'BonusPayment',
  'Comment',
  'DonatedSite',
  'DonatedLanding',
  'Site',
  'Landing',
  'SubmissionType',
  'ThematicConformity',
  'Organicness',
  'LinkAttribute',
  'PriceSetting',
  'Task',
  'LinkPlan',
  'ReferringLink',
  'Issue',
  'Feedback',
  'Team',
  'User',
  'FinancialPenalty',
  'Event',
  'PaymentOption',
  'PaymentDetail',
  'ReferringDomain',
  'ReferringPage',
  'ReferringDomainIssue',
  'ReferringPageIssue',
  'ReferringLinkIssue',
  'ReferringDomainSnapshot',
  'ReferringPageSnapshot',
  'Message',
  'Comment',
  'SubmissionPayment',
  'SubmissionContent',
  'DonatedSubmissionContent',
  'GuideAnswer',
  'GuideQuestion',
  'File',
  'ReferringDomainPenalty',
  'AttainmentPaymentBatch',
] as const;
