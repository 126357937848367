import { GenericDynamicParams, GenericSearchPageReturnType } from '@config/types/shared';
import {
  FeedbackDateField,
  FeedbackSearchItem,
  FeedbackSearchParams,
  FeedbackSortFields,
  FeedbackStatuses,
} from '@config/types/feedback';

export type FeedbackViewParams = GenericSearchPageReturnType<FeedbackSearchParams, FeedbackSearchItem>;

export const feedbackDefaultParams: FeedbackSearchParams = {
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const feedbackProtectedParams: FeedbackSearchParams = {};

export const feedbackDateFields: FeedbackDateField[] = ['dateAdded', 'dateUpdated'];

export const feedbackSortFields: FeedbackSortFields[] = ['dateAdded', 'dateUpdated'];

//pending Admin
export type FeedbackPendingAdminViewParams = FeedbackViewParams;

export const feedbackPendingAdminDefaultParams = {};

export const feedbackPendingAdminProtectedParams: GenericDynamicParams<FeedbackSearchParams> = {
  idStatus: [FeedbackStatuses.Submitted],
};
export const feedbackPendingAdminDateFields: FeedbackDateField[] = [];

export const feedbackPendingAdminSortFields: FeedbackSortFields[] = [];

//pending AdminPopover
export type FeedbackPendingAdminPopoverViewParams = FeedbackViewParams;

export const feedbackPendingAdminPopoverDefaultParams = { size: 5 };

export const feedbackPendingAdminPopoverProtectedParams: GenericDynamicParams<FeedbackSearchParams> = {
  idStatus: [FeedbackStatuses.Submitted],
  size: 100,
};
export const feedbackPendingAdminPopoverDateFields: FeedbackDateField[] = [];

export const feedbackPendingAdminPopoverSortFields: FeedbackSortFields[] = [];

//pending Taskmaster
export type FeedbackPendingTaskmasterViewParams = FeedbackViewParams;

export const feedbackPendingTaskmasterDefaultParams = {};

export const feedbackPendingTaskmasterProtectedParams: GenericDynamicParams<FeedbackSearchParams> = {
  idStatus: [FeedbackStatuses.Draft],
  idAuthor: 'dynamic',
  size: 100,
};
export const feedbackPendingTaskmasterDateFields: FeedbackDateField[] = [];

export const feedbackPendingTaskmasterSortFields: FeedbackSortFields[] = [];

//pending Taskmaster popover
export type FeedbackPendingTaskmasterPopoverViewParams = FeedbackPendingTaskmasterViewParams;

export const feedbackPendingTaskmasterPopoverDefaultParams = { size: 5 };

export const feedbackPendingTaskmasterPopoverProtectedParams: GenericDynamicParams<FeedbackSearchParams> = {
  idStatus: [FeedbackStatuses.Draft],
  idAuthor: 'dynamic',
};
export const feedbackPendingTaskmasterPopoverDateFields = feedbackPendingTaskmasterDateFields;

export const feedbackPendingTaskmasterPopoverSortFields = feedbackPendingTaskmasterSortFields;

//pending Linkbuilder
export type FeedbackPendingLinkbuilderViewParams = FeedbackViewParams;

export const feedbackPendingLinkbuilderDefaultParams = {};

export const feedbackPendingLinkbuilderProtectedParams: GenericDynamicParams<FeedbackSearchParams> = {
  idStatus: [FeedbackStatuses.Draft],
  idAuthor: 'dynamic',
  size: 100,
};
export const feedbackPendingLinkbuilderDateFields: FeedbackDateField[] = [];

export const feedbackPendingLinkbuilderSortFields: FeedbackSortFields[] = [];

//pending LinkbuilderPopover
export type FeedbackPendingLinkbuilderPopoverViewParams = FeedbackViewParams;

export const feedbackPendingLinkbuilderPopoverDefaultParams = { size: 5 };

export const feedbackPendingLinkbuilderPopoverProtectedParams: GenericDynamicParams<FeedbackSearchParams> = {
  idStatus: [FeedbackStatuses.Draft],
  idAuthor: 'dynamic',
};
export const feedbackPendingLinkbuilderPopoverDateFields: FeedbackDateField[] = [];

export const feedbackPendingLinkbuilderPopoverSortFields: FeedbackSortFields[] = [];
