import { GenericSearchPageReturnType } from '@config/types/shared';
import { FileDateField, FileSearchItem, FileSearchParams, FileSortFields } from '@config/types/file';

export type FileViewParams = GenericSearchPageReturnType<FileSearchParams, FileSearchItem>;

export const fileDefaultParams: FileSearchParams = {
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const fileProtectedParams: FileSearchParams = {};

export const fileDateFields: FileDateField[] = ['dateAdded', 'dateUpdated'];

export const fileSortFields: FileSortFields[] = ['dateAdded', 'dateUpdated'];
