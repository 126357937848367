import { GenericSearchPageReturnType } from '@config/types/shared';
import {
  ReferringDomainSnapshotDateField,
  ReferringDomainSnapshotSearchItem,
  ReferringDomainSnapshotSearchParams,
  ReferringDomainSnapshotSortFields,
} from '@config/types/referringDomainSnapshot';

export type ReferringDomainSnapshotViewParams = GenericSearchPageReturnType<
  ReferringDomainSnapshotSearchParams,
  ReferringDomainSnapshotSearchItem
>;

export const referringDomainSnapshotDefaultParams: ReferringDomainSnapshotSearchParams = {
  sortBy: 'dateAdded',
  sortType: 'desc',
};

export const referringDomainSnapshotProtectedParams: ReferringDomainSnapshotSearchParams = {};

export const referringDomainSnapshotDateFields: ReferringDomainSnapshotDateField[] = ['dateAdded'];

export const referringDomainSnapshotSortFields: ReferringDomainSnapshotSortFields[] = [
  'dateAdded',
  'domainRank',
];
