import { _ } from 'src/translator';

export const getUserRoles = () => _('dicts.userRoles', { returnObjects: true });
export const getAnchorTypes = () => _('dicts.anchorTypes', { returnObjects: true });
export const getLanguages = () => _('dicts.languages', { returnObjects: true });
export const getRefDomainTypes = () => _('dicts.referringDomainTypes', { returnObjects: true });
export const getPriorities = () => _('dicts.priorities', { returnObjects: true });
export const getLandingTypes = () => _('dicts.landingTypes', { returnObjects: true });
export const getTaskTypes = () => _('dicts.taskTypes', { returnObjects: true });
export const getReferringDomainContactStages = () =>
  _('dicts.referringDomainContactStages', { returnObjects: true });
export const getAttainmentPaymentTypes = () => _('dicts.attainmentPaymentTypes', { returnObjects: true });
