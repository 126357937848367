import { AscDesc, Patch, PatchBulk } from '@config/types/shared';
import { User } from '@config/types/user';
import { File } from '@config/types/file';

export enum FeedbackStatuses {
  Draft = 1,
  Submitted,
  Noted,
  Discarded,
  Removed,
  Dismissed,
}

export type FeedbackStatusActions = 'submit' | 'note' | 'dismiss' | 'discard' | 'remove';

export type Feedback = {
  name: string;
  description?: string;
  id: number;
  dateAdded: string;
  dateUpdated?: string;
  idStatus: FeedbackStatuses;
};

export type FeedbackSearchItem = Feedback & {
  author: User;
};

export type FeedbackDetails = FeedbackSearchItem & {
  linkedFiles?: File[];
};

export type FeedbackCreatePayload = {
  name: string;
  description?: string;
};

export type FeedbackCreateRequest = FeedbackCreatePayload;

export type FeedbackUpdatePayload = {
  name?: string;
  description?: string;
};

export type FeedbackUpdateRequest = Patch<FeedbackUpdatePayload>;

export type FeedbackUpdateAssociationsRequest = PatchBulk<number[]>;

export type FeedbackCountParams = {
  idFrom?: number;
  idTo?: number;
  dateAddedFrom?: string;
  dateAddedTo?: string;
  dateUpdatedFrom?: string;
  dateUpdatedTo?: string;
  name?: string;
  idStatus?: FeedbackStatuses[];
  idAuthor?: number[];
  idSite?: number[];
};

export type FeedbackSearchParams = FeedbackCountParams & {
  sortType?: AscDesc;
  sortBy?: FeedbackSortFields;
  page?: number;
  size?: number;
};

export type FeedbackSortFields = 'id' | 'dateAdded' | 'dateUpdated' | 'name' | 'idStatus' | 'idAuthor';

export type FeedbackDateField = 'dateAdded' | 'dateUpdated';
