import {
  PaymentDetailDateField,
  PaymentDetailSearchItem,
  PaymentDetailSearchParams,
  PaymentDetailSortFields,
} from '@config/types/paymentDetail';
import { GenericSearchPageReturnType } from '@config/types/shared';

export type PaymentDetailViewParams = GenericSearchPageReturnType<
  PaymentDetailSearchParams,
  PaymentDetailSearchItem
>;

export const paymentDetailDefaultParams: PaymentDetailSearchParams = {
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const paymentDetailProtectedParams: PaymentDetailSearchParams = {};

export const paymentDetailDateFields: PaymentDetailDateField[] = ['dateAdded', 'dateUpdated'];

export const paymentDetailSortFields: PaymentDetailSortFields[] = ['dateAdded', 'dateUpdated'];
