import { GenericSearchPageReturnType } from '@config/types/shared';
import {
  ReferringLinkIssueDateField,
  ReferringLinkIssueSearchItem,
  ReferringLinkIssueSearchParams,
  ReferringLinkIssueSortFields,
  ReferringLinkIssueStatuses,
} from '@config/types/referringLinkIssue';

export type ReferringLinkIssueViewParams = GenericSearchPageReturnType<
  ReferringLinkIssueSearchParams,
  ReferringLinkIssueSearchItem
>;

export const referringLinkIssueDefaultParams: ReferringLinkIssueSearchParams = {
  sortBy: 'dateAdded',
  sortType: 'desc',
  idStatus: [ReferringLinkIssueStatuses.Active],
};

export const referringLinkIssueProtectedParams: ReferringLinkIssueSearchParams = {};

export const referringLinkIssueDateFields: ReferringLinkIssueDateField[] = ['dateAdded'];

export const referringLinkIssueSortFields: ReferringLinkIssueSortFields[] = ['dateAdded'];
