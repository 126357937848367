import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@helpers/store';
import { views } from '@/search/v2/searchEngine/config/views';
import {
  AppearancePreferences,
  ProfilePreferences,
  EmailSignaturePreferences,
  ViewDisplay,
} from '@config/types/profile';
import { getPreferencesWithoutPages } from '@helpers/preferences';

export interface SearchDictInterface {
  [key: string]: SearchDict | undefined;
}

export type SearchDict = Record<string, string | number | boolean | SearchDictInterface>;

const initialState: ProfilePreferences = {};

export const preferencesSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    setSearchPreferences: (state: ProfilePreferences, { payload }: PayloadAction<SearchDictInterface>) => {
      state.search = payload;
    },
    resetSearchPagination: (state: ProfilePreferences) => getPreferencesWithoutPages(state),
    setView: (state: ProfilePreferences, { payload }: PayloadAction<{ key: string; value: ViewDisplay }>) => {
      if (!state?.appearance) {
        state.appearance = {};
      }
      if (!state.appearance.views) {
        state.appearance.views = {};
      }
      state.appearance.views[payload.key] = payload.value;
    },
    setPreferences: (state: ProfilePreferences, { payload }: PayloadAction<ProfilePreferences>) => payload,
    setEmailSignaturePreferences: (
      state: ProfilePreferences,
      { payload }: PayloadAction<EmailSignaturePreferences>
    ) => {
      state.emailSignature = payload;
    },
    resetPreferencesState: () => initialState,
  },
});

export const {
  setSearchPreferences,
  setPreferences,
  resetPreferencesState,
  setView,
  setEmailSignaturePreferences,
  resetSearchPagination,
} = preferencesSlice.actions;

export default preferencesSlice.reducer;

export const searchSelectorByView =
  (view: string | views) =>
  (state: RootState): SearchDict | undefined =>
    state.preferences?.search?.[view];

export const preferencesSelector = ({ preferences }: RootState): ProfilePreferences => preferences;

export const collapsedMenuBarSelector = ({ preferences }: RootState): boolean =>
  !!preferences?.appearance?.collapsedMenuBar;

export const debugModeSelector = ({ preferences }: RootState): boolean => !!preferences?.debugMode;

export const appearanceSelector = ({ preferences }: RootState): AppearancePreferences | undefined =>
  preferences?.appearance;

export const viewSelector =
  (pathname: string) =>
  ({ preferences }: RootState): string =>
    preferences?.appearance?.views?.[pathname] || 'grid';

export const emailSignatureSelector = ({ preferences }: RootState): EmailSignaturePreferences | undefined =>
  preferences?.emailSignature;
