import { GenericSearchPageReturnType } from '@config/types/shared';
import {
  ReferringPageSnapshotDateField,
  ReferringPageSnapshotSearchItem,
  ReferringPageSnapshotSearchParams,
  ReferringPageSnapshotSortFields,
} from '@config/types/referringPageSnapshot';

export type ReferringPageSnapshotViewParams = GenericSearchPageReturnType<
  ReferringPageSnapshotSearchParams,
  ReferringPageSnapshotSearchItem
>;

export const referringPageSnapshotDefaultParams: ReferringPageSnapshotSearchParams = {
  sortBy: 'dateAdded',
  sortType: 'desc',
};

export const referringPageSnapshotProtectedParams: ReferringPageSnapshotSearchParams = {};

export const referringPageSnapshotDateFields: ReferringPageSnapshotDateField[] = ['dateAdded'];

export const referringPageSnapshotSortFields: ReferringPageSnapshotSortFields[] = ['dateAdded'];
