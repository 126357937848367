import {
  PriceSettingDateField,
  PriceSettingSearchItem,
  PriceSettingSearchParams,
  PriceSettingSortFields,
} from '@config/types/priceSetting';
import { GenericSearchPageReturnType, StandardStatuses } from '@config/types/shared';

export type PriceSettingViewParams = GenericSearchPageReturnType<
  PriceSettingSearchParams,
  PriceSettingSearchItem
>;

export const priceSettingDefaultParams: PriceSettingSearchParams = {
  idStatus: [StandardStatuses.Draft, StandardStatuses.Active, StandardStatuses.Paused],
  sortBy: 'dateUpdated',
  sortType: 'desc',
};

export const priceSettingProtectedParams: PriceSettingSearchParams = {};

export const priceSettingDateFields: PriceSettingDateField[] = ['dateAdded', 'dateUpdated'];

export const priceSettingSortFields: PriceSettingSortFields[] = ['dateAdded', 'dateUpdated'];
