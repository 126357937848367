import { AttainmentPaymentBatch } from './attainmentPaymentBatch';
import { User } from '@config/types/user';
import { Task } from '@config/types/task';
import { LinkPlan } from '@config/types/linkPlan';
import { BonusPayment } from '@config/types/bonusPayment';
import { PaymentDetailItem } from '@config/types/paymentDetail';
import { AscDesc, Patch } from '@config/types/shared';

export enum AttainmentPaymentStatuses {
  Unverified = 1,
  Pending,
  Paid,
  Canceled,
  Reverted,
  Declined,
  Discarded,
}

export enum AttainmentPaymentTypes {
  Default = 1,
  Bonus,
  Penalty,
}

export enum AttainmentPaymentBonusTypes {
  ReferralAttainment = 1,
  Performance,
  Contribution,
}

export type AttainmentPayment = {
  version?: number;
  id: number;
  dateAdded: string;
  dateUpdated?: string;
  dateDue?: string;
  name: string;
  description?: string;
  amount: number;
  jiraLink?: string;
  idStatus: AttainmentPaymentStatuses;
  idType?: AttainmentPaymentTypes;
  idBonus?: AttainmentPaymentBonusTypes;
};

export type AttainmentPaymentSearchItem = AttainmentPayment & {
  author: User;
  recipient: User;
  task: Task;
  paymentDetail?: PaymentDetailItem;
  linkPlan?: LinkPlan;
  attainmentPaymentBatch?: AttainmentPaymentBatch;
  attainmentPayment?: AttainmentPayment;
};

export type AttainmentPaymentDetails = AttainmentPayment & {
  author: User;
  recipient?: User;
  task: Task;
  paymentDetail?: PaymentDetailItem;
  linkPlan?: LinkPlan;
  bonusPayments?: BonusPayment[];
};

export type AttainmentPaymentBatchAttainmentPayment = AttainmentPayment & {
  author: User;
  task: Task;
};

export type AttainmentPaymentPenaltyCreatePayload = {
  name: string;
  description?: string;
  amount: number;
  idIssue?: number;
  idRecipient: number;
};

export type AttainmentPaymentPenaltyCreateRequest = AttainmentPaymentPenaltyCreatePayload;

export type AttainmentPaymentBonusCreatePayload = {
  name: string;
  dateDue: string;
  description?: string;
  amount: number;
  jiraLink?: string;
  idBonus: AttainmentPaymentBonusTypes;
  idRecipient: number;
  idTask?: number;
};

export type AttainmentPaymentBonusCreateRequest = AttainmentPaymentBonusCreatePayload;

export type AttainmentPaymentUpdatePayload = {
  name?: string;
  description?: string;
  amount?: number;
  jiraLink?: string;
};

export type AttainmentPaymentUpdateRequest = Patch<AttainmentPaymentUpdatePayload>;

export type AttainmentPaymentVerifyPayload = {
  jiraLink: string;
};

export type AttainmentPaymentVerifyRequest = Patch<AttainmentPaymentVerifyPayload>;

export type AttainmentPaymentExecutePayload = {
  idPaymentDetail: string;
};

export type AttainmentPaymentExecuteRequest = Patch<AttainmentPaymentExecutePayload>;

export type AttainmentPaymentProclaimRequest = {
  dateDue?: string;
  name: string;
  description?: string;
};

export type AttainmentPaymentStatsParams = {
  idFrom?: number;
  idTo?: number;
  dateAddedFrom?: string;
  dateAddedTo?: string;
  dateUpdatedFrom?: string;
  dateUpdatedTo?: string;
  dateDueFrom?: string;
  dateDueTo?: string;
  amountFrom?: number;
  amountTo?: number;
  name?: string;
  idStatus?: AttainmentPaymentStatuses[];
  idType?: AttainmentPaymentTypes[];
  idAuthor?: number[];
  idPaymentDetail?: number[];
  idRecipient?: number[];
  idTask?: number[];
  idIssue?: number[];
};

export type AttainmentPaymentSearchParams = AttainmentPaymentStatsParams & {
  sortType?: AscDesc;
  sortBy?: AttainmentPaymentSortFields;
  page?: number;
  size?: number;
};

export type AttainmentPaymentSortFields =
  | 'id'
  | 'dateAdded'
  | 'dateUpdated'
  | 'dateDue'
  | 'name'
  | 'amount'
  | 'idStatus'
  | 'idAuthor'
  | 'idRecipient'
  | 'idTask'
  | 'idPaymentDetail';

export type AttainmentPaymentDateField = 'dateAdded' | 'dateUpdated' | 'dateDue';
