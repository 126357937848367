import { AscDesc } from '@config/types/shared';
import { ReferringLinkItem } from '@config/types/referringLink';
import { User } from '@config/types/user';

export enum ReferringLinkIssueStatuses {
  Active = 1,
  Inactive,
}

export type ReferringLinkIssue = {
  name: string;
  description?: string;
  id: number;
  dateAdded: string;
  idStatus: ReferringLinkIssueStatuses;
};

export type ReferringLinkIssueSearchItem = ReferringLinkIssue & {
  author?: User;
};

export type ReferringLinkIssueDetails = ReferringLinkIssue & {
  linkedLinks?: ReferringLinkItem[];
};

export type ReferringLinkIssueCreatePayload = {
  name: string;
  description?: string;
};

export type ReferringLinkIssueCreateRequest = ReferringLinkIssueCreatePayload;

export type ReferringLinkIssueSearchParams = {
  idFrom?: number;
  idTo?: number;
  dateAddedFrom?: string;
  dateAddedTo?: string;
  name?: string;
  idStatus?: ReferringLinkIssueStatuses[];
  idAuthor?: number[];
  sortType?: AscDesc;
  sortBy?: ReferringLinkIssueSortFields;
  page?: number;
  size?: number;
};

export type ReferringLinkIssueSortFields = 'id' | 'dateAdded' | 'name' | 'idStatus' | 'idAuthor';

export type ReferringLinkIssueDateField = 'dateAdded';
