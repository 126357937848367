import {
  AttainmentPaymentBatchDateField,
  AttainmentPaymentBatchSearchItem,
  AttainmentPaymentBatchSearchParams,
  AttainmentPaymentBatchSortFields,
} from '@config/types/attainmentPaymentBatch';
import { GenericDynamicParams, GenericSearchPageReturnType } from '@config/types/shared';

export type AttainmentPaymentPaymentBatchViewParams = GenericSearchPageReturnType<
  AttainmentPaymentBatchSearchParams,
  AttainmentPaymentBatchSearchItem
>;

export const attainmentPaymentBatchDefaultParams: AttainmentPaymentBatchSearchParams = {
  sortBy: 'dateAdded',
  sortType: 'desc',
};

export const attainmentPaymentBatchProtectedParams: AttainmentPaymentBatchSearchParams = {};

export const attainmentPaymentBatchDateFields: AttainmentPaymentBatchDateField[] = [
  'dateAdded',
  'dateExecuted',
];

export const attainmentPaymentBatchSortFields: AttainmentPaymentBatchSortFields[] = [
  'dateAdded',
  'dateExecuted',
];

export type AttainmentPaymentBatchLinkbuilderSingleViewParams = AttainmentPaymentPaymentBatchViewParams;

export const attainmentPaymentBatchLinkbuilderSingleDefaultParams: AttainmentPaymentBatchSearchParams =
  attainmentPaymentBatchDefaultParams;

export const attainmentPaymentBatchLinkbuilderSingleProtectedParams: GenericDynamicParams<AttainmentPaymentBatchSearchParams> =
  {
    idRecipient: 'dynamic',
  };

export const attainmentPaymentBatchLinkbuilderSingleDateFields: AttainmentPaymentBatchDateField[] =
  attainmentPaymentBatchDateFields;
export const attainmentPaymentBatchLinkbuilderSingleSortFields: AttainmentPaymentBatchSortFields[] =
  attainmentPaymentBatchSortFields;
