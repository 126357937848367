import { ReferringDomainPenalty } from './referringDomainPenalty';
import { LogMessage, Patch, PatchBulk, SnapshotPayload, SnapshotRequest, WithErrorArgs } from './shared';
import { Languages, AscDesc } from '@config/types/shared';
import { User } from '@config/types/user';
import { Webmaster } from '@config/types/webmaster';
import { ReferringDomainIssue } from '@config/types/referringDomainIssue';
import { ReferringDomainSnapshot } from '@config/types/referringDomainSnapshot';
import { ReferringPage } from '@config/types/referringPage';
import { Comment } from '@config/types/comment';
import { TaskItem } from '@config/types/task';
import { ReferringDomainReferringLink } from '@config/types/referringLink';

export enum ReferringDomainTypes {
  ManuallyAdded = 1,
  Parsed,
  CreatedWithPage,
}

export enum ReferringDomainStatuses {
  New = 1,
  InWork,
  Relinquished,
  Processed,
  Dismissed,
}

export enum ReferringDomainContactStages {
  NotImposed = 1,
  FirstContact,
  FollowUp1,
  FollowUp2,
  OpenDeal,
  Rejected,
  OnHold,
  NoResponse,
  MissionAccomplished,
}

export type ReferringDomain = {
  url: string;
  idLanguage: Languages;
  idType: ReferringDomainTypes;
  id: number;
  dateAdded: string;
  dateUpdated?: string;
  dateCheckedAhrefs?: string;
  name: string;
  isLegacy?: boolean;
  idStatus: ReferringDomainStatuses;
  idContactStage?: ReferringDomainContactStages;
};

export type ReferringDomainSearchItem = ReferringDomain & {
  author?: User;
  assignee?: User;
  webmaster?: Webmaster;
  snapshot?: ReferringDomainSnapshot;
  penalty?: ReferringDomainPenalty;
};

export type ReferringDomainDetails = ReferringDomainSearchItem & {
  referringPages?: ReferringPage[];
  comments?: Comment[];
  snapshots?: ReferringDomainSnapshot[];
  linkedIssues?: ReferringDomainIssue[];
  tasks?: TaskItem[];
  referringLinks?: ReferringDomainReferringLink[];
};

export type ReferringLinkReferringDomain = ReferringDomain & {
  traffic?: number;
  domainRank?: number;
  inboundLinks?: number;
  outboundLinks?: number;
  inboundDomains?: number;
  outboundDomains?: number;
  dynamicDomainSpam?: number;
};

export type TaskReferringDomain = ReferringDomain & {
  linkedIssues?: ReferringDomainIssue[];
  penalty?: ReferringDomainPenalty;
  snapshot?: ReferringDomainSnapshot;
};

export type ReferringDomainReferringDomainIssue = ReferringDomain & {
  snapshot?: ReferringDomainSnapshot;
};

export type ReferringDomainCreatePayload = {
  url: string;
  idLanguage: Languages;
  idType: ReferringDomainTypes;
  isLegacy?: boolean;
};

export type ReferringDomainCreateRequest = {
  body: ReferringDomainCreatePayload;
  invalidateTags?: boolean;
};

export type ReferringDomainUpdatePayload = {
  idLanguage?: Languages;
  idType?: ReferringDomainTypes;
  idThematicConformity?: number;
  idWebmaster?: number;
  isLegacy?: boolean;
};

export type ReferringDomainUpdateRequest = Patch<ReferringDomainUpdatePayload>;

export type ReferringDomainLegacyPayload = number[];

export type ReferringDomainLegacyRequest = ReferringDomainLegacyPayload;

export type ReferringDomainMakeSnapshotRequest = WithErrorArgs<SnapshotRequest, { suppress?: boolean }>;

export type ReferringDomainBulkSnapshotsPayload = SnapshotPayload & {
  referringDomainIds: number[];
};

export type ReferringDomainBulkSnapshotsRequest = ReferringDomainBulkSnapshotsPayload;

export type ReferringDomainUpdateIssuesPayload = number[];

export type ReferringDomainUpdateIssuesRequest = PatchBulk<ReferringDomainUpdateIssuesPayload>;

export type ReferringDomainAssignPayload = LogMessage & {
  idAssignee: number;
};

export type ReferringDomainAssignRequest = WithErrorArgs<
  Patch<ReferringDomainAssignPayload>,
  { name: string }
>;

export type ReferringDomainClaimRequest = WithErrorArgs<
  { id: number },
  { name: string; domainLimit?: number }
>;

export type ReferringDomainUnassignPayload = LogMessage;

export type ReferringDomainUnassignRequest = Patch<LogMessage>;

export type ReferringDomainSetContactStagePayload = LogMessage & {
  idContactStage: ReferringDomainContactStages;
};

export type ReferringDomainSetContactStageRequest = Patch<ReferringDomainSetContactStagePayload>;

export type ReferringDomainCountParams = {
  idFrom?: number;
  idTo?: number;
  dateAddedFrom?: string;
  dateAddedTo?: string;
  dateUpdatedFrom?: string;
  dateUpdatedTo?: string;
  name?: string;
  url?: string;
  isLegacy?: boolean;
  idLanguage?: Languages;
  idStatus?: ReferringDomainStatuses[];
  idType?: ReferringDomainTypes[];
  idContactStage?: ReferringDomainContactStages[];
  idAuthor?: number[];
  idAssignee?: number[];
  idWebmaster?: number[];
  idThematicConformity?: number[];
  unassigned?: boolean;
};

export type ReferringDomainSearchParams = ReferringDomainCountParams & {
  sortType?: AscDesc;
  sortBy?: ReferringDomainSortFields;
  page?: number;
  size?: number;
};

export type ReferringDomainSortFields =
  | 'id'
  | 'dateAdded'
  | 'dateUpdated'
  | 'name'
  | 'url'
  | 'isLegacy'
  | 'idLanguage'
  | 'idStatus'
  | 'idType'
  | 'idContactStage'
  | 'idAuthor'
  | 'idAssignee'
  | 'idWebmaster'
  | 'idThematicConformity'
  | 'traffic'
  | 'domainRating'
  | 'spamLevel';

export type ReferringDomainDateField = 'dateAdded' | 'dateUpdated';
